import { post, get, deleteRequest } from '../common';

export const getGoogleCalendar = (
  payload: {
    teamId: string,
    startDt: string,
    endDt: string,
  },
) => {
  return get('/external/google/calendar', {
    teamId: payload.teamId,
    startDt: payload.startDt,
    endDt: payload.endDt,
  });
};
