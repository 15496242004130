import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

import {
  ChatResponse,
  ChatReactionResponse,
} from 'remonade-api/lib';
// api
import { listChats, createChat, createChatReaction, deleteChatReaction } from '@/api/client';
import dayjs from 'dayjs';

import { userModule } from '@/store/dataModule/user/userModule';
import LocalDataServices from '@/service/LocalDataServices';

export interface ChatState {
  chats: ChatResponse[];
}

@Module({ dynamic: true, store, name: 'chat', namespaced: true })
class ChatModule extends VuexModule implements ChatState {
  public chats: ChatResponse[] = [];

  @Action
  public async listChat() {
    const teamId = LocalDataServices.getTeamId();
    if (teamId) {
      const from = dayjs().subtract(24, 'hour').toISOString();
      const to = dayjs().add(24, 'hour').toISOString();

      const listResponse = await listChats({
        teamId,
        startDt: from,
        endDt: to,
      });

      if ('error' in listResponse) {
        return;
      }

      this.setChat(listResponse.chats);
    }
  }

  @Action
  public async createChat({ teamId, comment, channelId }: {
    teamId: string,
    comment: string,
    channelId: string | null,
  }) {
    const createResponse = await createChat({ teamId, comment, channelId });

    if ('error' in createResponse) {
      return;
    }
    // this.addChat(createResponse); // 👈 Update by pusher's MQTT
  }

  @Action
  public async createChatReaction({ chatId, reaction, teamId }: {
    chatId: string,
    reaction: string,
    teamId: string,
  }) {
    const createResponse = await createChatReaction({ chatId, reaction, teamId });

    if ('error' in createResponse) {
      return;
    }
    // this.addChatReaction(createResponse); // 👈 Update by pusher's MQTT
  }

  @Action
  public async deleteChatReaction({ chatId, reaction, teamId }: {
    chatId: string,
    reaction: string,
    teamId: string,
  }) {
    const deleteResponse = await deleteChatReaction({ chatId, reaction, teamId });

    if ('error' in deleteResponse) {
      return;
    }

    // this.removeChatReaction(deleteResponse); // 👈 Update by pusher's MQTT
  }

  @Mutation
  public setChat(chats: ChatResponse[]) {
    this.chats = chats;
  }

  @Mutation
  public addChats(chats: ChatResponse[]) {
    this.chats = this.chats.concat(chats);
  }

  @Mutation
  public addReactions(chatReactions: ChatReactionResponse[]) {
    chatReactions.forEach((chatReaction) => {
      const targetChat = this.chats.find((c) => c.chatId === chatReaction.chatId);
      if (targetChat) {
        if (targetChat.reactions) {
          if (targetChat.reactions.find((reaction) => reaction.sortKey === chatReaction.sortKey)) {
            targetChat.reactions = [...targetChat.reactions].filter((reaction) => reaction.sortKey !== chatReaction.sortKey);
          } else {
            targetChat.reactions.push(chatReaction);
          }
        } else {
          targetChat.reactions = [chatReaction];
        }
        this.chats = this.chats.map((chat) => chat.chatId === targetChat.chatId ? targetChat : chat);
      }
    });
  }

  // 👇　Unused mutations
  // Using GET method for all updates

  // @Mutation
  // public addChat(chat: ChatResponse) {
  //   this.chats.unshift(chat);
  // }

  // @Mutation
  // public addChatReaction(chatReaction: ChatReactionResponse) {
  //   const index = this.chats.indexOf((this.chats.find((c) => c.chatId === chatReaction.chatId)));
  //   const find = {...this.chats.find((chat) => chat.chatId === chatReaction.chatId)};
  //   if (find) {
  //     if (find.reactions) {
  //       find.reactions.push(chatReaction);
  //     } else { // When 'reactions' key doesn't exist
  //       find.reactions = [chatReaction];
  //     }
  //     this.chats.splice(index, 1, find);
  //   }
  // }

  // @Mutation
  // public removeChatReaction(chatReaction: ChatReactionResponse) {
  //   const find = this.chats.find((chat) => chat.chatId === chatReaction.chatId);
  //   if (find) {
  //     if (find.reactions) {
  //       find.reactions = find.reactions.filter((reaction) => reaction.sortKey !== chatReaction.sortKey);
  //     }
  //   }
  // }

  // @Mutation
  // public updateChat(chat: ChatMqttResponse) {
  //   const index = this.chats.indexOf(this.chats.find((c) => c.chatId === chat.chatId));
  //   if (index > -1) {
  //     this.chats.splice(index, 1, chat);
  //   } else {
  //     this.chats.unshift(chat);
  //   }
  // }

  // @Mutation
  // public updateChatReaction(chatReaction: ChatReactionMqttResponse) {
  //   const index = this.chats.indexOf((this.chats.find((c) => c.chatId === chatReaction.chatId)));
  //   const find = {...this.chats.find((chat) => chat.chatId === chatReaction.chatId)};
  //   if (find) {
  //     if (find.reactions) {
  //       if (chatReaction.isDeleted) {
  //         find.reactions.filter((reaction) => reaction.sortKey !== chatReaction.sortKey);
  //       } else {
  //         find.reactions.push(chatReaction);
  //       }
  //     } else if (!chatReaction.isDeleted) { // When 'reactions' key doesn't exist
  //       find.reactions = [chatReaction];
  //     }
  //     this.chats.splice(index, 1, find);
  //   }
  // }
}

export const chatModule = getModule(ChatModule);
