import { post, get, deleteRequest, put } from '../common';

export const deleteChatworkConnection = (
  payload: {
    teamId: string,
  },
) => {
  return deleteRequest('/notification/chatwork', {
    teamId: payload.teamId,
  });
};

export const getChatworkChannelList = (
  payload: {
    teamId: string,
  },
) => {
  return get('/notification/chatwork/channels', {
    teamId: payload.teamId,
  });
};

export const getSlackChannelList = (
  payload: {
    teamId: string,
  },
) => {
  return get('/notification/slack/channels', {
    teamId: payload.teamId,
  });
};

export const authorizePusher = (
  payload: {
    socketId: string,
    teamId: string,
  },
) => {
  return post('/notification/auth', {
    socketId: payload.socketId,
    teamId: payload.teamId,
  });
};

export const linkChatwork = (
  payload: {
    teamId: string,
    userId: string,
    code: string,
    redirectUri: string,
  },
) => {
  return post('/notification/chatwork', {
    teamId: payload.teamId,
    userId: payload.userId,
    code: payload.code,
    redirectUri: payload.redirectUri,
  });
};

export const linkSlack = (
  payload: {
    teamId: string,
    code: string,
    redirectUri: string,
  },
) => {
  return post('/notification/slack', {
    teamId: payload.teamId,
    code: payload.code,
    redirectUri: payload.redirectUri,
  });
};

export const deleteSlackConnection = (
  payload: {
    teamId: string,
  },
) => {
  return deleteRequest('/notification/slack', {
    teamId: payload.teamId,
  });
};

export const updateTeamNotificationSetting = (
  payload: {
    teamId: string,
    channelId: string[],
    slackTypeId: string,
    isEnabled?: boolean | null,
    message?: string | null,
    scheduledAt?: string | null,
  },
) => {
  return put('/notification/team', {
    teamId: payload.teamId,
    channelId: payload.channelId,
    slackTypeId: payload.slackTypeId,
    isEnabled: payload.isEnabled,
    message: payload.message,
    scheduledAt: payload.scheduledAt,
  });
};

export const updateUserNotificationSetting = (
  payload: {
    teamId: string,
    notificationType: string,
    isEnabled: boolean,
  },
) => {
  return put('/notification/user', {
    teamId: payload.teamId,
    notificationType: payload.notificationType,
    isEnabled: payload.isEnabled,
  });
};

export const sentPushNotification = (
  payload: {
    teamId: string,
    pushType: string,
    isAll: boolean,
    targetUsers: string[],
  },
) => {
  return post('/notification/push', {
    teamId: payload.teamId,
    pushType: payload.pushType,
    isAll: payload.isAll,
    targetUsers: payload.targetUsers,
  });
};

export const sentMqttNotification = (
  payload: {
    teamId: string,
    eventName: string,
    params: object,
  },
) => {
  return post('/notification/mqtt', {
    teamId: payload.teamId,
    eventName: payload.eventName,
    params: payload.params,
  });
};
