import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

import {
  PubSubSnapshotUpdate,
} from '@/model/PubSubModels';
import {
  DailyReportResponse,
  SnapshotResponse,
  UserResponse,
} from 'remonade-api/lib';
import { getSnapshotList, createSnapshot } from '@/api/client';
import { userModule } from '../user/userModule';
import { teamModule } from '../team/teamModule';
import LocalDataServices from '@/service/LocalDataServices';
import dayjs from 'dayjs';

export interface SnapshotState {
  snapshots: SnapshotResponse[];
}

@Module({ dynamic: true, store, name: 'snapshot', namespaced: true })
class SnapshotModule extends VuexModule implements SnapshotState {
  public snapshots: SnapshotResponse[] = [];

  public get user(): UserResponse | null {
    return userModule.user;
  }
  // ====================
  // Snapshot
  // ====================
  @Action
  public async listLatestSnapshots() {
    const teamId = LocalDataServices.getTeamId();
    if (teamId) {
      const listResponse = await getSnapshotList({ teamId });
      if ('error' in listResponse) {
        return;
      }

      this.setSnapshots(listResponse.snapshots)
    }
  }

  @Action
  public async createSnapshot({ url, pixel }: { url: string; pixel: number; }) {
    const teamId = LocalDataServices.getTeamId();
    if (teamId) {
      const createResponse = await createSnapshot({ originalUrl: url, teamId });
      if ('error' in createResponse) {
        return;
      }
      this.refreshSnapshot(createResponse);
    }
  }

  @Mutation
  public addSnapshot(snapshot: SnapshotResponse) {
    this.snapshots.push(snapshot);
  }

  @Mutation
  public setSnapshots(snapshots: SnapshotResponse[]) {
    this.snapshots = snapshots;
  }

  @Mutation
  public clearSnapshot() {
    this.snapshots = [];
  }

  @Mutation
  public refreshSnapshot(snapshot: SnapshotResponse) {
    this.snapshots = this.snapshots.filter((s) => s.userId !== snapshot.userId);
    this.snapshots.push(snapshot);
  }

  @Mutation
  public updateTeamUserSnapshot(obj: PubSubSnapshotUpdate) {
    this.snapshots = this.snapshots.map((snapshot) => {
      if (snapshot.userId === obj.userId) {
        snapshot.originalUrl = obj.url;
        return snapshot;
      }
      return snapshot;
    });
  }
}

export const snapshotModule = getModule(SnapshotModule);
