import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import dayjs from 'dayjs';
// store
import { userModule } from '@/store/dataModule/user/userModule';
import { planModule } from '@/store/dataModule/plan/planModule';
import { UserResponse } from 'remonade-api/lib';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { RemonadeDailyNote } from '@/model/types';
import { journalModule } from '@/store/dataModule/journal/journalModule';
import { sentMqttNotification, sentPushNotification } from '@/api/client';
import LocalDataService from '@/service/LocalDataServices';

export interface JournalViewState {
  currentDate: string;
  currentMonth: string;
  selectedChannelId: string;
  teamDailyNotes: RemonadeDailyNote[];
}

@Module({ dynamic: true, store, name: 'journal-view', namespaced: true })
class JournalViewModule extends VuexModule implements JournalViewState {
  public currentDate: string = dayjs().format('YYYY-MM-DD');
  public currentMonth: string = dayjs().format('YYYY-MM');
  public selectedChannelId: string = 'MINE';
  public teamDailyNotes: RemonadeDailyNote[] = [];

  public get user(): UserResponse | null {
    const user = LocalDataService.getUser();
    if (userModule.user) {
      return userModule.user;
    } else if (user) {
      return user;
    }
  }

  public get teamUsers(): UserResponse[] {
    return userModule.teamUsers;
  }

  public get minDate() {
    return planModule.minDate;
  }

  public get teamTodaysNotes() {
    const today = dayjs().format('YYYY-MM-DD');
    return this.teamDailyNotes.filter((note) => note.epoch === today);
  }

  public get monthStart(): string {
    if (dayjs(this.currentMonth).diff(dayjs(this.minDate), 'day') < 0 && this.minDate) {
      return this.minDate;
    } else {
      return dayjs(this.currentMonth).format('YYYY-MM-DD');
    }
  }

  public get monthEnd(): string {
    return dayjs(this.currentMonth).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD');
  }

  @Action
  public async getJournalData() {
    if (this.user) {
      await teamModule.findTeam(userModule.user.teamId);
    }
  }

  @Action
  public async getTeamNotes() {
    if (this.user) {
      const notes = await journalModule.listTeamDailyNotes({
        teamId: this.user.teamId,
        from: this.monthStart,
        to: this.monthEnd,
      });
      this.setTeamNotes(notes);
    }
  }

  @Action
  public async sentPushNotification() {
    if (this.user) {
      const response = await sentPushNotification({
        teamId: this.user.teamId,
        pushType: 'JOURNAL_UPDATE',
        isAll: true,
        targetUsers: [],
      });
      if (response) {
        if ('error' in response) {
          return;
        }
      }
    }
  }

  @Action
  public async sentMqttNotification(note: RemonadeDailyNote) {
    if (this.user) {
      const response = await sentMqttNotification({
        teamId: this.user.teamId,
        eventName: 'JOURNAL_UPDATE',
        params: note,
      });
      if (response) {
        if ('error' in response) {
          return;
        }
      }
    }
  }

  @Mutation
  public setCurrentDate(date: string) {
    this.currentDate = date;
  }

  @Mutation
  public setCurrentMonth(date: string) {
    this.currentMonth = date;
  }

  @Mutation
  public selectChannel(channelId: string) {
    this.selectedChannelId = channelId;
  }

  @Mutation
  public setTeamNotes(notes: RemonadeDailyNote[]) {
    this.teamDailyNotes = notes;
  }

  @Mutation
  public updateTeamNote(note: RemonadeDailyNote) {
    const index = this.teamDailyNotes.indexOf((this.teamDailyNotes.find((n) => n.document_id === note.document_id)));
    if (index > -1) {
      this.teamDailyNotes.splice(index, 1, note);
    } else {
      this.teamDailyNotes.push(note);
    }
  }
}

export const journalViewModule = getModule(JournalViewModule);
