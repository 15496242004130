import { post, get, deleteRequest, put, putNoAuth, ErrorResponse } from '../common';

import {
  MyTeamResponse,
  UserResponse,
} from 'remonade-api/lib';

export interface ListTeamsResponse {
  teams: MyTeamResponse[];
}

export interface ListUsersResponse {
  users: UserResponse[];
}

export const getUserList = (
  payload: {
    teamId: string,
  },
) => {
  return get(`/team/${payload.teamId}/user`, {
  }) as Promise<ListUsersResponse | ErrorResponse>;
};

export const inviteUser = (
  payload: {
    teamId: string,
    email: string,
  },
) => {
  return post(`/team/${payload.teamId}/user`, {
    email: payload.email,
  });
};

export const signUpFromUrl = (
  payload: {
    teamId: string,
    email: string,
    language: string,
  },
) => {
  return putNoAuth(`/team/${payload.teamId}/user`, {
    email: payload.email,
    language: payload.language,
  });
};

export const listTeams = () => {
  return get('/me/teams', null) as Promise<ListTeamsResponse | ErrorResponse>;
};

export const deleteUser = (
  payload: {
    teamId: string,
    userId: string,
  },
) => {
  return deleteRequest(`/team/${payload.teamId}/user/${payload.userId}`, {});
};

export const updateUser = (
  payload: {
    teamId: string,
    userId: string,
    username?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    interval?: number | null,
    pixel?: number | null,
    message?: string | null,
    language?: string | null,
    userType?: string | null,
    isFirstLogin?: boolean | null,
    isPasswordChangeRequired?: boolean | null,
    snapContent?: string | null,
    snapIconUrl?: string | null,
    snapType?: number | null,
    timezone?: string | null,
    isMailNotificationEnabled?: boolean | null,
    googleCalendarIds?: string[] | null,
    isGoogleCalendarEnabled?: boolean | null,
  },
) => {
  return put(`/team/${payload.teamId}/user/${payload.userId}`, {
    username: payload.username,
    firstName: payload.firstName,
    lastName: payload.lastName,
    interval: payload.interval,
    pixel: payload.pixel,
    message: payload.message,
    language: payload.language,
    userType: payload.userType,
    isFirstLogin: payload.isFirstLogin,
    isPasswordChangeRequired: payload.isPasswordChangeRequired,
    snapContent: payload.snapContent,
    snapIconUrl: payload.snapIconUrl,
    snapType: payload.snapType,
    timezone: payload.timezone,
    isMailNotificationEnabled: payload.isMailNotificationEnabled,
    googleCalendarIds: payload.googleCalendarIds,
    isGoogleCalendarEnabled: payload.isGoogleCalendarEnabled,
  });
};

export const updateLastActive = ({ teamId, userId }: { teamId: string, userId: string }) => {
  return put(`/team/${teamId}/user/${userId}/health_check`, null) as Promise<UserResponse | ErrorResponse>;
};
