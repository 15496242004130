import Vue from 'vue';
import Vuex from 'vuex';
import { UserState } from './dataModule/user/userModule';
Vue.use(Vuex);

export interface RootState {
  userState: UserState;
}

export default new Vuex.Store<RootState>({});
