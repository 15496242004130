import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import {
  PubSubMessageUpdate, PubSubSnapContentUpdate,
} from '@/model/PubSubModels';

// api
import { getTeam, getWhitelist, getUserList, updateTeam, addWhitelist, deleteWhitelist } from '@/api/client';
import { TeamResponse, UserResponse, WhitelistResponse } from 'remonade-api/lib';

const wait = (sec: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, sec * 1000);
  });
};

export interface TeamState {
  team: TeamResponse | null;
  teamId: string | null;
  teamUsers: UserResponse[];
  highlightUsers: UserResponse[];
  whitelist: WhitelistResponse[];
}

@Module({ dynamic: true, store, name: 'team', namespaced: true })
class TeamModule extends VuexModule implements TeamState {
  public team: TeamResponse | null = null;
  public teamId: string | null = null;
  public teamUsers: UserResponse[] = [];
  public highlightUsers: UserResponse[] = [];
  public whitelist: WhitelistResponse[] = [];

  public get userLimit() {
    if (this.team) {
      return this.team.plan.userLimit;
    }
    return -1;
  }

  @Mutation
  public setTeam(team: TeamResponse) {
    this.team = team;
  }

  @Mutation
  public setTeamId(teamId: string | null) {
    this.teamId = teamId;
  }

  @Mutation
  public setTeamUsers(users: UserResponse[]) {
    this.teamUsers = users;
  }

  @Mutation
  public updateTeamUser(user: UserResponse) {
    this.teamUsers = this.teamUsers.filter((u) => u.userId !== user.userId);
    this.teamUsers.push(user);
  }

  @Mutation
  public updateTeamUserSnapContent(update: PubSubSnapContentUpdate) {
    const find = this.teamUsers.find((u) => u.userId === update.userId);
    if (find) {
      find.snapContent = update.content;
      this.teamUsers = this.teamUsers.filter((u) => u.userId !== update.userId);
      this.teamUsers.unshift(find);
    }
  }

  @Mutation
  public updateTeamUserMessage(obj: PubSubMessageUpdate) {
    this.teamUsers = this.teamUsers.map((user) => {
      if (user.userId === obj.userId) {
        user.message = obj.message;
        return user;
      }
      return user;
    });
  }

  @Action
  public async listTeamUsers(teamId: string) {
    const users = await getUserList({
      teamId,
    });
    if (users) {
      if ('error' in users) {
        return;
      }
      this.setTeamUsers(users.users);
    }
  }

  @Action
  public async findTeam(teamId: string) {
    const team = await getTeam({ teamId });
    if (team) {
      if ('error' in team) {
        return;
      }
      this.setTeam(team);
      this.setTeamId(team.teamId);
    }
  }

  @Action
  public async refreshTeam() {
    if (this.teamId) {
      const team = await getTeam({
        teamId: this.teamId,
      });
      if (team) {
        if ('error' in team) {
          return;
        }
        this.setTeam(team);
        return team;
      }
    }
    return null;
  }

  @Action
  public async updateTeam() {
    if (this.team) {
      const team = await updateTeam({
        teamId: this.team.teamId,
        timezone: this.team.timezone ? this.team.timezone : null,
        notificationWeekday: this.team.notificationWeekday ? this.team.notificationWeekday : null,
      });
      if (team) {
        if ('error' in team) {
          return;
        }
        this.setTeam(team);
        return team;
      }
    }
    return null;
  }

  // @Action
  // public async onUserReportUpdate(report: RemonadeReport) {
  //   if (!report.is_done) { return; }
  //   const ids = report.user_id.split('#');
  //   if (ids.length === 2) {
  //     const teamId = ids[0];
  //     const userId = ids[1];
  //     const user = this.teamUsers.find((u) => u.userId === userId);
  //     if (user) {
  //       this.pushHightlightUser(user);
  //       await wait(1);
  //       this.shiftHighlightUser();
  //     }
  //   }
  // }

  @Action
  public async getTeamWhitelist() {
    if (this.teamId) {
      const whitelist = await getWhitelist({ teamId: this.teamId });
      if (whitelist) {
        if ('error' in whitelist) {
          return;
        }
        this.setWhitelist(whitelist.domains);
      }
    }
  }

  @Action
  public async createDomainWhitelist({ teamId, domain }) {
    const whitelist = await addWhitelist({
      teamId,
      domain,
    });
    if (whitelist) {
      if ('error' in whitelist) {
        return;
      }
      this.whitelist.push(whitelist);
    }
  }

  @Action
  public async deleteDomainWhitelist({ teamId, domain }) {
    const whitelist = await deleteWhitelist({
      teamId,
      domain,
    });
    if (whitelist) {
      if ('error' in whitelist) {
        return;
      }
      this.setWhitelist(this.whitelist.filter((w) => domain !== w.domain));
    }
  }

  @Mutation
  public setWhitelist(whitelist: WhitelistResponse[]) {
    this.whitelist = whitelist;
  }

  @Mutation
  public pushHightlightUser(user: UserResponse) {
    this.highlightUsers.push(user);
  }

  @Mutation
  public shiftHighlightUser() {
    this.highlightUsers.shift();
  }

}

export const teamModule = getModule(TeamModule);
