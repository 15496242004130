import axios from 'axios';
import LocalDataService from '@/service/LocalDataServices';
import { auth0Module } from '@/store/viewModule/auth/auth0Module';

const url = process.env.VUE_APP_REMONADE_API_URL;
const xkey = process.env.VUE_APP_REMONADE_API_X_KEY;

export interface ErrorResponse {
  error: string;
}

const getHeaders = async () => {
  const authType = LocalDataService.getAuthType();
  if (!process.env.IS_ELECTRON) {
    if (authType === 'OPENID_CONNECT') {
      const token = await auth0Module.getAuth0Token();
      return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8',
        'x-api-key': xkey,
      };
    } else {
      const accessToken = LocalDataService.getAccessToken();
      const idToken = LocalDataService.getIdToken();
      return {
        'accessToken': accessToken,
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json; charset=utf-8',
        'x-api-key': xkey,
      };
    }
  } else {
    // EDIT THIS BLOCK ONLY IN ELECTRON BRANCH
  }
};

export const postNoAuth = async (endpoint: string, params: { [key: string]: any; }) => {
  return axios.post(`${url}${endpoint}`, JSON.stringify(params), {headers: {'x-api-key': xkey}}).then((response) => response.data);
};

export const post = async (endpoint: string, params: { [key: string]: any; }) => {
  return axios.post(
    `${url}${endpoint}`,
    params ? JSON.stringify(params) : null,
    {
      headers: await getHeaders(),
    },
  ).then((response) => response.data);
};

export const getNoAuth = async (endpoint: string) => {
  return axios.get(`${url}${endpoint}`, {headers: {'x-api-key': xkey}}).then((response) => response.data);
};

export const get = async (endpoint: string, params: { [key: string]: any; } | null) => {
  return axios.get(
    `${url}${endpoint}`,
    {
      params: params || null,
      headers: await getHeaders(),
    },
  ).then((response) => response.data);
};

// 👇 deleteが予約語なのでdeleteRequestとしている
export const deleteRequest = async (endpoint: string, params: { [key: string]: any; }) => {
  return axios.delete(
    `${url}${endpoint}`,
    {
      data: params ? JSON.stringify(params) : null,
      headers: await getHeaders(),
    },
  ).then((response) => response.data);
};

export const put = async (endpoint: string, params: { [key: string]: any; }) => {
  return axios.put(
    `${url}${endpoint}`,
    params ? JSON.stringify(params) : null,
    {
      headers: await getHeaders(),
    },
  ).then((response) => response.data);
};

export const putNoAuth = async (endpoint: string, params: { [key: string]: any; }) => {
  return axios.put(`${url}${endpoint}`, JSON.stringify(params), {headers: {'x-api-key': xkey}}).then((response) => response.data);
};
