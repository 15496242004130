import { post, get, deleteRequest, put, ErrorResponse } from '../common';

import {
  ScheduledReportResponse,
} from 'remonade-api/lib';

export interface ListSchedultedTasksResponse {
  schedules: ScheduledReportResponse[];
}

export const getScheduledTaskList = (
  payload: {
    teamId: string,
  },
) => {
  return get('/schedule', {
    teamId: payload.teamId,
  }) as Promise<ListSchedultedTasksResponse | ErrorResponse>;
};

export const createScheduledTask = (
  payload: {
    teamId: string,
    userId: string,
    task: string,
    weekday?: number | null,
    hour?: number | null,
    tag?: string | null,
  },
) => {
  return post('/schedule', {
    teamId: payload.teamId,
    userId: payload.userId,
    task: payload.task,
    weekday: payload.weekday,
    hour: payload.hour,
    tag: payload.tag,
  }) as Promise<ScheduledReportResponse | ErrorResponse>;
};

export const updateScheduledTask = (
  payload: {
    sReportId: string,
    teamId: string,
    userId: string,
    task?: string | null,
    weekday?: number | null,
    hour?: number | null,
    tag?: string | null,
  },
) => {
  return put(`/schedule/${payload.sReportId}`, {
    teamId: payload.teamId,
    userId: payload.userId,
    task: payload.task,
    weekday: payload.weekday,
    hour: payload.hour,
    tag: payload.tag,
  }) as Promise<ScheduledReportResponse | ErrorResponse>;
};

export const deleteScheduledTask = (
  payload: {
    sReportId: string,
    teamId: string,
    userId: string,
  },
) => {
  return deleteRequest(`/schedule/${payload.sReportId}`, {
    teamId: payload.teamId,
    userId: payload.userId,
  }) as Promise<ScheduledReportResponse | ErrorResponse>;
};
