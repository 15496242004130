import { CryptoPasswordUtils } from 'remonade-api/lib';
import { postNoAuth, put, putNoAuth } from '../common';

export const requestResetPassword = (
  payload: {
    teamId: string,
    email: string,
  },
) => {
  return postNoAuth('/user/reset_password', {
    teamId: payload.teamId,
    email: payload.email,
  });
};

export const changePassword = (
  payload: {
    teamId: string,
    oldPassword: string,
    newPassword: string,
  },
) => {
  const cryptUtils = new CryptoPasswordUtils(process.env.VUE_APP_AUTH_AES_KEY, process.env.VUE_APP_AUTH_AES_SALT, process.env.VUE_APP_AUTH_AES_IV);
  const encryptedOldPassword = cryptUtils.encryptPassword(payload.oldPassword);
  const encryptedNewPassword = cryptUtils.encryptPassword(payload.newPassword);
  return put('/user/change_password', {
    teamId: payload.teamId,
    oldPassword: encryptedOldPassword,
    newPassword: encryptedNewPassword,
  });
};

export const authorizeResetPassword = (
  payload: {
    teamId: string,
    email: string,
    newPassword: string,
    confirmationCode: string,
  },
) => {
  const cryptUtils = new CryptoPasswordUtils(process.env.VUE_APP_AUTH_AES_KEY, process.env.VUE_APP_AUTH_AES_SALT, process.env.VUE_APP_AUTH_AES_IV);
  const encryptedPassword = cryptUtils.encryptPassword(payload.newPassword);
  return putNoAuth('/user/reset_password', {
    teamId: payload.teamId,
    email: payload.email,
    newPassword: encryptedPassword,
    confirmationCode: payload.confirmationCode,
  });
};
