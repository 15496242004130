export const getProgressColor = (progress: number) => {
  if (progress > .7) {
    return '#00AF74';
  } else if (progress > .4) {
    return '#84D9BD';
  }
  return '#B2C9C1';
};

export const isMobile = (window: Window) => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
   }
  return false;
};
