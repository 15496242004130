import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import DailyNoteApiClient from '@/api/DailyNoteApiClient';
import { RemonadeDailyNote } from '@/model/types';
import dayjs from 'dayjs';

const wait = (sec: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, sec * 1000);
  });
};

export interface JournalState {
  nippoUserId: string | null;
  projectId: string;
  notes: RemonadeDailyNote[];
}

@Module({ dynamic: true, store, name: 'journal', namespaced: true })
class JournalModule extends VuexModule implements JournalState {
  public nippoUserId: string | null = null;
  public projectId = process.env.VUE_APP_DAILYNOTE_PROJECT_ID || '';
  public notes: RemonadeDailyNote[] = [];

  public get todayJournal() {
    if (!this.notes) {
      return null;
    }
    const find = this.notes.find((note) => note.epoch === dayjs().format('YYYY-MM-DD'));
    return find || null;
  }

  @Mutation
  public setNippoUserId(nippoUserId: string) {
    this.nippoUserId = nippoUserId;
  }

  @Action
  public async listMyDailyNotes({ teamId, from, to }: { teamId: string, from: string, to: string }) {
    if (this.nippoUserId) {
      const notesResponse = await DailyNoteApiClient.searchNotes({
        page: 1,
        perPage: 40,
        teamId,
        from,
        to,
        userId: this.nippoUserId,
      });
      this.replaceNotes(notesResponse.items);
    }
  }

  @Mutation
  public replaceNotes(notes: RemonadeDailyNote[]) {
    this.notes = notes;
  }

  @Mutation
  public addNotes(notes: RemonadeDailyNote[]) {
    this.notes = this.notes.concat(notes);
  }

  @Action
  public async createDailyNote({body, date, teamId}: {body: string, date: string, teamId: string}) {
    const createResponse = await DailyNoteApiClient.createNote({
      body,
      epoch: date,
      teamId,
    });
    this.addNotes([createResponse]);
    return createResponse;
  }

  @Action
  public async updateDailyNote({body, date, teamId, documentId}: {body: string, date: string, teamId: string, documentId: string}) {
    const updateResponse = await DailyNoteApiClient.updateNote({
      documentId,
      body,
      epoch: date,
      teamId,
    });
    this.refreshDailyNote(updateResponse);
    return updateResponse;
  }

  @Mutation
  public refreshDailyNote(note: RemonadeDailyNote) {
    this.notes = this.notes.filter((n) => n.epoch !== note.epoch);
    this.notes.push(note);
  }

  @Action
  public async listTeamDailyNotes({ teamId, from, to }: { teamId: string, from: string, to: string }) {
    const notesResponse = await DailyNoteApiClient.searchTeamNotes({
      page: 1,
      perPage: 10000,
      teamId,
      from,
      to,
    });
    if (notesResponse.total > 10000) {
      const notesBigResponse = await DailyNoteApiClient.searchTeamNotes({
        page: 1,
        perPage: notesResponse.total,
        teamId,
        from,
        to,
      });
      return notesBigResponse.items;
    }
    return notesResponse.items;
  }

  @Action
  public async updateTeamDailyNotes() {

  }
}

export const journalModule = getModule(JournalModule);
