import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

import dayjs from 'dayjs';

import {
  DailyReportResponse,
} from 'remonade-api/lib';
import { listReports, createReport, updateReport, deleteReport } from '@/api/client';

export interface CreatingTask {
  task: string;
  tag: string;
  estimate: number | null;
  parentId?: string | null;
}

export interface ReportsState {
  reports: DailyReportResponse[];
  dates: string[];
}

@Module({ dynamic: true, store, name: 'reports', namespaced: true })
class ReportsModule extends VuexModule implements ReportsState {
  public reports: DailyReportResponse[] = [];
  public dates: string[] = [];

  public get activeReports(): DailyReportResponse[] {
    return this.reports.filter((report) => !report.deletedAt);
  }

  public get todayReports(): DailyReportResponse[] {
    return this.activeReports.filter((report) => report.date === dayjs().format('YYYY-MM-DD'));
  }

  public get sortedTodayTasks(): DailyReportResponse[] {
    return this.todayReports.sort((t1, t2) => (t1.displayOrder || 0) - (t2.displayOrder || 0));
  }

  /**
   * List team reports
   */
  @Action
  public async listMyReports({ teamId, userId, date }: { teamId: string, userId: string, date: string }) {
    const listResponse = await listReports({teamId, userId, date, sortKey: `${teamId}#${userId}` , queryType: 'date_sort'});

    if (listResponse.error) {
      return;
    }

    if (listResponse.reports) {
      const reports = listResponse.reports;
      this.mergeReports(reports);
    }
  }

  @Action
  public async listTeamReportsWithRange({ teamId, startDt, endDt }: { teamId: string, startDt: string, endDt: string }) {
    const listResponse = await listReports({teamId, startDt, endDt, queryType: 'team_date'});

    if (listResponse.error) {
      return;
    }

    if (listResponse.reports) {
      const reports = listResponse.reports;
      this.mergeReports(reports);
    }
  }

  /**
   * List team reports
   */
  @Action
  public async createTask(payload: {
    teamId: string,
    userId: string,
    task: string,
    tag: string | null,
    estimate: number | null,
    date: string,
    displayOrder: number,
    parentId?: string | null,
  }) {
    const createResponse = await createReport({
      date: payload.date,
      type: 'task',
      task: payload.task,
      tag: payload.tag,
      hour: payload.estimate,
      teamId: payload.teamId,
      userId: payload.userId,
      displayOrder: payload.displayOrder,
      parentId: payload.parentId,
    });

    if ('error' in createResponse) {
      return;
    }

    this.mergeReports([createResponse as DailyReportResponse]);
  }

  @Mutation
  public mergeReports(reports: DailyReportResponse[]) {
    let currentReports = [...this.reports];
    reports.forEach((report) => {
      currentReports = currentReports.filter((r) => r.reportId !== report.reportId);
      currentReports.push(report);
    });
    this.reports = currentReports;
  }

  /**
   * Update report
   */
  @Action
  public async updateTask(task: DailyReportResponse) {
    const updateResponse = await updateReport(task);

    if ('error' in updateResponse) {
      return;
    }

    this.refreshReport(updateResponse as DailyReportResponse);
  }

  @Action
  public async updateTaskDone(task: DailyReportResponse) {
    const updateResponse = await updateReport(task);

    if ('error' in updateResponse) {
      return;
    }

    this.refreshReport(updateResponse as DailyReportResponse);
  }

  @Mutation
  public refreshReport(report: DailyReportResponse) {
    this.reports = this.reports.filter((r) => r.reportId !== report.reportId);
    this.reports.push(report);
    // teamModule.onUserReportUpdate(report);
  }

  /**
   * Delete task
   */
  @Action
  public async deleteTask(task: DailyReportResponse) {
    const deleteResponse = await deleteReport({ reportId: task.reportId, teamId: task.teamId });

    if ('error' in deleteResponse) {
      return;
    }

    this.refreshReport(deleteResponse as DailyReportResponse);
  }
}

export const reportsModule = getModule(ReportsModule);
