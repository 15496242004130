import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

import { userModule } from '../user/userModule';

import {
  BookmarkResponse,
} from 'remonade-api/lib';

// api
import { listBookmarks, createBookmark, updateBookmark, deleteBookmark } from '@/api/client';
import LocalDataServices from '@/service/LocalDataServices';

export interface BookmarkState {
  bookmarks: BookmarkResponse[];
}

@Module({ dynamic: true, store, name: 'bookmark', namespaced: true })
class BookmarkModule extends VuexModule implements BookmarkState {
  public bookmarks: BookmarkResponse[] = [];

  @Action
  public async listBookmarks() {
    const teamId = LocalDataServices.getTeamId();
    if (teamId) {
      const listResponse = await listBookmarks({ teamId });
      if ('error' in listResponse) {
        // handle error
        return;
      }
      this.setBookmars(listResponse.bookmarks);
    }
  }

  @Mutation
  public setBookmars(bookmarks: BookmarkResponse[]) {
    this.bookmarks = bookmarks;
  }

  @Mutation
  public addBookmars(bookmarks: BookmarkResponse[]) {
    this.bookmarks = this.bookmarks.concat(bookmarks);
  }

  @Mutation
  public refreshBookmark(bookmark: BookmarkResponse) {
    this.bookmarks = this.bookmarks.map((b) => {
      if (b.bookmarkId === bookmark.bookmarkId) {
        return bookmark;
      }
      return b;
    });
  }

  @Mutation
  public removeBookmark(bookmarkId: string) {
    this.bookmarks = this.bookmarks.filter((bookmark) => bookmark.bookmarkId !== bookmarkId);
  }

  @Action
  public async createBookmark({
    channelId,
    url,
    title,
    description,
    icon,
  }: {
    channelId: string | null,
    url: string,
    title: string,
    description?: string,
    icon?: string,
  }) {
    if (userModule.user) {
      const createResponse = await createBookmark({
        teamId: userModule.user.teamId,
        channelId,
        url,
        title,
        description,
        icon,
      });

      if ('error' in createResponse) {
        return;
      }

      this.addBookmars([createResponse]);
    }
  }

  @Action
  public async updateBookmark({
    bookmarkId,
    channelId,
    url,
    title,
    description,
    icon,
  }: {
    bookmarkId: string,
    channelId: string | null,
    url: string,
    title: string,
    description?: string,
    icon?: string,
  }) {
    if (userModule.user) {
      const updateResponse = await updateBookmark({
        bookmarkId,
        teamId: userModule.user.teamId,
        channelId,
        url,
        title,
        description,
        icon,
      });

      if ('error' in updateResponse) {
        return;
      }

      this.refreshBookmark(updateResponse);
    }
  }

  @Action
  public async deleteBookmark(bookmarkId: string) {
    if (userModule.user) {
      const deleteResponse = await deleteBookmark({ teamId: userModule.user.teamId, bookmarkId });

      if ('error' in deleteResponse) {
        return;
      }

      this.removeBookmark(bookmarkId);
    }
  }
}

export const bookmarkModule = getModule(BookmarkModule);
