import { post, get } from '../common';

export const uploadImage = (
  payload: {
    teamId: string,
    fileName: string,
  },
) => {
  return post('/resource', {
    teamId: payload.teamId,
    fileName: payload.fileName,
  });
};

export const getImage = (
  payload: {
    path: string,
    teamId: string,
  },
) => {
  return get('/resource', {
    path: payload.path,
    teamId: payload.teamId,
  });
};
