import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { GoogleCalendarResponse, UserResponse } from 'remonade-api/lib';
import { auth0Module } from '@/store/viewModule/auth/auth0Module';
import LocalDataService from '@/service/LocalDataServices';
import { userModule } from '../user/userModule';
import { getGoogleCalendar } from '@/api/client/methods/external';
import dayjs from 'dayjs';


export interface CalendarState {
  calendars: GoogleCalendarResponse[];
}

@Module({ dynamic: true, store, name: 'calendar', namespaced: true })
class CalendarModule extends VuexModule implements CalendarState {
  public calendars: GoogleCalendarResponse[] = [];

  public get user(): UserResponse | null {
    const user = LocalDataService.getUser();
    if (userModule.user) {
      return userModule.user;
    } else if (user) {
      return user;
    }
  }

  @Action
  public async authorizeGoogleCalendar() {
    await auth0Module.authorizeGoogleCalendar();
  }

  @Action
  public async getGoogleCalendars(currentDate: string) {
    if (this.user) {
      const r = await getGoogleCalendar({
        teamId: this.user.teamId,
        startDt: dayjs(currentDate).format(),
        endDt: dayjs(currentDate).add(1, 'day').format(),
      });
      if (r) {
        this.setCalendars(r.calendars);
        return r;
      }
    }
  }

  @Mutation
  public setCalendars(calendars: GoogleCalendarResponse[]) {
    this.calendars = calendars;
  }
}

export const calendarModule = getModule(CalendarModule);
