import { post, get, deleteRequest } from '../common';

export const addWhitelist = (
  payload: {
    teamId: string,
    domain: string,
  },
) => {
  return post('/whitelist', {
    teamId: payload.teamId,
    domain: payload.domain,
  });
};

export const getWhitelist = (
  payload: {
    teamId: string,
  },
) => {
  return get('/whitelist', {
    teamId: payload.teamId,
  });
};

export const deleteWhitelist = (
  payload: {
    teamId: string,
    domain: string,
  },
) => {
  return deleteRequest('/whitelist', {
    teamId: payload.teamId,
    domain: payload.domain,
  });
};
