import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import dayjs from 'dayjs';
import { TaskItem } from '@/model/types';
import { userModule } from '@/store/dataModule/user/userModule';
import { reportsModule } from '@/store/dataModule/report/reportsModule';

// service
import { sortTasks } from '@/service/ui/TaskService';
import { getTaskItems } from '@/service/ui/ModelService';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import LocalDataService from '@/service/LocalDataServices';
import { planModule } from '@/store/dataModule/plan/planModule';
import { UserResponse } from 'remonade-api/lib';
import { calendarModule } from '@/store/dataModule/calendar/calendarModule';
import { updateUser } from '@/api/client';

export interface TaskDialogState {
  isDialogOpen: boolean;
  currentDate: string;
  movingTask: TaskItem | null;
  copyingTask: TaskItem | null;
  connectedCalendars: GoogleCalendar[];
}

export interface GoogleCalendar {
  calendarId: string;
  calendarSummary: string;
  tasks: TaskItem[];
}

@Module({ dynamic: true, store, name: 'task-dialog', namespaced: true })
class TaskDialogModule extends VuexModule implements TaskDialogState {
  public isDialogOpen: boolean = false;
  public currentDate: string = dayjs().format('YYYY-MM-DD');
  public movingTask: TaskItem | null = null;
  public copyingTask: TaskItem | null = null;
  public connectedCalendars: GoogleCalendar[] = [];

  public get user(): UserResponse | null {
    const user = LocalDataService.getUser();
    if (userModule.user) {
      return userModule.user;
    } else if (user) {
      return user;
    }
  }

  public get isEditable() {
    return true;
    // const today = dayjs().format('YYYY-MM-DD');
    // return this.currentDate >= today;
  }

  public get tasks() {
    if (userModule.user) {
      const userId = userModule.user.userId;
      const sorted = sortTasks(reportsModule.activeReports);
      const tasks = sorted.filter((r) => r.type === 'task' && r.userId.endsWith(userId) && r.date === this.currentDate);
      return getTaskItems(tasks, tagModule.tags);
    }
    return [];
  }

  public get parentTasks() {
    return this.tasks.filter((task) => !task.parentId);
  }

  public get childTasks() {
    return this.tasks.filter((task) => task.parentId !== null || task.parentId !== undefined);
  }

  public get minDate() {
    return planModule.minDate;
  }

  public get isGoogleUser(): boolean {
    return userModule.isGoogleUser;
  }

  @Mutation
  public calcCurrentDate() {
    this.currentDate = dayjs().format('YYYY-MM-DD');
  }

  @Action
  public getConnectCalendars() {
    if (this.user) {
      const connectedCalendars: GoogleCalendar[] = [];
      const calendars = calendarModule.calendars.filter((calendar) => this.user.googleCalendarIds.includes(calendar.calendarId));
      for (const calendar of calendars) {
        const connectedCalendar: GoogleCalendar = {
          calendarId: calendar.calendarId,
          calendarSummary: calendar.calendarSummary,
          tasks: [],
        } ;
        for (const event of calendar.events) {
          let hour = null;
          let taskTimeMessage = '';
          if (event.start.dateTime && event.end.dateTime) {
            const startTime = new Date(event.start.dateTime).getTime();
            const endTime = new Date(event.end.dateTime).getTime();
            hour = (endTime - startTime) / (1000 * 60 * 60);
            taskTimeMessage = ` (${dayjs(event.start.dateTime).format('HH:mm')}〜${dayjs(event.end.dateTime).format('HH:mm')})`;
          }
          const task: TaskItem = {
            taskName: event.summary + taskTimeMessage,
            tagId: null,
            hour,
          };
          connectedCalendar.tasks.push(task);
        }
        connectedCalendars.push(connectedCalendar);
      }
      this.setConnectedCalendars(connectedCalendars);
    }
  }

  @Mutation
  public setConnectedCalendars(calendars: GoogleCalendar[]) {
    this.connectedCalendars = calendars;
  }

  @Mutation
  public setConnectedCalendarTaskTag({ updatedTask, tag }) {
    for (const calendar of this.connectedCalendars) {
      if (calendar.tasks.find((t) => t.taskName === updatedTask.taskName)) {
        this.connectedCalendars.find((c) => c.calendarId === calendar.calendarId).tasks.find((t) => t.taskName === updatedTask.taskName).tagId = tag;
      }
    }
  }

  @Mutation
  public removeConnectedCalendarTask(updatedTask: TaskItem) {
    for (const calendar of this.connectedCalendars) {
      if (calendar.tasks.includes(updatedTask)) {
        this.connectedCalendars.find((c) => c.calendarId === calendar.calendarId).tasks.filter((t) => t !== updatedTask);
      }
    }
  }

  @Mutation
  public setOpen(open: boolean) {
    this.isDialogOpen = open;
  }

  @Mutation
  public setCurrentDate(date: string) {
    this.currentDate = date;
    if (userModule.user) {
      reportsModule.listMyReports({
        teamId: userModule.user.teamId,
        userId: userModule.user.userId,
        date,
      });
    }
  }

  @Action
  public async createTask(taskItem: TaskItem) {
    if (userModule.user) {
      const displayOrder = dayjs().unix();
      await reportsModule.createTask({
        teamId: userModule.user.teamId,
        userId: userModule.user.userId,
        date: this.currentDate,
        task: taskItem.taskName || '',
        tag: taskItem.tagId ? taskItem.tagId : undefined,
        estimate: taskItem.hour ? taskItem.hour : undefined,
        displayOrder,
      });
    }
  }

  @Action
  public async createSubtask(taskItem: TaskItem) {
    if (userModule.user) {
      const displayOrder = dayjs().unix();
      await reportsModule.createTask({
        teamId: userModule.user.teamId,
        userId: userModule.user.userId,
        date: this.currentDate,
        task: taskItem.taskName || '',
        tag: taskItem.tagId ? taskItem.tagId : undefined,
        estimate: taskItem.hour ? taskItem.hour : undefined,
        displayOrder,
        parentId: taskItem.parentId,
      });
    }
  }

  @Action
  public async updateTaskDone({ taskId, isDone }: { taskId: string, isDone: boolean }) {
    if (userModule.user) {
      const report = reportsModule.activeReports.find((r) => r.reportId === taskId);
      if (report) {
        report.isDone = isDone;
        reportsModule.updateTaskDone(report);
      }
    }
  }

  @Action
  public async updateTask(taskItem: TaskItem) {
    if (userModule.user) {
      const report = reportsModule.activeReports.find((r) => r.reportId === taskItem.taskId);
      if (report) {
        if (taskItem.taskName) {
          report.task = taskItem.taskName;
        }
        if (taskItem.tagId) {
          report.tag = taskItem.tagId;
        }
        if (taskItem.hour) {
          report.hour = taskItem.hour;
        }
        if (taskItem.displayOrder) {
          report.displayOrder = taskItem.displayOrder;
        }
        reportsModule.updateTask(report);
      }
    }
  }

  @Action
  public async deleteTask(taskId: string) {
    if (userModule.user) {
      const report = reportsModule.activeReports.find((r) => r.reportId === taskId);
      if (report) {
        reportsModule.deleteTask(report);
      }
    }
  }

  @Action
  public async updateUserCalendarEnabled() {
    if (this.user) {
      const updatedUser = {...this.user};
      updatedUser.isGoogleCalendarEnabled = true;
      const user = await updateUser({
        teamId: updatedUser.teamId,
        userId: updatedUser.userId,
        isGoogleCalendarEnabled: updatedUser.isGoogleCalendarEnabled,
      });
      if (user) {
        if ('error' in user) {
          return;
        }
        userModule.setUser(user);
      }
    }
  }

  @Action
  public async authorizeGoogleCalendar() {
    LocalDataService.setLastPath(window.location.pathname);
    await calendarModule.authorizeGoogleCalendar();
  }

  /**
   * Move
   */
  @Mutation
  public setMovingTask(movingTask: TaskItem | null) {
    this.movingTask = movingTask;
  }

  @Action
  public async moveTask(date: string) {
    if (this.movingTask) {
      const report = reportsModule.activeReports.find((r) => r.reportId === this.movingTask!.taskId);
      if (report && report.date !== date) {
        report.date = date;
        await reportsModule.updateTask(report);
        const childReports = reportsModule.activeReports.filter((r) => r.parentId === this.movingTask!.taskId);
        const displayOrder = dayjs().unix();
        await Promise.all(childReports.map((child, i) => {
          child.date = date;
          child.displayOrder = displayOrder + i + 1;
          return reportsModule.updateTask(child);
        }));
        this.setMovingTask(null);
      }
    }
  }

  /**
   * Copy
   */
  @Mutation
  public setCopyingTask(copyingTask: TaskItem | null) {
    this.copyingTask = copyingTask;
  }

  @Action
  public async copyTask(date: string) {
    if (userModule.user && this.copyingTask) {
      const report = reportsModule.activeReports.find((r) => r.reportId === this.copyingTask!.taskId);
      if (report && report.date !== date) {
        const displayOrder = dayjs().unix();
        const created = await reportsModule.createTask({
          teamId: userModule.user.teamId,
          userId: userModule.user.userId,
          date,
          task: report.task || '',
          tag: report.tag ? report.tag : undefined,
          estimate: report.hour ? report.hour : undefined,
          displayOrder,
        });
        // if (created) {
        //   const childReports = reportsModule.activeReports.filter((r) => r.parentId === this.copyingTask!.taskId);
        //   await Promise.all(childReports.map((child, i) => {
        //     return reportsModule.createTask({
        //       teamId: userModule.user!.teamId,
        //       userId: userModule.user!.userId,
        //       parentId: created.reportId,
        //       date,
        //       task: child.task || '',
        //       tag: child.tag || '',
        //       estimate: child.hour || 1,
        //       displayOrder: displayOrder + i + 1,
        //     });
        //   }));
        // }
        this.setCopyingTask(null);
      }
    }
  }
}

export const taskDialogModule = getModule(TaskDialogModule);
