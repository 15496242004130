import { UserResponse, MyTeamResponse } from 'remonade-api/lib';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import ElectronLocalDataService from './electron/ElectronLocalDataService';

const KEY_CURRENT_USER = 'REMONADE_CURRENT_ACCOUNT';
const KEY_CURRENT_USER_ISSUE_DATE = 'REMONADE_CURRENT_ACCOUNT_ISSUE_DATE';
const KEY_INTERVAL_COUNT = 'REMONADE_INTERVAL_COUNT';
const KEY_ACCESS_TOKEN = 'REMONADE_ACCESS_TOKEN';
const KEY_ID_TOKEN = 'REMONADE_ID_TOKEN';
const KEY_REFRESH_TOKEN = 'REMONADE_REFRESH_TOKEN';
const KEY_WINDOW_ACTIVE_TIMESTAMP = 'REMONADE_WINDOW_ACTIVE_TIMESTAMP';
const KEY_CURRENT_TEAM_ID = 'REMONADE_CURRENT_TEAM_ID';
const KEY_CURRENT_USER_ID = 'REMONADE_CURRENT_USER_ID';
const KEY_MY_ACCOUNTS = 'REMONADE_MY_ACCOUNTS';
const KEY_LAST_PATH = 'REMONADE_LAST_PATH';

class LocalDataService {
  public static setAccessToken(accessToken: string) {
    localStorage.setItem(`${KEY_ACCESS_TOKEN}`, accessToken);
    return accessToken;
  }

  public static getAccessToken(): string | null {
    const saved = localStorage.getItem(`${KEY_ACCESS_TOKEN}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static setIdToken(idToken: string) {
    localStorage.setItem(`${KEY_ID_TOKEN}`, idToken);
    return idToken;
  }

  public static getIdToken(): string | null {
    const saved = localStorage.getItem(`${KEY_ID_TOKEN}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static getAuthType() {
    const token = this.getAccessToken();
    if (token) {
      const tk = jwtDecode(token);
      const { iss } = jwtDecode(token);
      if (iss.indexOf('amazonaws.com') >= 0) {
        return 'USER_POOL';
      }
      return 'OPENID_CONNECT';
    }
    return null;
  }

  public static setRefreshToken(accessToken: string) {
    localStorage.setItem(`${KEY_REFRESH_TOKEN}`, accessToken);
    return accessToken;
  }

  public static getRefreshToken(): string | null {
    const saved = localStorage.getItem(`${KEY_REFRESH_TOKEN}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static setUser(account: UserResponse): UserResponse {
    const stringify = JSON.stringify(account);
    localStorage.setItem(KEY_CURRENT_USER, stringify);
    const now = dayjs().toString();
    localStorage.setItem(KEY_CURRENT_USER_ISSUE_DATE, now);
    return account;
  }

  public static getUser(): UserResponse | null {
    const saved = localStorage.getItem(KEY_CURRENT_USER);
    if (saved) {
      try {
        const account = JSON.parse(saved);
        return account;
      } catch {
        return null;
      }
    }
    return null;
  }

  public static setIntervalCount(count: number): number {
    const countString = '' + count;
    localStorage.setItem(KEY_INTERVAL_COUNT, countString);
    return count;
  }

  public static getIntervalCount(): number {
    const saved = localStorage.getItem(KEY_INTERVAL_COUNT);
    if (saved) {
      return parseInt(saved, 10);
    }
    return 0;
  }

  public static getLocale(): string {
    const savedUser = localStorage.getItem(KEY_CURRENT_USER);
    if (savedUser) {
      const user =  JSON.parse(savedUser);
      if (user.language) {
        return user.language;
      }
    }
    const language = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language;
    const lang = language.slice(0, 2);
    return lang;
  }

  public static setWindowActiveTimestamp(timestamp: string) {
    localStorage.setItem(KEY_WINDOW_ACTIVE_TIMESTAMP, timestamp);
  }

  public static getWindowActiveTimestamp() {
    const savedTimestamp = localStorage.getItem(KEY_WINDOW_ACTIVE_TIMESTAMP);
    return savedTimestamp || null;
  }

  public static setTeamId(teamId: string) {
    localStorage.setItem(KEY_CURRENT_TEAM_ID, teamId);
  }

  public static getTeamId(): string | null {
    return localStorage.getItem(KEY_CURRENT_TEAM_ID) || null;
  }

  public static setUserId(userId: string) {
    localStorage.setItem(KEY_CURRENT_USER_ID, userId);
  }

  public static getUserId(): string | null {
    return localStorage.getItem(KEY_CURRENT_USER_ID) || null;
  }

  public static setMyAccounts(accounts: MyTeamResponse[]) {
    try {
      const accountsString = JSON.stringify(accounts);
      localStorage.setItem(KEY_MY_ACCOUNTS, accountsString);
    } catch (e) {
      return;
    }
  }

  public static getMyAccounts(): MyTeamResponse[] {
    try {
      const accountsString = localStorage.getItem(KEY_MY_ACCOUNTS);
      if (accountsString) {
        const accounts = JSON.parse(accountsString) as MyTeamResponse[];
        return accounts;
      }
    } catch (e) {
      return [];
    }
    return [];
  }

  public static setLastPath(path: string | null) {
    localStorage.setItem(KEY_LAST_PATH, path);
  }

  public static getLastPath(): string | null {
    return localStorage.getItem(KEY_LAST_PATH) || null;
  }
}

export default process.env.IS_ELECTRON ? ElectronLocalDataService : LocalDataService;
