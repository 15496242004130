import { UserResponse, MyTeamResponse } from 'remonade-api/lib';

export default class ElectronLocalDataSercice {
  public static setAccessToken(accessToken: string): string | null {
    return null;
  }

  public static getAccessToken(): string | null {
    return null;
  }

  public static setIdToken(idToken: string): string | null {
    return null;
  }

  public static getIdToken(): string | null {
    return null;
  }

  public static getAuthType(): string | null {
    return null;
  }

  public static setRefreshToken(accessToken: string): string | null {
    return null;
  }

  public static getRefreshToken(): string | null {
    return null;
  }

  public static setUser(account: UserResponse): UserResponse {
    return account;
  }

  public static getUser(): UserResponse | null {
    return null;
  }

  public static setIntervalCount(count: number): number {
    return count;
  }

  public static getIntervalCount(): number {
    return 0;
  }

  public static getLocale(): string {
    return 'en';
  }

  public static setWindowActiveTimestamp(timestamp: string) {
  }

  public static getWindowActiveTimestamp(): string | null {
    return null;
  }

  public static setTeamId(teamId: string): void {

  }

  public static getTeamId(): string | null {
    return null;
  }

  public static setUserId(userId: string): void {
  }

  public static getUserId(): string | null {
    return null;
  }

  public static setMyAccounts(accounts: MyTeamResponse[]): void {
  }

  public static getMyAccounts(): MyTeamResponse[] {
    return [];
  }

  public static setLastPath(path: string | null): void {
  }

  public static getLastPath(): string | null {
    return null;
  }
}
