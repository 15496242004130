import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './style/override.sass';

import 'vue-instant/dist/vue-instant.css';
import VueIntercom from 'vue-intercom';
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css';
import i18n from '@/lang';
import VueTextareaAutosize from 'vue-textarea-autosize';
import VueClipboard from 'vue-clipboard2';
import VueShortkey from 'vue-shortkey';
// import './registerServiceWorker';
import 'firebase/messaging';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import vuetify from 'vuetify';

Vue.config.productionTip = false;

// const firebaseApiKey = process.env.VUE_APP_FIREBASE_API_KEY || '';
// const firebaseAuthDomain = process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || '';
// const firebaseDatabaseURL = process.env.VUE_APP_FIREBASE_DATABASE_URL || '';
// const firebaseProjectId = process.env.VUE_APP_FIREBASE_PROJECT_ID || '';
// const firebaseStorageBucket = process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || '';
// const firebaseMessagingSenderId = process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || '';
// const firebaseAppId = process.env.VUE_APP_FIREBASE_APP_ID || '';
// const firebaseMeasurementId = process.env.VUE_APP_FIREBASE_MEASUREMENT_ID || '';

// const firebaseConfig = {
//   apiKey: firebaseApiKey,
//   authDomain: firebaseAuthDomain,
//   databaseURL: firebaseDatabaseURL,
//   projectId: firebaseProjectId,
//   storageBucket: firebaseStorageBucket,
//   messagingSenderId: firebaseMessagingSenderId,
//   appId: firebaseAppId,
//   measurementId: firebaseMeasurementId,
// };
// firebase.initializeApp(firebaseConfig);

Vue.use(VueShortkey);
Vue.use(PerfectScrollbar);
Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_TOKEN });
Vue.use(VueCroppie);
Vue.use(VueTextareaAutosize);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(vuetify);

new Vue({
  router,
  store,
  i18n,
  vuetify: new vuetify({
    theme: {
      themes: {
        light: {
          primary: '#00af74',
          primaryDark: '#22543d',
          primaryLight: '#00e296',
          primaryLighter: '#e9fcf4',
          primaryBG: '#f9fffc',
          secondary: '#25a199',
          secondaryDark: '#1e817b',
          secondaryLight: '#54ccc5',
          secondaryBG: '#edf9f9',
          danger: '#fa2a5b',
          dangerLight: '#fb6387',
          dangerBG: '#feeaef',
          type: '#012c21',
          typeSlight: '#243a35',
          typeLight: '#32604f',
          typeGray: '#abbdb8',
          gray: '#85928f',
          lightGray: 'cfd7d5',
          ash: '#eef2f4',
          whiteAsh: '#f6f8f9',
        },
      },
    },
  }),
  render: (h) => h(App),
}).$mount('#app');
