import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import LocalDataService from '@/service/LocalDataServices';
import { taskDialogModule } from '@/store/viewModule/task/taskDialogModule';
import { isMobile } from '@/service/StyleService';
import { MobileMenus } from '@/store/viewModule/mobile/mobileViewModule';
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';

const ErrorContainer = () => import('@/container/ErrorContainer.vue');
// scene移行
const InvitationScene = () => import('@/scenes/auth/InvitationScene.vue');
const LinkChatworkScene = () => import('@/scenes/setting/LinkChatworkScene.vue');
const ResetPasswordConfirmScene = () => import('@/scenes/auth/ResetPasswordConfirmScene.vue');
const ResetPasswordScene = () => import('@/scenes/auth/ResetPasswordScene.vue');
const ChangePasswordScene = () => import('@/scenes/auth/ChangePasswordScene.vue');
const RootScene = () => import('@/scenes/RootScene.vue');
const SignInScene = () => import('@/scenes/auth/SignInScene.vue');
const SignUpScene = () => import('@/scenes/auth/SignUpScene.vue');
const CreateTeamScene = () => import('@/scenes/auth/CreateTeamScene.vue');
const OnboardingScene = () => import('@/scenes/auth/OnboardingScene.vue');

// App
const TodayScene = () => import('@/scenes/app/today/TodayScene.vue');
const StatsScene = () => import('@/scenes/app/stats/StatsScene.vue');
const JournalScene = () => import('@/scenes/app/journal/JournalScene.vue');
const TaskpoolScene = () => import('@/scenes/app/taskpool/TaskpoolScene.vue');
const SettingScene = () => import('@/scenes/app/settings/SettingScene.vue');

// Mobile
const MobileScene = () => import('@/scenes/mobile/MobileScene.vue');

// Electron
const ElectronAuth0RedirectScene = () => import('@/scenes/electron/ElectronAuth0RedirectScene.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/signin',
    name: 'signin',
    component: SignInScene,
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUpScene,
  },
  {
    path: '/create-team',
    name: 'create-team',
    component: CreateTeamScene,
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: OnboardingScene,
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    component: ResetPasswordScene,
  },
  {
    path: '/reset_password_confirm',
    name: 'reset_password_confirm',
    component: ResetPasswordConfirmScene,
  },
  {
    path: '/change_password',
    name: 'change_password',
    component: ChangePasswordScene,
  },
  {
    path: '/team/:teamId',
    redirect: (to) => {
      return {
        name: 'app',
        params: {
          teamId: to.params.teamId,
        },
      };
    },
  },
  {
    path: '/today',
    name: 'today',
    component: TodayScene,
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsScene,
  },
  {
    path: '/journal',
    name: 'journal',
    component: JournalScene,
  },
  {
    path: '/taskpool',
    name: 'taskpool',
    component: TaskpoolScene,
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingScene,
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorContainer,
  },
  {
    path: '/slack-redirect/:teamId',
    redirect: (to) => {
      return {
        name: 'settings',
        params: {
          teamId: to.params.teamId,
        },
      };
    },
  },
  {
    path: '/auth0-redirect',
    redirect: (to) => {
      if (!isMobile(window)) {
        if (LocalDataService.getLastPath() !== 'null' && LocalDataService.getLastPath() !== null) {
          const path = LocalDataService.getLastPath().slice(1);
          taskDialogModule.setOpen(true);
          LocalDataService.setLastPath(null);
          return {
            name: path,
            params: {
              teamId: to.query.teamId,
            },
          };
        }
        return {
          name: 'settings',
          params: {
            teamId: to.query.teamId,
          },
        };
      } else {
        settingViewModule.setIsMobileDialogOpen(true);
        return {
          name: 'mobile',
          params: {
            teamId: to.query.teamId,
          },
          query: {
            menu: MobileMenus.today,
          },
        };
      }
    },
  },
  {
    name: 'link-chatwork',
    path: '/link-chatwork',
    component: LinkChatworkScene,
  },
  {
    name: 'root',
    path: '/',
    redirect: (to) => {
      if (!process.env.IS_ELECTRON) {
        const authType = LocalDataService.getAuthType();
        if (authType) {
          if (!isMobile(window)) {
            return '/today';
          } else {
            return '/mobile';
          }
        }
      } else {
        // EDIT THIS BLOCK ONLY IN ELECTRON BRANCH
      }
      return '/signin';
    },
  },
  {
    name: '',
    path: '',
    redirect: (to) => {
      if (!process.env.IS_ELECTRON) {
        const authType = LocalDataService.getAuthType();
        if (authType) {
          if (!isMobile(window)) {
            return '/today';
          } else {
            return '/mobile';
          }
        }
      } else {
        // EDIT THIS BLOCK ONLY IN ELECTRON BRANCH
      }
      return '/signin';
    },
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: MobileScene,
  },
  {
    path: '/invitation',
    name: 'invitation',
    component: InvitationScene,
  },
  {
    path: '/auth0-redirect-electron',
    name: 'auth0-redirect-electron',
    component: ElectronAuth0RedirectScene,
  },
];

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeResolve((to, from, next) => {
//   if (to.name) {
//     NProgress.start();
//     if (
//       to.name === 'app' ||
//       to.name === 'user-setting'
//     ) {
//       const authType = LocalDataService.getAuthType();
//       if (!authType) {
//         next('/signin');
//         return;
//       }
//       if (authType === 'USER_POOL') {
//         const user = LocalDataService.getUser();
//         try {
//           const routeTeamId = to.params.teamId;
//           if (user && routeTeamId) {
//             const userTeamId = user.teamId;
//             if (userTeamId === routeTeamId) {
//               next();
//             } else {
//               throw(null);
//             }
//           } else {
//             throw(null);
//           }
//         } catch (e) {
//           next('/signin');
//         }
//       } else if (authType === 'OPENID_CONNECT') {
//         auth0Module.getAuth0Token().then((token) => {
//           if (token) {
//             const user = LocalDataService.getUser();
//             const routeTeamId = to.params.teamId;
//             if (user && routeTeamId) {
//               const userTeamId = user.teamId;
//               if (userTeamId === routeTeamId) {
//                 next();
//               } else {
//                 throw(null);
//               }
//             } else {
//               throw(null);
//             }
//           }
//         }).catch(() => {
//           next('/signin');
//         });
//       }

//       next();
//     } else {
//       next();
//     }
//   } else {
//     next('/signin');
//   }
// });

// router.afterEach((to, from) => {
//   NProgress.done();
// });

export default router;
