import Vue from 'vue';
import VueI18n from 'vue-i18n';

import jaLocale from './ja';
import enLocale from './en';
import LocalDataService from '@/service/LocalDataServices';

Vue.use(VueI18n);

const messages = {
  ja: {
    ...jaLocale,
  },
  en: {
    ...enLocale,
  },
};

const i18n = new VueI18n({
  locale: LocalDataService.getLocale(),
  fallbackLocale: 'en',
  messages,
});

export default i18n;
