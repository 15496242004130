import {
  UserResponse,
  DailyReportResponse,
  ScheduledReportResponse,
  ActivityResponse,
  ChatReactionResponse,
  TagResponse,
  StatsCompletion,
} from 'remonade-api/lib';

import {
  SelectOptionItem,
  TodayUser,
  ActivityData,
  TaskItem,
  ChatReaction,
  SuggestUser,
  BarChartItem,
} from '@/model/types';

import {
  calcDoneHours,
  calcTotalHours,
  getIsDone,
} from './TaskService';
import dayjs from 'dayjs';

export const hours = [
  0.25,
  0.5,
  1.0,
  1.5,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
];

export const getTodayUser = (user: UserResponse, presence: boolean, reports: DailyReportResponse[], snapUrl: string | null, data: BarChartItem[]): TodayUser => {
  const userReports = reports.filter((r) => r.userId === user.userId);
  const totalHours = calcTotalHours(userReports);
  const completedHours = calcDoneHours(userReports);
  let iconUrl = '';
  let showEmoji = false;
  switch (user.snapType) {
    case 0:
      iconUrl = snapUrl || 'placeholder.jpg';
      showEmoji = false;
      break;
    case 1:
      iconUrl = user.snapIconUrl || 'placeholder.jpg';
      showEmoji = false;
      break;
    case 2:
      iconUrl = user.snapIconUrl || 'placeholder.jpg';
      showEmoji = true;
      break;
    default:
      break;
  }
  return {
    totalHours,
    completedHours,
    username: user.username || '',
    message: user.message,
    emoji: user.snapContent,
    iconUrl,
    showEmoji,
    userId: user.userId,
    progressData: data,
    presence,
    lastActiveAt: user.lastActiveAt,
    nippoUserId: user.nippoUserId,
  };
};

export const getTodayUserWithStats = (user: UserResponse, presence: boolean, stats: StatsCompletion[], snapUrl: string | null, data: BarChartItem[]): TodayUser => {
  const calc: { totalHour: number; doneHour: number; } = stats.reduce((r, s) => {
    return {
      totalHour: r.totalHour + s.totalHour,
      doneHour: r.doneHour + s.doneHour,
    }
  }, { totalHour: 0, doneHour: 0 });
  let iconUrl = '';
  let showEmoji = false;
  switch (user.snapType) {
    case 0:
      iconUrl = snapUrl || 'placeholder.jpg';
      showEmoji = false;
      break;
    case 1:
      iconUrl = user.snapIconUrl || 'placeholder.jpg';
      showEmoji = false;
      break;
    case 2:
      iconUrl = user.snapIconUrl || 'placeholder.jpg';
      showEmoji = true;
      break;
    default:
      break;
  }
  return {
    totalHours: calc.totalHour,
    completedHours: calc.doneHour,
    username: user.username || '',
    message: user.message,
    emoji: user.snapContent,
    iconUrl,
    showEmoji,
    userId: user.userId,
    progressData: data,
    presence,
    lastActiveAt: user.lastActiveAt,
    nippoUserId: user.nippoUserId,
  };
};

export const getTagOption = (tag: TagResponse): SelectOptionItem => {
  return {
    id: tag.tagId,
    value: tag.tagId,
    label: tag.tagName,
    color: tag.color,
  };
};

export const getTaskItem = (task: DailyReportResponse, tags: TagResponse[] = []): TaskItem => {
  const tag = tags.find((t) => t.tagId === task.tag);
  let tagName: string | null = null;
  if (tag) {
    tagName = tag.tagName;
  }
  return {
    hour: task.hour || null,
    tagId: task.tag,
    parentId: task.parentId,
    taskName: task.task || '',
    tagName,
    taskId: task.reportId,
    isDone: task.isDone,
    displayOrder: task.displayOrder,
    userId: task.userId,
    date: task.date,
  };
};

export const getTaskItemScheduled = (task: ScheduledReportResponse) => {
  return {
    hour: task.hour || null,
    tagId: task.tag,
    taskName: task.task || '',
    taskId: task.sReportId,
    userId: task.userId,
  };
};

export const getTaskItems = (tasks: DailyReportResponse[], tags: TagResponse[]): TaskItem[] => {
  return tasks.map((task) => {
    const tag = tags.find((t) => t.tagId === task.tag);
    let tagName: string | null = null;
    let tagColor: string | null = null;
    if (tag) {
      tagName = tag.tagName;
      tagColor = tag.color;
    }
    const isDone = getIsDone(task, tasks);
    return {
      hour: task.hour || null,
      tagId: task.tag,
      parentId: task.parentId,
      taskName: task.task || '',
      tagName,
      tagColor,
      taskId: task.reportId,
      isDone,
      displayOrder: task.displayOrder,
      userId: task.userId,
      type: task.type || null,
      emotion: task.emotion || null,
      date: task.date,
    };
  });
};

export const getActivity = (activity: ActivityResponse, locale: string): ActivityData => {
  let activityString = '';
  if (locale === 'ja') {
    if (activity.actionType === 'task_done') {
      activityString = `${activity.taskName} を完了しました`;
    } else if (activity.actionType === 'task_add') {
      activityString = `${activity.taskName} を追加しました`;
    } else if (activity.actionType === 'login') {
      activityString = `ログインしました`;
    } else if (activity.actionType === 'task_done_cancel') {
      activityString = `${activity.taskName} をキャンセルしました`;
    }
  } else {
    if (activity.actionType === 'task_done') {
      activityString = `Done ${activity.taskName}`;
    } else if (activity.actionType === 'task_add') {
      activityString = `Add ${activity.taskName}`;
    } else if (activity.actionType === 'login') {
      activityString = `Logged in`;
    } else if (activity.actionType === 'task_done_cancel') {
      activityString = `Cancel ${activity.taskName}`;
    }
  }

  return {
    activity: activityString,
    datetime: activity.createdAt,
    timelabel: dayjs(activity.createdAt).format('HH:mm'),
    userId: activity.userId,
  };
};

export const getChatReactions = (reactions: ChatReactionResponse[], users: UserResponse[], me: UserResponse): ChatReaction[] => {
  let chatReactions: ChatReaction[] = [];
  reactions.forEach((reaction) => {
    const item = chatReactions.find((cr) => cr.reaction === reaction.reaction);
    const user = users.find((u) => u.userId === reaction.userId);
    if (item && user) {
      item.count = item.count + 1;
      item.hoverContent = item.hoverContent + `,${user.username}`;
      if (user.userId === me.userId) {
        item.class = 'mine';
      }
      chatReactions = chatReactions.filter((cr) => cr.reaction !== item.reaction);
      chatReactions.push(item);
    } else if (user) {
      const newItem: ChatReaction = {
        count: 1,
        reaction: reaction.reaction,
        hoverContent: `${user.username}`,
        class: (user.userId === me.userId) ? 'mine' : '',
      };
      chatReactions.push(newItem);
    }
  });
  return chatReactions;
};

export const getChatReactionsWithTodayUser = (reactions: ChatReactionResponse[], users: TodayUser[], me: TodayUser): ChatReaction[] => {
  let chatReactions: ChatReaction[] = [];
  reactions.forEach((reaction) => {
    const item = chatReactions.find((cr) => cr.reaction === reaction.reaction);
    const user = users.find((u) => u.userId === reaction.userId);
    if (item && user) {
      item.count = item.count + 1;
      item.hoverContent = item.hoverContent + `,${user.username}`;
      if (user.userId === me.userId) {
        item.class = 'mine';
      }
      chatReactions = chatReactions.filter((cr) => cr.reaction !== item.reaction);
      chatReactions.push(item);
    } else if (user) {
      const newItem: ChatReaction = {
        count: 1,
        reaction: reaction.reaction,
        hoverContent: `${user.username}`,
        class: (user.userId === me.userId) ? 'mine' : '',
      };
      chatReactions.push(newItem);
    }
  });
  return chatReactions;
};

export const getSuggestUser = (user: UserResponse, me: UserResponse) => {
  const suggestUser: SuggestUser = {
    userId: user.userId,
    username: user.username || '',
  };
  if (suggestUser.userId === 'ANYONE') {
    suggestUser.class = 'danger';
  }
  if (suggestUser.userId === me.userId) {
    suggestUser.class = 'mine';
  }
  return suggestUser;
};

interface DailyNoteJSON {
  type: string;
  content: any[];
}

interface DailyNoteNode {
  type: string;
  content?: DailyNoteNode[];
  text?: string;
}

const serializeDailyNoteJSON = (node: DailyNoteNode) => {
  let text = node.text || '';
  if (node.content) {
    const childText = node.content.map((childNode) => serializeDailyNoteJSON(childNode)).join(' ');
    text = text + childText;
  }
  return text;
};

export const shortenJournal = (value: string | null, length?: number): string => {
  if (!value) {
    return '';
  }
  try {
    const json = JSON.parse(value) as DailyNoteNode;
    const plainString = serializeDailyNoteJSON(json);
    const len = length || 40;
    if (plainString.length > len) {
      return plainString.slice(0, len - 2) + '..';
    }
    return plainString;
  } catch {
    return '';
  }
  return '';
};
