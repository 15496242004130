import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

import {
  ScheduledReportResponse,
} from 'remonade-api/lib';
// api
import { getScheduledTaskList, createScheduledTask, updateScheduledTask, deleteScheduledTask } from '@/api/client';

import { userModule } from '../user/userModule';
import LocalDataServices from '@/service/LocalDataServices';

export interface ScheduledReportState {
  scheduledReports: ScheduledReportResponse[];
}

@Module({ dynamic: true, store, name: 'scheduled-report', namespaced: true })
class ScheduledReportModule extends VuexModule implements ScheduledReportState {
  public scheduledReports: ScheduledReportResponse[] = [];

  @Mutation
  public async setScheduledReports(reports: ScheduledReportResponse[]) {
    this.scheduledReports = reports;
  }

  @Mutation
  public async addScheduledReports(reports: ScheduledReportResponse[]) {
    this.scheduledReports = this.scheduledReports.concat(reports);
  }

  @Mutation
  public async removeScheduledReport(sReportId: string) {
    this.scheduledReports = this.scheduledReports.filter((report) => report.sReportId !== sReportId);
  }

  @Action
  public async listScheduledReport() {
    const teamId = LocalDataServices.getTeamId();
    if (teamId) {
      const listResponse = await getScheduledTaskList({ teamId });

      if ('error' in listResponse) {
        return;
      }

      this.setScheduledReports(listResponse.schedules);
    }
  }

  @Action
  public async createScheduledReport({
    teamId,
    userId,
    task,
    hour,
    tag,
    weekday,
  }: {
    teamId: string,
    userId: string,
    task: string,
    hour?: number | null,
    tag?: string | null,
    weekday: number,
  }) {
    const createResponse = await createScheduledTask({
      teamId,
      userId,
      task,
      hour,
      tag,
      weekday,
    });

    if ('error' in createResponse) {
      return;
    }

    this.addScheduledReports([createResponse]);
  }

  @Action
  public async updateScheduledReport({
    sReportId,
    teamId,
    userId,
    task,
    hour,
    tag,
    weekday,
  }: {
    sReportId: string,
    teamId: string,
    userId: string,
    task: string,
    hour?: number | null,
    tag?: string | null,
    weekday?: number,
  }) {
    const input: {
      sReportId: string,
      teamId: string,
      userId: string,
      task: string,
      hour?: number | null,
      tag?: string | null,
      weekday?: number,
    } = {
      sReportId,
      teamId,
      userId,
      task,
      weekday,
    };
    // リゾルバの関係でnullになり得るものはupdateのとき送らない
    if (hour) {
      input.hour = hour;
    }
    if (tag) {
      input.tag = tag;
    }

    const updateResponse = await updateScheduledTask(input);

    if ('error' in updateResponse) {
      return;
    }

    this.refreshScheduledReport(updateResponse);
  }

  @Mutation
  public refreshScheduledReport(report: ScheduledReportResponse) {
    this.scheduledReports = this.scheduledReports.map((sr) => {
      if (sr.sReportId === report.sReportId) {
        return report;
      }
      return sr;
    });
  }

  @Action
  public async deleteScheduledReport({ sReportId, teamId, userId }: {
    sReportId: string,
    teamId: string,
    userId: string,
  }) {
    const deleteResponse = await deleteScheduledTask({ sReportId, teamId, userId });

    if ('error' in deleteResponse) {
      return;
    }

    this.removeScheduledReport(deleteResponse.sReportId);
  }

}

export const scheduledReportModule = getModule(ScheduledReportModule);
