import { post, get, deleteRequest, put, ErrorResponse } from '../common';

import {
  TagResponse,
} from 'remonade-api/lib';

export interface ListTagsResponse {
  tags: TagResponse[];
}

export const getTagList = (
  payload: {
    teamId: string,
  },
) => {
  return get(`/tag`, { teamId: payload.teamId }) as Promise<ListTagsResponse | ErrorResponse>;
};

export const createTag = (
  payload: {
    teamId: string,
    tagName: string,
  },
) => {
  return post('/tag', {
    teamId: payload.teamId,
    tagName: payload.tagName,
  });
};

export const updateTag = (
  payload: {
    tagId: string,
    teamId: string,
    tagName?: string | null,
    isArchived?: boolean | null,
    color?: string | null,
  },
) => {
  return put(`/tag/${payload.tagId}`, {
    teamId: payload.teamId,
    tagName: payload.tagName,
    isArchived: payload.isArchived,
    color: payload.color,
  });
};

export const deleteTag = (
  payload: {
    tagId: string,
    teamId: string,
  },
) => {
  return deleteRequest(`/tag/${payload.tagId}`, {
    teamId: payload.teamId,
  });
};

