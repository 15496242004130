import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { userModule } from '@/store/dataModule/user/userModule';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { uploadImage } from '@/api/client';
import axios from 'axios';
import { snapshotModule } from '@/store/dataModule/snapshot/snapshotModule';
import LocalDataServices from '@/service/LocalDataServices';

export interface CaptureState {
  shouldCapture: boolean;
  timerId: number;
}

@Module({ dynamic: true, store, name: 'capture', namespaced: true })
class CaptureModule extends VuexModule implements CaptureState {
  public captureFunction: (() => void) | null = null;
  public shouldCapture: boolean = false;
  public timerId: number = -1;

  public get user() {
    return userModule.user;
  }

  @Mutation
  public setTimerId(id: number) {
    this.timerId = id;
  }

  @Action
  public releaseTimer() {
    if (this.timerId > 0) {
      window.clearInterval(this.timerId);
      this.setTimerId(-1);
    }
  }

  @Mutation
  public setShouldCapture(shouldCapture: boolean) {
    this.shouldCapture = shouldCapture;
  }

  @Action
  public async postSnapshot(imageString: string) {
    if (userModule.user) {
      fetch(imageString)
      .then((res) => res.blob())
      .then(async (blob) => {
        if (this.user) {
          const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
          const N = 32;
          const uuid = Array.from(crypto.getRandomValues(new Uint8Array(N))).map((n) => S[n % S.length]).join('');
          const preSignedUrl = await uploadImage({ teamId: userModule.user.teamId, fileName: `${uuid}.png` });
          if (preSignedUrl) {
            if ('error' in preSignedUrl) {
              return;
            }
            return axios.put(
              preSignedUrl.url,
              blob,
            ).then((res) => {
              snapshotModule.createSnapshot({
                url: `${this.user.teamId}/${this.user.userId}/${uuid}.png`,
                pixel: userModule.user.pixel,
              });
            });
          }
        }
      });
    }
  }

  @Action
  public async postImage(imageString: string) {
    const res = await fetch(imageString);
    const blob = await res.blob();
    if (this.user) {
      const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      const N = 32;
      const uuid = Array.from(crypto.getRandomValues(new Uint8Array(N))).map((n) => S[n % S.length]).join('');
      const preSignedUrl = await uploadImage({ teamId: this.user.teamId, fileName: `${uuid}.png` });
      if (preSignedUrl) {
        if ('error' in preSignedUrl) {
          return;
        }
        return axios.put(
          preSignedUrl.url,
          blob,
        ).then(() => {
          return `${this.user.teamId}/${this.user.userId}/${uuid}.png`;
        });
      }
    } else {
      return new Promise((resolve) => resolve(null));
    }
  }
}

export const captureModule = getModule(CaptureModule);
