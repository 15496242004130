import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
// store
import { userModule } from '@/store/dataModule/user/userModule';
import { taskpoolModule } from '@/store/dataModule/report/taskpoolModule';
import dayjs from 'dayjs';
import { UserResponse } from 'remonade-api/lib';
import { commonModule } from '../common/commonModule';
import LocalDataServices from '@/service/LocalDataServices';

export interface TaskpoolViewState {
  selectedTag: string;
  currentDate: string;
  isMobileDialogOpen: boolean;
}

@Module({ dynamic: true, store, name: 'taskpool-view', namespaced: true })
class TaskpoolViewModule extends VuexModule implements TaskpoolViewState {
  public selectedTag: string = 'ALL';
  public currentDate: string = dayjs().format('YYYY-MM-DD');
  public isMobileDialogOpen: boolean = false;

  public get user(): UserResponse | null {
    return userModule.user;
  }

  public get teamUsers(): UserResponse[] {
    return userModule.teamUsers;
  }

  public get poolTasks() {
    return taskpoolModule.pooledTasks.filter((t) => t.type === 'pool' && !t.deletedAt).sort((t1, t2) => {
      if (t1.displayOrder && t2.displayOrder) {
        if (t1.displayOrder > t2.displayOrder) {
          return 1;
        }
        if (t1.displayOrder < t2.displayOrder) {
          return -1;
        }
      }
      return 0;
    });
  }

  @Action
  public async getTaskpoolData() {
    const teamId = LocalDataServices.getTeamId();
    if (userModule.user) {
      await taskpoolModule.listTaskpoolTasks(teamId);
    }
  }

  @Action
  public async createPoolTask({ taskItem, suggest }) {
    if (this.user) {
      const displayOrder = dayjs().unix();
      commonModule.setLoader(true);
      await taskpoolModule.createTask({
        teamId: this.user.teamId,
        userId: this.user.userId,
        task: taskItem.taskName || '',
        tag: taskItem.tagId ? taskItem.tagId : undefined,
        estimate: taskItem.hour ? taskItem.hour : undefined,
        displayOrder,
        suggest,
      });
      commonModule.setLoader(false);
    }
  }

  @Mutation
  public selectTag(id: string) {
    this.selectedTag = id;
  }

  @Mutation
  public setIsMobileDialogOpen(isOpen: boolean) {
    this.isMobileDialogOpen = isOpen;
  }
}

export const taskpoolViewModule = getModule(TaskpoolViewModule);
