import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { ChannelResponse } from 'remonade-api/lib';
import { userModule } from '../user/userModule';

// api
import { listChannels, createChannel, addChannelUser, deleteChannelUser, updateChannel, deleteChannel } from '@/api/client';
import LocalDataServices from '@/service/LocalDataServices';

export interface ChannelsState {
  channels: ChannelResponse[];
}

@Module({ dynamic: true, store, name: 'channel', namespaced: true })
class ChannelsModule extends VuexModule implements ChannelsState {
  public channels: ChannelResponse[] = [];

  public get myChannels() {
    if (userModule.user) {
      return this.channels.filter((channel) => {
        const find = channel.users.find((user) => user.userId === userModule.user!.userId);
        if (find) {
          return true;
        }
        return false;
      });
    }
    return [];
  }

  @Action
  public async listChannels() {
    const teamId = LocalDataServices.getTeamId();
    if (teamId) {
      const listResponse = await listChannels({ teamId });
      if ('error' in listResponse) {
        // handle error
        return;
      }
      this.setChannels(listResponse.channels);
    }
  }

  @Mutation
  public setChannels(channels: ChannelResponse[]) {
    this.channels = channels;
  }

  @Mutation
  public addChannels(channels: ChannelResponse[]) {
    this.channels = this.channels.concat(channels);
  }

  @Action
  public async createChannel(channelName: string) {
    if (userModule.user) {
      const createResponse = await createChannel({ teamId: userModule.user.teamId, channelName });
      return createResponse;
    }
  }

  @Action
  public async updateChannel({ channelId, channelName }) {
    if (userModule.user) {
      const updateResponse = await updateChannel({
        channelId,
        teamId: userModule.user.teamId,
        channelName,
      });
      return updateResponse;
    }
  }

  @Action
  public async deleteChannel(channelId: string) {
    if (userModule.user) {
      const deleteResponse = await deleteChannel({
        channelId,
        teamId: userModule.user.teamId,
      });
      return deleteResponse;
    }
  }

  @Action
  public async inviteUserToChannel({
    channelId,
    userId,
    userType,
  }: {
    channelId: string,
    userId: string,
    userType: string,
  }) {
    if (userModule.user) {
      const addResponse = await addChannelUser({
        channelId,
        userId,
        userType,
        teamId: userModule.user.teamId,
      });
    }
  }

  @Action
  public async removeUserFromChannel({
    channelId,
    userId,
  }: {
    channelId: string,
    userId: string,
  }) {
    if (userModule.user) {
      const removeResponse = await deleteChannelUser({
        channelId,
        userId,
        teamId: userModule.user.teamId,
      });
    }
  }
}

export const channelsModule = getModule(ChannelsModule);
