import { Auth0Client } from '@auth0/auth0-spa-js';
import { isElectron } from '@/service/electron/ElectronService';

export const getAuth0RedirectUrl = () => {
  if (isElectron()) {
    // EDIT THIS BLOCK ONLY IN ELECTRON BRANCH
    return 'file://callback';
  } else {
    return window.location.origin + '/signin';
  }
};

export const getAuth0WebAuthRedirectUrl = () => {
  if (isElectron()) {
    // EDIT THIS BLOCK ONLY IN ELECTRON BRANCH
    return 'file://callback_webauth';
  } else {
    return window.location.origin + '/auth0-redirect';
  }
};

export const signinProcess = (client: Auth0Client | null) => {
  if (isElectron()) {
    // EDIT THIS BLOCK ONLY IN ELECTRON BRANCH
  } else if (client) {
    client.loginWithRedirect({
      redirect_uri: window.location.origin + '/signin',
    });
  }
};


