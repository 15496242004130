import { post, get, deleteRequest, put, ErrorResponse } from '../common';

import {
  BookmarkResponse,
} from 'remonade-api/lib';

export interface ListBookmarksResponse {
  bookmarks: BookmarkResponse[];
}

export const listBookmarks = (
  payload: {
    teamId: string,
  },
) => {
  return get('/bookmark', {
    teamId: payload.teamId,
  }) as Promise<ListBookmarksResponse | ErrorResponse>;
};

export const createBookmark = ({
  teamId,
  channelId,
  url,
  title,
  description,
  icon,
}: {
  teamId: string,
  channelId: string | null,
  url: string,
  title: string,
  description: string,
  icon: string,
}) => {
  return post('/bookmark', {
    teamId,
    channelId,
    url,
    title,
    description,
    icon,
  }) as Promise<BookmarkResponse | ErrorResponse>;
};

export const updateBookmark = ({
  bookmarkId,
  teamId,
  channelId,
  url,
  title,
  description,
  icon,
}: {
  bookmarkId: string,
  teamId: string,
  channelId: string | null,
  url: string,
  title: string,
  description: string,
  icon: string,
}) => {
  return put(`/bookmark/${bookmarkId}`, {
    teamId,
    channelId,
    url,
    title,
    description,
    icon,
  }) as Promise<BookmarkResponse | ErrorResponse>;
};

export const deleteBookmark = ({
  bookmarkId,
  teamId,
}: {
  bookmarkId: string,
  teamId: string,
}) => {
  return deleteRequest(`/bookmark/${bookmarkId}`, {
    teamId,
  }) as Promise<BookmarkResponse | ErrorResponse>;
};
