import { get, put } from '../common';

export const getTeam = (
  payload: {
    teamId: string,
  },
) => {
  return get(`/team/${payload.teamId}`, {
  });
};

export const updateTeam = (
  payload: {
    teamId: string,
    timezone?: string | null,
    notificationWeekday?: number | null,
  },
) => {
  return put(`/team/${payload.teamId}`, {
    timezone: payload.timezone,
    notificationWeekday: payload.notificationWeekday,
  });
};
