import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
// models
// store
import LocalDataService from '@/service/LocalDataServices';
import { userModule } from '@/store/dataModule/user/userModule';
import { captureModule } from '../app/captureModule';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { planModule } from '@/store/dataModule/plan/planModule';
import { teamNotificationModule } from '../../dataModule/notification/teamNotificationModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';

// api
import { updateUser, updateTeam, inviteUser, updateUserNotificationSetting } from '@/api/client';
import { GoogleCalendarResponse, PlanResponse, TagResponse, TeamResponse, UserResponse, WhitelistResponse } from 'remonade-api/lib';
import { calendarModule } from '@/store/dataModule/calendar/calendarModule';
import dayjs from 'dayjs';

export interface SettingViewState {
  selectedChannel: string;
  currency: string;
  planTab: number;
  isMobileDialogOpen: boolean;
}

@Module({ dynamic: true, store, name: 'setting-view', namespaced: true })
class SettingViewModule extends VuexModule implements SettingViewState {
  public selectedChannel: string = 'USER';
  public currency: string = 'USD';
  public planTab: number = 0;
  public isMobileDialogOpen: boolean = false;

  public get user(): UserResponse | null {
    const user = LocalDataService.getUser();
    if (userModule.user) {
      return userModule.user;
    } else if (user) {
      return user;
    }
  }

  public get team(): TeamResponse | null {
    return teamModule.team;
  }

  public get isGoogleUser(): boolean {
    return userModule.isGoogleUser;
  }

  public get bit(): number {
    if (this.team) {
      return (this.team.notificationWeekday >>> 0);
    }
    return 0;
  }

  public get userEmailNotification(): boolean {
    if (this.user && this.user.isMailNotificationEnabled) {
      return this.user.isMailNotificationEnabled;
    }
    return false;
  }

  public get planList(): PlanResponse[] {
    return planModule.planList;
  }

  public get usingTags(): TagResponse[] {
    return tagModule.activeTags.filter((tag) => tag.isArchived !== true);
  }

  public get archivedTags(): TagResponse[] {
    return tagModule.activeTags.filter((tag) => tag.isArchived === true);
  }

  public get whitelist(): WhitelistResponse[] {
    return teamModule.whitelist;
  }

  public get teamUsers(): UserResponse[] {
    return userModule.teamUsers;
  }

  public get teamUsersList(): UserResponse[] {
    if (this.user) {
      return this.teamUsers.filter((user) => !user.deletedAt && user.userId !== this.user!.userId);
    } else {
      return this.teamUsers.filter((user) => !user.deletedAt);
    }
  }

  public get calendars(): GoogleCalendarResponse[] {
    return calendarModule.calendars;
  }

  @Action
  public async getSettingData() {
    const user = LocalDataService.getUser();
    if (user) {
      await userModule.findMyUser();
      await teamModule.findTeam(user.teamId);
      if (user.userType === 'admin') {
        await planModule.listRemonadePlans();
        await tagModule.listTags();
        await teamModule.getTeamWhitelist();
        await userModule.listTeamUsers();
        if (this.team && this.team.plan.planId > -1) {
          switch (this.team.plan.planId) {
            case 0:
            case 1:
            case 3:
            case 5:
              this.selectCurrency('JPY');
              this.selectPlanTab(0);
              break;
            case 2:
            case 4:
            case 6:
              this.selectCurrency('JPY');
              this.selectPlanTab(1);
              break;
            case 7:
            case 8:
            case 10:
            case 12:
              this.selectCurrency('USD');
              this.selectPlanTab(0);
              break;
            case 9:
            case 11:
            case 13:
              this.selectCurrency('USD');
              this.selectPlanTab(1);
              break;
            default:
              break;
          }
        }
      }
    }
  }

  @Action
  public async updateUser({ key, value }) {
    if (this.user) {
      const updatedUser = {...this.user};
      switch (key) {
        case 'username':
          updatedUser.username = value;
          break;
        case 'firstName':
          updatedUser.firstName = value;
          break;
        case 'lastName':
          updatedUser.lastName = value;
          break;
        case 'timezone':
          updatedUser.timezone = JSON.stringify(value);
          break;
        case 'language':
          updatedUser.language = value.locale;
          break;
        case 'snapIconUrl':
          const result = await captureModule.postImage(value);
          if (result) {
            updatedUser.snapIconUrl = result as string;
          }
          break;
        case 'isEmailEnabled':
          updatedUser.isMailNotificationEnabled = value;
          break;
        case 'isGoogleCalendarEnabled':
          updatedUser.isGoogleCalendarEnabled = value;
          break;
        case 'googleCalendarIds':
          updatedUser.googleCalendarIds = value;
          break;
        default:
          break;
      }
      const user = await updateUser({
        teamId: updatedUser.teamId,
        userId: updatedUser.userId,
        username: updatedUser.username,
        firstName: updatedUser.firstName,
        lastName: updatedUser.lastName,
        timezone: updatedUser.timezone,
        language: updatedUser.language,
        snapIconUrl: updatedUser.snapIconUrl,
        isMailNotificationEnabled: updatedUser.isMailNotificationEnabled,
        isGoogleCalendarEnabled: updatedUser.isGoogleCalendarEnabled,
        googleCalendarIds: updatedUser.googleCalendarIds,
      });
      if (user) {
        if ('error' in user) {
          return;
        }
        userModule.setUser(user);
      }
    }
  }

  @Action
  public async updatePushNotificationEnabled({ key, value }) {
    if (this.user) {
      const user = await updateUserNotificationSetting({ teamId: this.user.teamId, notificationType: key, isEnabled: value });
      if (user) {
        if ('error' in user) {
          return;
        }
        userModule.setUser(user);
      }
    }
  }

  @Action
  public async updateTeam({ key, value }) {
    if (this.team) {
      const updatedTeam = {...this.team};
      switch (key) {
        case 'timezone':
          updatedTeam.timezone = JSON.stringify(value);
          break;
        case 'Mon':
          if (this.bit & value) {
            updatedTeam.notificationWeekday = this.bit ^ value;
          } else {
            updatedTeam.notificationWeekday = this.bit | value;
          }
          break;
        case 'Tue':
          if (this.bit & value) {
            updatedTeam.notificationWeekday = this.bit ^ value;
          } else {
            updatedTeam.notificationWeekday = this.bit | value;
          }
          break;
        case 'Wed':
          if (this.bit & value) {
            updatedTeam.notificationWeekday = this.bit ^ value;
          } else {
            updatedTeam.notificationWeekday = this.bit | value;
          }
          break;
        case 'Thu':
          if (this.bit & value) {
            updatedTeam.notificationWeekday = this.bit ^ value;
          } else {
            updatedTeam.notificationWeekday = this.bit | value;
          }
          break;
        case 'Fri':
          if (this.bit & value) {
            updatedTeam.notificationWeekday = this.bit ^ value;
          } else {
            updatedTeam.notificationWeekday = this.bit | value;
          }
          break;
        case 'Sat':
          if (this.bit & value) {
            updatedTeam.notificationWeekday = this.bit ^ value;
          } else {
            updatedTeam.notificationWeekday = this.bit | value;
          }
          break;
        case 'Sun':
          if (this.bit & value) {
            updatedTeam.notificationWeekday = this.bit ^ value;
          } else {
            updatedTeam.notificationWeekday = this.bit | value;
          }
          break;
        default:
          break;
      }
      if (!updatedTeam.timezone) {
        updatedTeam.timezone = '';
      }
      const team = await updateTeam({
        teamId: updatedTeam.teamId,
        timezone: updatedTeam.timezone,
        notificationWeekday: updatedTeam.notificationWeekday,
      });
      if (team) {
        if ('error' in team) {
          return;
        }
        teamModule.setTeam(team);
      }
    }
  }

  @Action
  public async changePlan(id: number) {
    try {
      if (this.team) {
        const r = await planModule.changePlan({
          teamId: this.team.teamId,
          planId: id,
        });
        return r;
      }
    } catch (e) {
      return 'error';
    }
  }

  @Action
  public async registerCard({ planId, source }) {
    try {
      if (this.team) {
        if (this.team.payment && this.team.payment.isPaymentRegistered) {
          const r  = await planModule.editCard({
            teamId: this.team.teamId,
            source,
          });
          return r;
        } else {
          const r = await planModule.registerCard({
            teamId: this.team.teamId,
            planId,
            source,
          });
          return r;
        }
      }
    } catch (e) {
      return 'error';
    }
  }

  @Action
  public async createTag(name: string) {
    if (this.team) {
      await tagModule.createTag({
        teamId: this.team.teamId,
        tag: name,
      });
    }
  }

  @Action
  public async updateTag(tag: TagResponse) {
    await tagModule.updateTag(tag);
  }

  @Action
  public async deleteTag(tag: TagResponse) {
    await tagModule.deleteTag(tag);
  }

  @Action
  public async updateTagArchive(tag: TagResponse) {
    const newTag = tag;
    if (tag.isArchived !== null) {
      newTag.isArchived = !tag.isArchived;
    } else {
      newTag.isArchived = true;
    }
    await tagModule.updateTag(newTag);
  }

  @Action
  public async updateDomain(value: WhitelistResponse[]) {
    if (value.length > this.whitelist.length) {
      value.map(async (v) => {
        const find = this.whitelist.find((w) => w.domain === v.domain);
        if (!find) {
          await teamModule.createDomainWhitelist({
            teamId: v.teamId,
            domain: v.domain,
          });
        }
      });
    } else if (value.length < this.whitelist.length) {
      this.whitelist.map(async (w) => {
        const find = value.find((v) => v.domain === w.domain);
        if (!find) {
          await teamModule.deleteDomainWhitelist({
            teamId: w.teamId,
            domain: w.domain,
          });
        }
      });
    }
  }

  @Action
  public async createDomain(value: WhitelistResponse[]) {
    value.map(async (v) => {
      const find = this.whitelist.find((w) => w.domain === v.domain);
      if (!find) {
        await teamModule.createDomainWhitelist({
          teamId: v.teamId,
          domain: v.domain,
        });
      }
    });
  }

  @Action
  public async updateUserRole(user: UserResponse) {
    await userModule.changeRole(user);
  }

  @Action
  public async suspendUser(user: UserResponse) {
    await userModule.suspendUser(user);
    await userModule.listTeamUsers();
  }

  @Action
  public async inviteUser({ teamId, email }) {
    await inviteUser({
      teamId,
      email,
    });
  }

  @Action
  public async inviteUsers(emails: string[]) {
    if (this.team && this.user) {
      await Promise.all(
        emails
          .map((email) => this.inviteUser({ teamId: this.team!.teamId, email})),
      );
      await userModule.listTeamUsers();
    }
  }

  @Action
  public async linkSlack({ code, host }) {
    if (this.team) {
      await teamNotificationModule.linkSlack({ code, host, teamId: this.team.teamId });
    }
  }

  @Action
  public async linkChatwork({ code, host }) {
    if (this.team && this.user) {
      await teamNotificationModule.linkChatwork({ code, host, teamId: this.team.teamId, userId: this.user.userId });
    }
  }

  @Action
  public async getSlackChannelList() {
    if (this.team) {
      await teamNotificationModule.getSlackChannelList(this.team.teamId);
    }
  }

  @Action
  public async getChatworkChannelList() {
    if (this.team) {
      await teamNotificationModule.getChatworkChannelList(this.team.teamId);
    }
  }

  @Action
  public async unlinkSlack() {
    if (this.team) {
      await teamNotificationModule.unlinkSlack(this.team.teamId);
    }
  }

  @Action
  public async unlinkChatwork() {
    if (this.team) {
      await teamNotificationModule.unlinkChatwork(this.team.teamId);
    }
  }

  @Action
  public async getGoogleCalendars() {
    const r = await calendarModule.getGoogleCalendars(dayjs().format('YYYY-MM-DD'));
    return r;
  }

  @Action
  public async authorizeGoogleCalendar() {
    await calendarModule.authorizeGoogleCalendar();
  }

  @Mutation
  public selectChannel(channel: string) {
    this.selectedChannel = channel;
  }

  @Mutation
  public selectCurrency(value: string) {
    this.currency = value;
  }

  @Mutation
  public selectPlanTab(tab: number) {
    this.planTab = tab;
  }

  @Mutation
  public setIsMobileDialogOpen(isOpen: boolean) {
    this.isMobileDialogOpen = isOpen;
  }
}

export const settingViewModule = getModule(SettingViewModule);
