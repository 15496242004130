import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { reportsModule } from '@/store/dataModule/report/reportsModule';
import { userModule } from '../user/userModule';
import dayjs from 'dayjs';

// api
import { listReports, createReport, updateReport, deleteReport } from '@/api/client';
import { DailyReportResponse } from 'remonade-api/lib';
import { teamModule } from '../team/teamModule';

export interface TaskpoolState {
  pooledTasks: DailyReportResponse[];
}

@Module({ dynamic: true, store, name: 'taskpool', namespaced: true })
class TaskpoolModule extends VuexModule implements TaskpoolState {
  public pooledTasks: DailyReportResponse[] = [];

  public get myTasksCounts() {
    return this.pooledTasks.filter((t) => {
      if (!t.suggest) {
        return false;
      }
      if (userModule.user) {
        return t.suggest.indexOf(userModule.user.userId) >= 0;
      }
      return false;
    }).length;
  }

  public get anyoneTasksCounts() {
    return this.pooledTasks.filter((t) => {
      if (!t.suggest) {
        return false;
      }
      return t.suggest.indexOf('ANYONE') >= 0;
    }).length;
  }

  @Action
  public async listTaskpoolTasks(teamId: string) {
    const reportList = await listReports({
      teamId,
      type: 'pool',
      queryType: 'team_type',
    });
    if (reportList) {
      if ('error' in reportList) {
        return;
      }
      if (reportList.reports) {
        this.setTasks(reportList.reports);
      }
    }
  }

  @Mutation
  public setTasks(tasks: DailyReportResponse[]) {
    this.pooledTasks = tasks;
  }

  @Mutation
  public addTask(task: DailyReportResponse) {
    this.pooledTasks.push(task);
  }

  @Mutation
  public removeTask(task: DailyReportResponse) {
    this.pooledTasks = this.pooledTasks.filter((t) => t.reportId !== task.reportId);
  }

  @Mutation
  public replaceTask(task: DailyReportResponse) {
    this.pooledTasks = this.pooledTasks.filter((t) => t.reportId !== task.reportId);
    this.pooledTasks.push(task);
  }

  @Action
  public async createTask(payload: {
    teamId: string,
    userId: string,
    task: string,
    tag: string | null,
    estimate: number | null,
    displayOrder: number,
    suggest: string | null,
  }) {
    const report = await createReport({
      teamId: payload.teamId,
      userId: payload.userId,
      date: dayjs().format('YYYY-MM-DD'),
      type: 'pool',
      task: payload.task,
      tag: payload.tag ? payload.tag : undefined,
      hour: payload.estimate ? payload.estimate : undefined,
      displayOrder: payload.displayOrder,
      suggest: payload.suggest ? payload.suggest : undefined,
    });
    if (report) {
      if ('error' in report) {
        return;
      }
      this.addTask(report as DailyReportResponse);
    }
  }

  @Action
  public async updateTask(task: DailyReportResponse) {
    const report = await updateReport({
      reportId: task.reportId,
      teamId: task.teamId,
      userId: task.userId,
      type: task.type,
      task: task.task,
      tag: task.tag,
      hour: task.hour,
      emotion: task.emotion,
      displayOrder: task.displayOrder,
      parentId: task.parentId,
      isPrivate: task.isPrivate,
      suggest: task.suggest,
      copied: task.copied ? task.copied as boolean : null,
    });
    if (report) {
      if ('error' in report) {
        return;
      }
      this.replaceTask(report as DailyReportResponse);
    }
  }

  @Action
  public async moveToToday(task: DailyReportResponse) {
    const report = await updateReport({
        reportId: task.reportId,
        teamId: task.teamId,
        userId: task.userId,
        type: task.type,
        date: task.date,
        task: task.task,
        tag: task.tag,
        hour: task.hour,
        emotion: task.emotion,
        displayOrder: task.displayOrder,
        parentId: task.parentId,
        isPrivate: task.isPrivate,
        suggest: task.suggest,
        copied: task.copied ? task.copied as boolean : null,
    });
    if (report) {
      if ('error' in report) {
        return;
      }
      this.removeTask(report as DailyReportResponse);
      reportsModule.refreshReport(report as DailyReportResponse);
    }
  }

  @Action
  public async deleteTask(task) {
    if (teamModule.team) {
      const report = await deleteReport({ reportId: task.reportId, teamId: teamModule.team.teamId });
      if (report) {
        if ('error' in report) {
          return;
        }
        this.removeTask(report as DailyReportResponse);
      }
    }
  }
}

export const taskpoolModule = getModule(TaskpoolModule);
