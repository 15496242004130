import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import dayjs from 'dayjs';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { TodayUser } from '@/model/types';
import { userModule } from '@/store/dataModule/user/userModule';
import { reportsModule } from '@/store/dataModule/report/reportsModule';
import { getDoneRateChartData, calcDoneRate, calcAveHours, getCompletionAndTaskHourData, getTasksByTagData, getCompletionByTagData, getTasksByDayData, getCompletionByGroupData, calcDoneRateWithStats, getTodayStatsSummary, getCompletionAndTaskHourDataWithStats, getTasksByTagDataWithStats, getTasksByDayDataWithStats } from '@/service/ui/StatsService';
import { snapshotModule } from '@/store/dataModule/snapshot/snapshotModule';
import { getTodayUser, getTodayUserWithStats } from '@/service/ui/ModelService';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { DailyReportResponse, ChannelResponse, StatsCompletion, StatsByTag } from 'remonade-api/lib';
import { pusherModule } from '@/store/dataModule/pusher/pusherModule';
import { planModule } from '@/store/dataModule/plan/planModule';
import { statsModule } from '@/store/dataModule/stats/statsModule';
import LocalDataServices from '@/service/LocalDataServices';
import { commonModule } from '../common/commonModule';

export interface StatsViewState {
  endDate: string;
  startDate: string;
  comparisonEndDate: string;
  comparisonStartDate: string;
  selectedChannelId: string;
  selectedUserId: string | null;
  currentMonth: string;
}

export interface ChannelWithProgress {
  channel: ChannelResponse;
  progress: number;
  progressComparison: number;
  averageHour: number;
  averageHourComparison: number;
  count: number;
  countComparison: number;
}

@Module({ dynamic: true, store, name: 'stats-view', namespaced: true })
class StatsViewModule extends VuexModule implements StatsViewState {
  public selectedChannelId = 'MINE';

  public endDate = dayjs().format('YYYY-MM-DD');
  public startDate = dayjs().subtract(13, 'day').format('YYYY-MM-DD');

  public comparisonEndDate = dayjs().subtract(14, 'day').format('YYYY-MM-DD');
  public comparisonStartDate = dayjs().subtract(18, 'day').format('YYYY-MM-DD');

  public selectedUserId: string | null = null;
  public currentMonth: string = dayjs().format('YYYY-MM');

  @Mutation
  public setStartDate(date: string) {
    this.startDate = date;
  }

  @Mutation
  public setEndDate(date: string) {
    this.endDate = date;

    const diff = dayjs(this.endDate).diff(dayjs(this.startDate), 'day');
    this.comparisonEndDate = dayjs(this.startDate).subtract(1, 'day').format('YYYY-MM-DD');
    this.comparisonStartDate = dayjs(this.comparisonEndDate).subtract(diff, 'day').format('YYYY-MM-DD');
  }

  public get me(): TodayUser {
    if (userModule.user) {
      const id = userModule.user.userId;
      const myStats = statsModule.teamStats.find((s) => s.userId === id);

      const myTodayReports = reportsModule.todayReports.filter((r) => r.userId.endsWith(id));
      const data = getTodayStatsSummary(myStats ? myStats.completions : [], myTodayReports, this.startDate, this.endDate);

      const snap = snapshotModule.snapshots.find((s) => s.userId === `${userModule.user!.teamId}#${userModule.user!.userId}`) || null;
      const presence = pusherModule.presenceUsers.includes(userModule.user.userId);
      return getTodayUserWithStats(userModule.user, presence, myStats ? myStats.completions : [], snap ? snap.originalUrl : null, data);
    }
    return null;
  }

  public get teamUsers(): TodayUser[] {
    if (userModule.user) {
      return userModule.teamUsers
        .filter((tu) => tu.userId !== userModule.user!.userId)
        .map((u) => {
          const userStats = statsModule.teamStats.filter((s) => s.userId === u.userId);
          const userTodayReports = reportsModule.todayReports.filter((r) => r.userId === u.userId);
          const data = getTodayStatsSummary(userStats.flatMap((s) => s.completions), userTodayReports, this.startDate, this.endDate);
          const totalHour = userStats.flatMap((s) => s.completions).reduce((h, s) => {
            return h + s.totalHour;
          }, 0);
          const snap = snapshotModule.snapshots.find((s) => s.userId === `${u.teamId}#${u.userId}`) || null;
          const presence = pusherModule.presenceUsers.includes(u.userId);
          return {
            todayUser: getTodayUserWithStats(u, presence, userStats.flatMap((s) => s.completions), snap ? snap.originalUrl : null, data),
            totalHour,
          };
        })
        .sort((obj1, obj2) => obj2.totalHour - obj1.totalHour)
        .map((obj) => obj.todayUser);
    }
    return [];
  }

  public get selectedChannelUsers(): TodayUser[] {
    if (this.selectedChannelId === 'MINE') {
      if (this.me) {
        return [this.me];
      }
      return [];
    } else if (this.selectedChannelId === 'ALL') {
      if (this.me) {
        return [this.me, ...this.teamUsers];
      }
      return this.teamUsers;
    } else {
      const channel = channelsModule.channels.find((c) => c.channelId === this.selectedChannelId);
      if (channel) {
        const result: TodayUser[] = [];
        channel.users.forEach((mapping) => {
          const user = this.teamUsers.find((teamUser) => teamUser.userId === mapping.userId);
          if (user) {
            result.push(user);
          }
        });
        return result;
      }
      return [];
    }
  }

  public get selectedChannelStatsCompletions(): StatsCompletion[] {
    if (this.selectedChannelId === 'MINE') {
      if (this.me) {
        return statsModule.teamStats.filter((s) => s.userId === this.me!.userId).flatMap((s) => s.completions);
      }
      return [];
    } else if (this.selectedChannelId === 'ALL') {
      return statsModule.teamStats.flatMap((s) => s.completions);
    } else {
      const channel = channelsModule.channels.find((c) => c.channelId === this.selectedChannelId);
      if (channel) {
        return channel.users.flatMap((user) => statsModule.teamStats.filter((s) => s.userId === user.userId).flatMap((s) => s.completions));
      }
      return [];
    }
  }

  public get selectedChannelTagStats(): StatsByTag[] {
    if (this.selectedChannelId === 'MINE') {
      if (this.me) {
        return statsModule.userTagStats.filter((s) => s.userId === this.me!.userId).flatMap((s) => s.statsByTag);
      }
      return [];
    } else if (this.selectedChannelId === 'ALL') {
      return statsModule.userTagStats.flatMap((s) => s.statsByTag);
    } else {
      const channel = channelsModule.channels.find((c) => c.channelId === this.selectedChannelId);
      if (channel) {
        return channel.users.flatMap((user) => statsModule.userTagStats.filter((s) => s.userId === user.userId).flatMap((s) => s.statsByTag));
      }
      return [];
    }
  }

  public get selectedChannelReports(): DailyReportResponse[] {
    if (this.selectedChannelId === 'MINE') {
      return reportsModule.reports.filter((report) => {
        return (
          report.userId === this.me!.userId &&
          report.date >= this.startDate &&
          report.date <= this.endDate
        );
      });
    } else if (this.selectedChannelId === 'ALL') {
      return reportsModule.reports.filter((report) => {
        return (
          report.date >= this.startDate &&
          report.date <= this.endDate
        );
      });
    } else {
      const channel = channelsModule.channels.find((c) => c.channelId === this.selectedChannelId);
      if (channel) {
        let result: DailyReportResponse[] = [];
        channel.users.forEach((user) => {
          const reports = reportsModule.reports.filter((report) => {
            return (
              report.userId === user.userId &&
              report.date >= this.startDate &&
              report.date <= this.endDate
            );
          });
          result = result.concat(reports);
        });
        return result;
      }
      return [];
    }
  }

  public get selectedChannelComparisonReports(): DailyReportResponse[] {
    if (this.selectedChannelId === 'MINE') {
      return reportsModule.reports.filter((report) => {
        return (
          report.userId === this.me!.userId &&
          report.date >= this.comparisonStartDate &&
          report.date <= this.comparisonEndDate
        );
      });
    } else if (this.selectedChannelId === 'ALL') {
      return reportsModule.reports.filter((report) => {
        return (
          report.date >= this.comparisonStartDate &&
          report.date <= this.comparisonEndDate
        );
      });
    } else {
      const channel = channelsModule.channels.find((c) => c.channelId === this.selectedChannelId);
      if (channel) {
        let result: DailyReportResponse[] = [];
        channel.users.forEach((user) => {
          const reports = reportsModule.reports.filter((report) => {
            return (
              report.userId === user.userId &&
              report.date >= this.comparisonStartDate &&
              report.date <= this.comparisonEndDate
            );
          });
          result = result.concat(reports);
        });
        return result;
      }
      return [];
    }
  }

  /**
   * Selected user
   */
  public get selectedUser(): TodayUser | null {
    if (!this.selectedUserId) {
      return null;
    }

    if (this.me && this.selectedUserId === this.me.userId) {
      return this.me;
    }

    const find = this.teamUsers.find((teamUser) => teamUser.userId === this.selectedUserId);
    return find || null;
  }

  public get selectedUserCompletions() {
    return statsModule.teamStats.filter((s) => s.userId === this.selectedUser.userId).flatMap((s) => s.completions);
  }

  public get selectedUserStats() {
    return statsModule.userStats.find((s) => s.userId === this.selectedUser.userId) || null;
  }

  public get selectedUserTagStats() {
    return statsModule.userTagStats.find((s) => s.userId === this.selectedUser.userId) || null;
  }

  public get selectedUserCompletionAndHourData() {
    if (this.selectedUserCompletions.length > 0) {
      return getCompletionAndTaskHourDataWithStats(this.selectedUserCompletions, this.startDate, this.endDate);
    }
    return null;
  }

  public get selectedUserTasksByTagData() {
    if (this.selectedUserId && this.selectedUserTagStats) {
      return getTasksByTagDataWithStats(this.selectedUserTagStats.statsByTag, tagModule.activatedTags);
    }
    return null;
  }

  public get selectedUserTasksByDayData() {
    if (this.selectedUserCompletions.length > 0) {
      return getTasksByDayDataWithStats(this.selectedUserCompletions);
    }
    return null;
  }

  /**
   * Getters: Completion
   */
  public get myCompletion(): number {
    if (this.me) {

      const myStats = statsModule.userStats.find((s) => s.userId === this.me.userId);
      if (myStats && myStats.summary) {
        return myStats.summary.completion;
      }
    }
    return 0;
  }

  public get myCompletionComparison(): number {
    if (this.me) {
      // この処理まとめたほうがいいかもしれない。
      const myStats = statsModule.userStats.find((s) => s.userId === this.me.userId);
      if (myStats && myStats.summary) {
        return myStats.summary.completionComparison;
      }
    }
    return 0;
  }

  public get allCompletion(): number {
    const data: { doneHour: number; totalHour; } = statsModule.userStats.reduce((r, s) => {
      if (s.summary) {
        return {
          doneHour: r.doneHour + s.summary.tasksCount * s.summary.averageTaskHour * s.summary.completion,
          totalHour: r.totalHour + s.summary.tasksCount * s.summary.averageTaskHour,
        };
      }
      return {
        doneHour: r.doneHour,
        totalHour: r.totalHour,
      };
    }, { doneHour: 0, totalHour: 0 });

    return data.doneHour / data.totalHour;
  }

  public get allCompletionComparison(): number {
    const data: { doneHour: number; totalHour; } = statsModule.userStats.reduce((r, s) => {
      if (s.summary) {
        return {
          doneHour: r.doneHour + s.summary.tasksCountComparison * s.summary.averageTaskHourComparison * s.summary.completionComparison,
          totalHour: r.totalHour + s.summary.tasksCountComparison * s.summary.averageTaskHourComparison,
        };
      }
      return {
        doneHour: r.doneHour,
        totalHour: r.totalHour,
      };
    }, { doneHour: 0, totalHour: 0 });

    return data.doneHour / data.totalHour;
  }

  public get allTasks(): DailyReportResponse[] {
    return reportsModule.reports.filter((report) => {
      return (
        report.date >= this.startDate &&
        report.date <= this.endDate
      );
    });
  }

  public get tasksByGroup() {
    const channels = channelsModule.myChannels;
    return channels.map((channel) => {
      let reports: DailyReportResponse[] = [];
      channel.users.forEach((user) => {
        const userReports = reportsModule.reports.filter((report) => {
          return (
            report.userId === user.userId &&
            report.date >= this.startDate &&
            report.date <= this.endDate
          );
        });
        reports = reports.concat(userReports);
      });

      return {
        channel,
        reports,
      };
    });
  }

  public get tasksByTag() {
    return tagModule.activatedTags.map((tag) => {
      const reports = this.selectedChannelReports;
      const tagReports = reports.filter((r) => r.tag === tag.id);
      return {
        tag,
        reports: tagReports,
      };
    });
  }

  /**
   * Getters: Channel stats
   */
  public get channelCompletion() {
    if (this.selectedChannelId === 'MINE') {
      return this.myCompletion;
    } else if (this.selectedChannelId === 'ALL') {
      return this.allCompletion;
    } else {
      const find = this.myChannelsWithProgress.find((channel) => channel.channel.channelId === this.selectedChannelId);
      if (find) {
        return find.progress;
      }
      return 0;
    }
  }

  public get channelCompletionComparison() {
    if (this.selectedChannelId === 'MINE') {
      return this.myCompletionComparison;
    } else if (this.selectedChannelId === 'ALL') {
      return this.allCompletionComparison;
    } else {
      const find = this.myChannelsWithProgress.find((channel) => channel.channel.channelId === this.selectedChannelId);
      if (find) {
        return find.progressComparison;
      }
      return 0;
    }
  }

  public get channelTasksCount() {
    if (this.selectedChannelId === 'MINE') {
      const myStats = statsModule.userStats.find((s) => s.userId === this.me.userId);
      if (myStats && myStats.summary) {
        return myStats.summary.tasksCount;
      }
      return 0;
    } else if (this.selectedChannelId === 'ALL') {
      return statsModule.userStats.reduce((count: number, s) => {
        if (s.summary) {
          return count + s.summary.tasksCount;
        }
        return count;
      }, 0);
    } else {
      const find = this.myChannelsWithProgress.find((channel) => channel.channel.channelId === this.selectedChannelId);
      if (find) {
        return find.count;
      }
      return 0;
    }
  }

  public get channelTasksCountComparison() {
    if (this.selectedChannelId === 'MINE') {
      const myStats = statsModule.userStats.find((s) => s.userId === this.me.userId);
      if (myStats && myStats.summary) {
        return myStats.summary.tasksCountComparison;
      }
      return 0;
    } else if (this.selectedChannelId === 'ALL') {
      return statsModule.userStats.reduce((count: number, s) => {
        if (s.summary) {
          return count + s.summary.tasksCountComparison;
        }
        return count;
      }, 0);
    } else {
      const find = this.myChannelsWithProgress.find((channel) => channel.channel.channelId === this.selectedChannelId);
      if (find) {
        return find.countComparison;
      }
      return 0;
    }
  }

  public get channelAverageHour() {
    if (this.selectedChannelId === 'MINE') {
      const myStats = statsModule.userStats.find((s) => s.userId === this.me.userId);
      if (myStats && myStats.summary) {
        return myStats.summary.averageTaskHour;
      }
      return 0;
    } else if (this.selectedChannelId === 'ALL') {
      const result: { hour: number; count: number; } = statsModule.userStats.reduce((r, s) => {
        if (s.summary) {
          return {
            hour: r.hour + (s.summary.averageTaskHour * s.summary.tasksCount),
            count: r.count + s.summary.tasksCount,
          };
        }
        return r;
      }, { hour: 0, count: 0 });
      return result.hour / result.count;
    } else {
      const find = this.myChannelsWithProgress.find((channel) => channel.channel.channelId === this.selectedChannelId);
      if (find) {
        return find.averageHour;
      }
      return 0;
    }
  }

  public get channelAverageHourComparison() {
    if (this.selectedChannelId === 'MINE') {
      const myStats = statsModule.userStats.find((s) => s.userId === this.me.userId);
      if (myStats && myStats.summary) {
        return myStats.summary.averageTaskHourComparison;
      }
      return 0;
    } else if (this.selectedChannelId === 'ALL') {
      const result: { hour: number; count: number; } = statsModule.userStats.reduce((r, s) => {
        if (s.summary) {
          return {
            hour: r.hour + (s.summary.averageTaskHourComparison * s.summary.tasksCountComparison),
            count: r.count + s.summary.tasksCountComparison,
          };
        }
        return r;
      }, { hour: 0, count: 0 });
      return result.hour / result.count;
    } else {
      const find = this.myChannelsWithProgress.find((channel) => channel.channel.channelId === this.selectedChannelId);
      if (find) {
        return find.averageHourComparison;
      }
      return 0;
    }
  }

  /**
   * Getters: Channels & progress
   */
  public get myChannelsWithProgress(): ChannelWithProgress[] {
    const channels = channelsModule.myChannels;
    return channels.map((channel) => {
      // let todayReports: DailyReportResponse[] = [];
      // let statsComplitions: StatsCompletion[] = [];

      const data: {
        doneHour: number;
        doneHourComparison: number;
        totalHour: number;
        totalHourComparison: number;
        count: number;
        countComparison: number;
      } = channel.users
        .flatMap((user) => statsModule.userStats.filter((s) => s.userId === user.userId))
        .flatMap((s) => s.summary)
        .reduce((r, s) => {
          return {
            doneHour: r.doneHour + s.tasksCount * s.averageTaskHour * s.completion,
            doneHourComparison: r.doneHourComparison + s.tasksCountComparison * s.averageTaskHourComparison * s.completionComparison,
            totalHour: r.totalHour + s.tasksCount * s.averageTaskHour,
            totalHourComparison: r.totalHourComparison + s.tasksCountComparison * s.averageTaskHourComparison,
            count: r.count + s.tasksCount,
            countComparison: r.countComparison + s.tasksCountComparison,
          };
        }, {
          doneHour: 0,
          doneHourComparison: 0,
          totalHour: 0,
          totalHourComparison: 0,
          count: 0,
          countComparison: 0,
        });
      
      const progress = data.doneHour / data.totalHour;
      const progressComparison = data.doneHourComparison / data.totalHourComparison;
      const averageHour = data.totalHour / data.count;
      const averageHourComparison = data.totalHourComparison / data.countComparison;

      return {
        channel,
        progress,
        progressComparison,
        averageHour,
        averageHourComparison,
        count: data.countComparison,
        countComparison: data.countComparison,
      };
    });
  }

  /**
   * Data
   */
  @Action
  public async getStatsData(force?: boolean) { // fetch all data that today grid need
    const teamId = LocalDataServices.getTeamId();
    if (force) {
      if (teamId) {
        commonModule.setLoader(true);
        await Promise.all([
          statsModule.listTeamStats({
            teamId,
            startDt: this.startDate,
            endDt: this.endDate,
          }),
          statsModule.listUserStatsWithTeamId({
            teamId,
            startDt: this.startDate,
            endDt: this.endDate,
          }),
        ]);
        commonModule.setLoader(false);
      }
      return;
    }

    if (teamId) {
      statsModule.listTeamStats({
        teamId,
        startDt: this.startDate,
        endDt: this.endDate,
      });
      statsModule.listUserStatsWithTeamId({
        teamId,
        startDt: this.startDate,
        endDt: this.endDate,
      });
    }
  }

  @Mutation
  public selectChannel(channelId: string) {
    this.selectedChannelId = channelId;
  }

  @Mutation
  public selectUser(userId: string | null) {
    this.selectedUserId = userId;
  }

  public get minDate() {
    return planModule.minDate;
  }
}

export const statsViewModule = getModule(StatsViewModule);
