import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { getTagOption } from '@/service/ui/ModelService';

// api
import { getTagList, createTag, updateTag, deleteTag } from '@/api/client';
import { TagResponse } from 'remonade-api/lib';
import LocalDataServices from '@/service/LocalDataServices';

export interface TagState {
  tags: TagResponse[];
}

@Module({ dynamic: true, store, name: 'tag', namespaced: true })
class TagModule extends VuexModule implements TagState {
  public tags: TagResponse[] = [];

  public get activeTags() {
    return this.tags.filter((t) => !t.deletedAt);
  }

  public get activatedTags() {
    return this.tags.filter((tag) => tag.isArchived !== true).map((tag) => getTagOption(tag));
  }

  @Action
  public async listTags() {
    const teamId = LocalDataServices.getTeamId();
    if (teamId) {
      const listResponse = await getTagList({ teamId });
      if ('error' in listResponse) {
        return;
      }

      this.setTags(listResponse.tags);
    }
  }

  @Action
  public async updateTag(tag: TagResponse) {
    const updatedTag = await updateTag({
      tagId: tag.tagId,
      teamId: tag.teamId,
      tagName: tag.tagName,
      isArchived: tag.isArchived,
      color: tag.color,
    });
    if (updatedTag) {
      if ('error' in updatedTag) {
        return;
      }
      this.replaceTag(updatedTag);
    }
  }

  @Action
  public async createTag({ teamId, tag }: { teamId: string, tag: string }) {
    const newTag = await createTag({ teamId, tagName: tag });
    if (newTag) {
      if ('error' in newTag) {
        return;
      }
      this.addTag(newTag);
    }
  }

  @Action
  public async deleteTag(tag: TagResponse) {
    const deletedTag = await deleteTag({
      tagId: tag.tagId,
      teamId: tag.teamId,
    });
    if (deletedTag) {
      if ('error' in deletedTag) {
        return;
      }
      this.removeTag(deletedTag);
    }
  }


  @Mutation
  public setTags(tags: TagResponse[]) {
    this.tags = tags;
  }

  @Mutation
  public addTag(tag: TagResponse) {
    this.tags.push(tag);
  }

  @Mutation
  public replaceTag(tag: TagResponse) {
    const index = this.tags.findIndex((t) => t.tagId === tag.tagId);
    this.tags.splice(index, 1, tag);
  }

  @Mutation
  public removeTag(tag: TagResponse) {
    this.tags = this.tags.filter((t) => t.tagId !== tag.tagId);
  }
}

export const tagModule = getModule(TagModule);
