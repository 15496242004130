import { get, post, postNoAuth, ErrorResponse } from '../common';

import { SigninResponse, CryptoPasswordUtils, AuthResponse } from 'remonade-api/lib';

export const signUpExternal = (
  payload: {
    teamId: string,
    name: string,
    username: string,
    language: string,
    firstName?: string | null,
    lastName?: string | null,
  },
) => {
  return post('/auth/signup/external', {
    teamId: payload.teamId,
    name: payload.name,
    username: payload.username,
    language: payload.language,
    firstName: payload.firstName,
    lastName: payload.lastName,
  });
};

export const signUp = (
  payload: {
    teamId: string,
    name: string,
    email: string,
    password: string,
    username: string,
    language: string,
    firstName?: string | null,
    lastName?: string | null,
  },
) => {
  const cryptUtils = new CryptoPasswordUtils(process.env.VUE_APP_AUTH_AES_KEY, process.env.VUE_APP_AUTH_AES_SALT, process.env.VUE_APP_AUTH_AES_IV);
  const encryptedPassword = cryptUtils.encryptPassword(payload.password);
  return postNoAuth('/auth/signup', {
    teamId: payload.teamId,
    name: payload.name,
    email: payload.email,
    password: encryptedPassword,
    username: payload.username,
    language: payload.language,
    firstName: payload.firstName,
    lastName: payload.lastName,
  });
};

export const signIn = (
  payload: {
    teamId: string,
    email: string,
    password: string,
  },
) => {
  const cryptUtils = new CryptoPasswordUtils(process.env.VUE_APP_AUTH_AES_KEY, process.env.VUE_APP_AUTH_AES_SALT, process.env.VUE_APP_AUTH_AES_IV);
  const encryptedPassword = cryptUtils.encryptPassword(payload.password);
  return postNoAuth('/auth/signin', {
    teamId: payload.teamId,
    email: payload.email,
    password: encryptedPassword,
  }) as Promise<SigninResponse | ErrorResponse>;
};

export const refresh = (
  payload: {
    accessToken: string,
    refreshToken: string,
  },
) => {
  return postNoAuth('/auth/refresh', {
    accessToken: payload.accessToken,
    refreshToken: payload.refreshToken,
  }) as Promise<AuthResponse | ErrorResponse>;
};

export const checkSession = () => {
  return get('/auth/check_session', null);
};
