import { post, put, get } from '../common';

export const registerPayment = (
  payload: {
    teamId: string,
    source: string,
    planId: number,
  },
) => {
  return post('/payment', {
    teamId: payload.teamId,
    source: payload.source,
    planId: payload.planId,
  });
};

export const updateCreditcard = (
  payload: {
    teamId: string,
    source: string,
  },
) => {
  return put('/payment', {
    teamId: payload.teamId,
    source: payload.source,
  });
};

export const updatePlan = (
  payload: {
    teamId: string,
    planId: number,
  },
) => {
  return put('/payment/change', {
    teamId: payload.teamId,
    planId: payload.planId,
  });
};

export const getCheckoutSession = (
  payload: {
    teamId: string,
    type: string,
    planId: number,
    currency: string,
  },
) => {
  return get('/payment/checkout', {
    teamId: payload.teamId,
    type: payload.type,
    planId: payload.planId,
    currency: payload.currency,
  });
};
