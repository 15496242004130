import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import LocalDataService from '@/service/LocalDataServices';
import { auth0Module } from '../auth/auth0Module';
import { pusherModule } from '@/store/dataModule/pusher/pusherModule';
import { checkSession, refresh } from '@/api/client';
import { userModule } from '@/store/dataModule/user/userModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { todayViewModule } from '../today/todayViewModule';
import { taskpoolViewModule } from '@/store/viewModule/taskpool/taskpoolViewModule';
import { journalViewModule } from '../journal/journalViewModule';
import { statsViewModule } from '../stats/statsViewModule';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { journalModule } from '@/store/dataModule/journal/journalModule';
import dayjs from 'dayjs';
import { calendarModule } from '@/store/dataModule/calendar/calendarModule';
import { taskDialogModule } from '../task/taskDialogModule';
import { statsModule } from '@/store/dataModule/stats/statsModule';
import LocalDataServices from '@/service/LocalDataServices';
import { snapshotModule } from '@/store/dataModule/snapshot/snapshotModule';
import { taskpoolModule } from '@/store/dataModule/report/taskpoolModule';
export interface CommonState {
  showLoader: boolean;
  lastFetch: string | null;
}

@Module({ dynamic: true, store, name: 'common', namespaced: true })
class CommonModule extends VuexModule implements CommonState {
  public showLoader: boolean = false;
  public lastFetch: string | null = null;

  public get teams() {
    return LocalDataService.getMyAccounts();
  }

  @Mutation
  public setLastFetch() {
    this.lastFetch = dayjs().toISOString();
  }

  @Mutation
  public setLoader(showLoader: boolean) {
    this.showLoader = showLoader;
  }

  @Action
  public async fetchAppData() {
    if (this.lastFetch) {
      const diff = dayjs().diff(dayjs(this.lastFetch), 'hour');
      if (diff < 1) {
        return;
      }
    }

    this.setLastFetch();

    this.setLoader(true);
    taskDialogModule.calcCurrentDate();
    await Promise.all([
      userModule.findMyUser(),
      userModule.listTeamUsers(),
      userModule.checkGoogleUser(),
      channelsModule.listChannels(),
      tagModule.listTags(),
      todayViewModule.getTodayData(),
      taskpoolViewModule.getTaskpoolData(),
      statsViewModule.getStatsData(),
      snapshotModule.listLatestSnapshots(),
    ]);
    this.setLoader(false);

    this.fetchGoogleData();
    this.fetchNippoData();

    const teamId = LocalDataServices.getTeamId();
    if (teamId) {
      statsModule.listTeamStatsForToday(teamId);
      taskpoolModule.listTaskpoolTasks(teamId);
    }

    if (!process.env.IS_ELECTRON) {
      pusherModule.connectPusher();
      pusherModule.initBeamsClient();
      pusherModule.addInterestOfBeams();
    } else {
      // EDIT THIS BLOCK ONLY IN ELECTRON BRANCH
    }
  }

  @Action
  public async fetchNippoData() {
    if (userModule.user && userModule.user.nippoUserId && userModule.user.teamId) {
      teamModule.findTeam(userModule.user.teamId);
      journalModule.setNippoUserId(userModule.user.nippoUserId);
      journalViewModule.getTeamNotes();
      const start = dayjs().startOf('month').format('YYYY-MM-DD');
      const end = dayjs().endOf('month').format('YYYY-MM-DD');
      await journalModule.listMyDailyNotes({
        teamId: userModule.user.teamId,
        from: start,
        to: end,
      });
    }
  }

  @Action
  public async fetchGoogleData() {
    if (userModule.isGoogleUser && userModule.user.isGoogleCalendarEnabled) {
      await calendarModule.getGoogleCalendars(dayjs().format('YYYY-MM-DD'));
      taskDialogModule.getConnectCalendars();
    }
  }

  @Action
  public async checkSession() {
    try {
      const response = await checkSession();
      if ('error' in response) {
        return false;
      }
      return true;
    } catch {
      const accessToken = LocalDataService.getAccessToken();
      const refreshToken = LocalDataService.getRefreshToken();
      if (accessToken && refreshToken) {
        const refreshResponse = await refresh({ accessToken, refreshToken });
        if ('error' in refreshResponse) {
          return false;
        }
        const newAccessToken = refreshResponse.accessToken;
        const newRefreshToken = refreshResponse.refreshToken;
        const newIdToken = refreshResponse.idToken;
        LocalDataService.setAccessToken(newAccessToken);
        LocalDataService.setRefreshToken(newRefreshToken);
        LocalDataService.setIdToken(newIdToken);
        return true;
      }
      return false;
    }
  }

  @Action
  public async logout() {
    // pusherModule.disconnectPusher(); // 👈 logout時にunsbscribeすると次ログイン時にsubscribeされない
    const authType = LocalDataService.getAuthType();
    if (authType === 'OPENID_CONNECT') {
      localStorage.clear();
      await auth0Module.logout();
    } else {
      localStorage.clear();
      window.location.href = '/signin';
    }
  }
}

export const commonModule = getModule(CommonModule);
