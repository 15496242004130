import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

export const MobileMenus = {
  today: 'TODAY',
  chat: 'CHAT',
  journal: 'JOURNAL',
  stats: 'STATS',
};

export interface MobileViewState {
  selectedMenu: string;
}

@Module({ dynamic: true, store, name: 'mobile-view', namespaced: true })
class MobileViewModule extends VuexModule implements MobileViewState {
  public selectedMenu: string = MobileMenus.today;

  @Mutation
  public selectMenu(menu: string) {
    this.selectedMenu = menu;
  }
}

export const mobileViewModule = getModule(MobileViewModule);
