import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { linkSlack, linkChatwork, getSlackChannelList, getChatworkChannelList, deleteSlackConnection, deleteChatworkConnection, updateTeamNotificationSetting } from '@/api/client';
import { ChatworkChannelResponse, SlackChannelResponse, TeamNotificationSettingResonse } from 'remonade-api/lib';

export interface TeamNotificationState {
  slackChannels: SlackChannelResponse[];
  chatworkChannels: ChatworkChannelResponse[];
  postAtHour: number;
  postAtMinute: number;
}

@Module({ dynamic: true, store, name: 'team-notification', namespaced: true })
class TeamNotificationModule extends VuexModule implements TeamNotificationState {
  public slackChannels: SlackChannelResponse[] = [];
  public chatworkChannels: ChatworkChannelResponse[] = [];
  public postAtHour: number = 0;
  public postAtMinute: number = 0;

  public get teamId() {
    return teamModule.teamId;
  }

  @Action
  public openSlackUrl({ host, teamId }) {
    window.location.href = `${process.env.VUE_APP_SLACK_AUTH_URL}&redirect_uri=${host}/slack-redirect/${teamId}`;
  }

  @Action
  public openChatworkUrl(host: string) {
    window.location.href = `https://www.chatwork.com/packages/oauth2/login.php?response_type=code&redirect_uri=${host}/link-chatwork&client_id=${process.env.VUE_APP_CHATWORK_CLIENT_ID}&scope=rooms.tasks:write%20offline_access%20rooms.messages:read%20rooms.members:read%20rooms.info:read%20users.tasks.me:read%20rooms.files:read%20rooms.all:read%20contacts.all:read%20users.profile.me:read%20rooms.all:read_write%20rooms.messages:write%20contacts.all:read_write%20users.all:read%20rooms:write%20rooms.info:write%20users.status.me:read%20rooms.files:write%20rooms.tasks:read%20contacts.all:write%20rooms.all:write%20rooms.members:write`;
  }

  @Action
  public async linkSlack({ code, host, teamId }) {
    const r = await linkSlack({
      teamId,
      code,
      redirectUri: `${host}/slack-redirect/${teamId}`,
    });
    if (r) {
      if ('error' in r) {
        return;
      }
    }
  }

  @Action
  public async linkChatwork({ code, host, teamId, userId }) {
    const r = await linkChatwork({
      teamId,
      userId,
      code,
      redirectUri: `${host}/link-chatwork`,
    });
    if (r) {
      if ('error' in r) {
        return;
      }
    }
  }

  @Action
  public async unlinkSlack(teamId: string) {
    const r = await deleteSlackConnection({ teamId });
    if (r) {
      if ('error' in r) {
        return;
      }
    }
    await teamModule.findTeam(teamId);
  }

  @Action
  public async unlinkChatwork(teamId: string) {
    const r = await deleteChatworkConnection({ teamId });
    if (r) {
      if ('error' in r) {
        return;
      }
    }
    await teamModule.findTeam(teamId);
  }

  @Action
  public async updateSetting(setting: TeamNotificationSettingResonse) {
    if (this.teamId) {
      const r = await updateTeamNotificationSetting({
        teamId: this.teamId,
        channelId: setting.channelId,
        slackTypeId: setting.slackTypeId,
        isEnabled: setting.isEnabled,
        message: setting.message,
        scheduledAt: setting.scheduledAt,
      });
      if (r) {
        if ('error' in r) {
          return;
        }
        await teamModule.findTeam(this.teamId);
        return true;
      }
    }
    return false;
  }

  @Action
  public async getSlackChannelList(teamId: string) {
    const channels = await getSlackChannelList({ teamId });
    if (channels) {
      if ('error' in channels) {
        return;
      }
      this.setSlackChannels(channels.channels);
    }
  }

  @Action
  public async getChatworkChannelList(teamId: string) {
    const channels = await getChatworkChannelList({ teamId });
    if (channels) {
      if ('error' in channels) {
        return;
      }
      this.setChatworkChannels(channels.channels);
    }
  }

  @Mutation
  public setSlackChannels(channels: SlackChannelResponse[]) {
    this.slackChannels = channels;
  }

  @Mutation
  public setChatworkChannels(channels: ChatworkChannelResponse[]) {
    this.chatworkChannels = channels;
  }
}

export const teamNotificationModule = getModule(TeamNotificationModule);
