import { post, get, deleteRequest, put, ErrorResponse } from '../common';

import {
  TeamStatsResponse,
  UserStatsResponse,
} from 'remonade-api/lib';

export const listTeamStats = (
  {
    teamId,
    startDt,
    endDt,
  }: {
    teamId: string,
    startDt: string,
    endDt: string,
  },
) => {
  return get(`/stats/team`, { teamId, startDt, endDt }) as Promise<TeamStatsResponse[] | ErrorResponse>;
};

export const listUserStatsWithTeamId = (
  {
    teamId,
    startDt,
    endDt,
  }: {
    teamId: string,
    startDt: string,
    endDt: string,
  },
) => {
  return get(`/stats/user`, { teamId, startDt, endDt }) as Promise<UserStatsResponse[] | ErrorResponse>;
};

export const listUserTagStatsWithTeamId = (
  {
    teamId,
    startDt,
    endDt,
  }: {
    teamId: string,
    startDt: string,
    endDt: string,
  },
) => {
  return get(`/stats/user`, { teamId, startDt, endDt, query: 'tag' }) as Promise<UserStatsResponse[] | ErrorResponse>;
};
