import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import LocalDataService from '@/service/LocalDataServices';
import {
  signinProcess,
  getAuth0RedirectUrl,
  getAuth0WebAuthRedirectUrl,
} from '@/service/AuthService';
import auth0 from 'auth0-js';

import { MyTeamResponse } from 'remonade-api/lib';
import { listTeams, signUpExternal } from '@/api/client';

import { commonModule } from '@/store/viewModule/common/commonModule';

export interface Auth0State {
  auth0Client: Auth0Client | null;
  accounts: MyTeamResponse[];
}

interface OpenIdSigninResponse {
  users: MyTeamResponse[];
  access_token: string;
  refresh_token: string;
}

@Module({ dynamic: true, store, name: 'auth0', namespaced: true })
class Auth0Module extends VuexModule implements Auth0State {
  public auth0Client: Auth0Client | null = null;
  public accounts: MyTeamResponse[] = [];
  public showSignUp: boolean = false;

  @Mutation
  public setShowSignUp(show: boolean) {
    this.showSignUp = show;
  }

  @Action
  public async initClient() {
    if (
      process.env.VUE_APP_AUTH0_DOMAIN &&
      process.env.VUE_APP_AUTH0_CLIENT_ID &&
      !process.env.IS_ELECTRON
    ) {
      try {
        const client = await createAuth0Client({
          domain: process.env.VUE_APP_AUTH0_DOMAIN,
          client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
          redirect_uri: getAuth0RedirectUrl(),
          useRefreshTokens: true,
        });
        this.setClient(client);
      } catch (e) {
        if (e && e.error && e.error === 'interaction_required') {
          location.href = 'https://auth-dev.remonade.app/v2/logout';
        }
      }
    }
  }

  @Mutation
  public setClient(client: Auth0Client) {
    this.auth0Client = client;
  }

  @Action
  public async signinProcess() {
    signinProcess(this.auth0Client);
  }

  @Action
  public async createTeam({ teamId, teamName, username, firstName, lastName, language }: {
    teamId: string,
    teamName: string,
    username: string,
    firstName: string,
    lastName: string,
    language: string,
  }) {
    if (this.auth0Client) {
      const user = await this.auth0Client.getUser();
      const email = user.email;
      if (email) {
        const userResponse = await signUpExternal({
          teamId,
          name: teamName,
          username,
          language,
          firstName,
          lastName,
        });
        if ('error' in userResponse) {
          return;
        }
        return userResponse;
      }
    }
    return null;
  }

  @Action
  public async getAuth0User() {
    if (this.auth0Client && process.env.VUE_APP_AUTH0_DOMAIN) {
      const user = await this.auth0Client.getUser();
      return user;
    }
    return null;
  }

  @Action
  public async authorizeGoogleCalendar() {
    const webAuth = new auth0.WebAuth({
      clientID: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_CLIENT_ID}`,
      domain: `${process.env.VUE_APP_AUTH0_DOMAIN}`,
      responseType: 'token id_token',
      audience: `${process.env.VUE_APP_AUTH0_ENDPOINT}/userinfo`,
      redirectUri: getAuth0WebAuthRedirectUrl(),
      scope: 'openid profile offline_access',
    });
    webAuth.authorize({
      connection: 'google-oauth2',
      connection_scope: 'https://www.googleapis.com/auth/calendar.readonly',
      scope: 'openid profile',
      accessType: 'offline',
      approvalPrompt: 'force',
    });
  }

  @Action
  public async handleAuth0() {
    if (this.auth0Client && process.env.VUE_APP_AUTH0_DOMAIN) {
      await this.auth0Client.handleRedirectCallback();
      const user = await this.auth0Client.getUser();
      const claim = await this.auth0Client.getIdTokenClaims();
      const accessToken = claim.__raw;
      LocalDataService.setAccessToken(accessToken);

      this.listTeams();
    }
    return null;
  }

  @Action
  public async listTeams() {
    const listResponse = await listTeams();

    if ('error' in listResponse) {
      this.setShowSignUp(true);
      return true;
    }

    if (listResponse.teams.length === 0) {
      this.setShowSignUp(true);
    }

    LocalDataService.setMyAccounts(listResponse.teams);
    this.setAccounts(listResponse.teams);
  }

  @Mutation
  public setAccounts(accounts: MyTeamResponse[]) {
    this.accounts = accounts;
  }

  @Action
  public async logout() {
    if (!this.auth0Client) {
      await this.initClient();
    }
    if (this.auth0Client) {
      await this.auth0Client.logout();
    }
  }

  @Action
  public async getAuth0Token() {
    if (this.auth0Client) {
      const claims = await this.auth0Client.getIdTokenClaims();
      if (claims) {
        return claims.__raw;
      }
      await this.initClient();
      const claims2 = await this.auth0Client.getIdTokenClaims();
      if (claims2) {
        return claims2.__raw;
      }
      return '';
    } else {
      try {
        await this.initClient();
        const claims = await this.auth0Client!.getIdTokenClaims();
        if (claims) {
          return claims.__raw;
        } else {
          commonModule.logout();
        }
        return '';
      } catch (err) {
        return '';
      }
    }
  }
}

export const auth0Module = getModule(Auth0Module);
