





import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
  },
})

export default class App extends Vue {
}
