import { DailyReportResponse } from 'remonade-api/lib';
import { post, get, deleteRequest, put, ErrorResponse } from '../common';

export interface ListReportsResponse {
  reports?: DailyReportResponse[];
  error?: string;
}

export const listReports = (
  payload: {
    teamId: string,
    date?: string | null,
    startDt?: string | null,
    endDt?: string | null,
    queryType?: string | null,
    type?: string | null,
    userId?: string | null,
    sortKey?: string | null,
  },
) => {
  return get('/report', {
    teamId: payload.teamId,
    date: payload.date,
    startDt: payload.startDt,
    endDt: payload.endDt,
    queryType: payload.queryType,
    type: payload.type,
    userId: payload.userId,
    sortKey: payload.sortKey,
  }) as ListReportsResponse;
};

export const createReport = (
  payload: {
    teamId: string,
    userId: string,
    date: string,
    type: string,
    task?: string | null,
    tag?: string | null,
    hour?: number | null,
    emotion?: number | null,
    displayOrder?: number | null,
    parentId?: string | null,
    isPrivate?: boolean | null,
    suggest?: string | null,
    copied?: boolean | null,
  },
) => {
  return post('/report', {
    teamId: payload.teamId,
    userId: payload.userId,
    date: payload.date,
    type: payload.type,
    task: payload.task,
    tag: payload.tag,
    hour: payload.hour,
    emotion: payload.emotion,
    displayOrder: payload.displayOrder,
    parentId: payload.parentId,
    isPrivate: payload.isPrivate,
    suggest: payload.suggest,
    copied: payload.copied,
  }) as Promise<DailyReportResponse | ErrorResponse>;
};

export const updateReport = (
  payload: {
    reportId: string,
    teamId: string,
    userId: string,
    type: string,
    date?: string | null,
    task?: string | null,
    tag?: string | null,
    hour?: number | null,
    emotion?: number | null,
    displayOrder?: number | null,
    parentId?: string | null,
    isPrivate?: boolean | null,
    suggest?: string | null,
    copied?: boolean | null,
    isDone?: boolean,
  },
) => {
  return put(`/report/${payload.reportId}`, {
    teamId: payload.teamId,
    userId: payload.userId,
    tag: payload.tag,
    type: payload.type,
    date: payload.date,
    task: payload.task,
    hour: payload.hour,
    emotion: payload.emotion,
    displayOrder: payload.displayOrder,
    parentId: payload.parentId,
    isPrivate: payload.isPrivate,
    suggest: payload.suggest,
    copied: payload.copied,
    isDone: payload.isDone,
  }) as Promise<DailyReportResponse | ErrorResponse>;
};

export const deleteReport = (
  payload: {
    reportId: string,
    teamId: string,
  },
) => {
  return deleteRequest(`/report/${payload.reportId}`, {
    teamId: payload.teamId,
  }) as Promise<DailyReportResponse | ErrorResponse>;
};
