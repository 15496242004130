import { SnapshotResponse } from 'remonade-api/lib';
import { post, get, ErrorResponse } from '../common';

export interface ListSnapshotsResponse {
  snapshots: SnapshotResponse[];
}

export const getSnapshotList = (
  payload: {
    teamId: string,
  },
) => {
  return get('/snapshot', {
    teamId: payload.teamId,
  }) as Promise<ListSnapshotsResponse | ErrorResponse>;
};

export const createSnapshot = (
  payload: {
    teamId?: string | null,
    originalUrl?: string | null,
    pixel?: number | null,
  },
) => {
  return post('/snapshot', {
    teamId: payload.teamId,
    originalUrl: payload.originalUrl,
    pixel: payload.pixel,
  }) as Promise<SnapshotResponse | ErrorResponse>;
};
