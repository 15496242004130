import { post, get, deleteRequest, put, ErrorResponse } from '../common';

import {
  ChannelResponse,
} from 'remonade-api/lib';

export interface ListChannelsResponse {
  channels: ChannelResponse[];
}

export const listChannels = (
  payload: {
    teamId: string,
  },
) => {
  return get('/channel', {
    teamId: payload.teamId,
  }) as Promise<ListChannelsResponse | ErrorResponse>;
};

export const createChannel = (
  payload: {
    teamId: string,
    channelName: string,
  },
) => {
  return post('/channel', {
    teamId: payload.teamId,
    channelName: payload.channelName,
  }) as Promise<ChannelResponse | ErrorResponse>;
};

export const addChannelUser = (
  payload: {
    channelId: string,
    userId: string,
    teamId: string,
    userType: string,
  },
) => {
  return post(`/channel/${payload.channelId}/user/${payload.userId}`, {
    teamId: payload.teamId,
    userType: payload.userType,
  });
};

export const deleteChannelUser = (
  payload: {
    channelId: string,
    userId: string,
    teamId: string,
  },
) => {
  return deleteRequest(`/channel/${payload.channelId}/user/${payload.userId}`, {
    teamId: payload.teamId,
  });
};

export const updateChannelUser = (
  payload: {
    channelId: string,
    userId: string,
    userType: string,
  },
) => {
  return put(`/channel/${payload.channelId}/user/${payload.userId}`, {
    userType: payload.userType,
  });
};

export const updateChannel = (
  payload: {
    channelId: string,
    teamId: string,
    channelName: string,
  },
) => {
  return put(`/channel/${payload.channelId}`, {
    teamId: payload.teamId,
    channelName: payload.channelName,
  });
};

export const deleteChannel = (
  payload: {
    channelId: string,
    teamId: string,
  },
) => {
  return deleteRequest(`/channel/${payload.channelId}`, {
    teamId: payload.teamId,
    channelId: payload.channelId,
  });
};
