import axios from 'axios';
import LocalDataService from '@/service/LocalDataServices';
import { auth0Module } from '@/store/viewModule/auth/auth0Module';
import { RemonadeDailyNote } from '@/model/types';

const url = process.env.VUE_APP_DAILYNOTE_API_URL;

export interface DailyNoteSearchResponse {
  total: number;
  items: RemonadeDailyNote[];
}

export default class DailyNoteApiClient {
  public static async searchNotes({
    page,
    perPage,
    teamId,
    from,
    to,
    userId,
  }: {
    page: number,
    perPage: number,
    teamId: string,
    from: string,
    to: string,
    userId: string,
  }) {
    const response = await this.get('/document', {
      page,
      per_page: perPage,
      team_id: teamId,
      epoch_from: from,
      epoch_to: to,
      user_id: userId,
    });
    return response as DailyNoteSearchResponse;
  }

  public static async searchTeamNotes({
    page,
    perPage,
    teamId,
    from,
    to,
  }: {
    page: number,
    perPage: number,
    teamId: string,
    from: string,
    to: string,
  }) {
    const response = await this.get('/document', {
      page,
      per_page: perPage,
      team_id: teamId,
      epoch_from: from,
      epoch_to: to,
    });
    return response as DailyNoteSearchResponse;
  }

  public static async createNote({
    body,
    epoch,
    teamId,
  }: {
    body: string,
    epoch: string,
    teamId: string,
  }) {
    const response = await this.post('/document', {
      title: 'TITLE',
      body,
      epoch,
      team_id: teamId,
    });
    return response as RemonadeDailyNote;
  }

  public static async updateNote({
    documentId,
    body,
    epoch,
    teamId,
  }: {
    documentId: string,
    body: string,
    epoch: string,
    teamId: string,
  }) {
    const response = await this.put(`/document/${documentId}`, {
      title: 'TITLE',
      body,
      epoch,
      team_id: teamId,
    });
    return response as RemonadeDailyNote;
  }

  // Common Functions
  private static async get(endpoint: string, params: { [key: string]: string | boolean | number; } | null) {
    const authType = LocalDataService.getAuthType();
    const projectId = process.env.VUE_APP_DAILYNOTE_PROJECT_ID || '';
    const headers = await getHeaders(projectId);
    if (authType === 'OPENID_CONNECT') {
      return axios.get(
        `${url}${endpoint}`,
        {
          params: params ? params : null,
          headers,
        },
      ).then((response) => response.data);
    } else {
      return axios.get(
        `${url}${endpoint}`,
        {
          params: params ? params : null,
          headers,
        },
      ).then((response) => response.data);
    }
  }

  private static async post(endpoint: string, params: { [key: string]: string | boolean; } | null) {
    const authType = LocalDataService.getAuthType();
    const projectId = process.env.VUE_APP_DAILYNOTE_PROJECT_ID || '';
    const headers = await getHeaders(projectId);
    if (authType === 'OPENID_CONNECT') {
      return axios.post(
        `${url}${endpoint}`,
        params ? params : null,
        {
          headers,
        },
      ).then((response) => response.data);
    } else {
      return axios.post(
        `${url}${endpoint}`,
        params ? JSON.stringify(params) : null,
        {
          headers,
        },
      ).then((response) => response.data);
    }
  }

  private static async put(endpoint: string, params: { [key: string]: string | boolean; } | null) {
    const authType = LocalDataService.getAuthType();
    const projectId = process.env.VUE_APP_DAILYNOTE_PROJECT_ID || '';
    const headers = await getHeaders(projectId);
    if (authType === 'OPENID_CONNECT') {
      return axios.put(
        `${url}${endpoint}`,
        params ? params : null,
        {
          headers,
        },
      ).then((response) => response.data);
    } else {
      return axios.put(
        `${url}${endpoint}`,
        params ? JSON.stringify(params) : null,
        {
          headers,
        },
      ).then((response) => response.data);
    }
  }

  private static async postWithoutToken(endpoint: string, params: { [key: string]: string; }) {
    return axios.post(`${url}${endpoint}`, JSON.stringify(params)).then((response) => response.data);
  }
}

const getHeaders = async (projectId: string) => {
  if (!process.env.IS_ELECTRON) {
    const authType = LocalDataService.getAuthType();
    if (authType === 'OPENID_CONNECT') {
      const token = await auth0Module.getAuth0Token();
      return {
        'Authorization': `${token}`,
        'project_id': projectId,
        'Content-Type': 'application/json; charset=utf-8',
      };
    } else {
      const idToken = LocalDataService.getIdToken();
      return {
        'Authorization': `${idToken}`,
        'project_id': projectId,
        'Content-Type': 'application/json; charset=utf-8',
      };
    }
  } else {
    // EDIT THIS BLOCK ONLY IN ELECTRON BRANCH
  }
  return {
    'project_id': projectId,
    'Content-Type': 'application/json; charset=utf-8',
  };
};
