import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { getTagOption } from '@/service/ui/ModelService';

// api
import { listTeamStats, listUserStatsWithTeamId, listUserTagStatsWithTeamId } from '@/api/client';
import { TagResponse, TeamStatsResponse, UserStatsResponse } from 'remonade-api/lib';
import { userModule } from '../user/userModule';
import dayjs from 'dayjs';
import LocalDataServices from '@/service/LocalDataServices';

export interface StatsState {
  teamStats: TeamStatsResponse[];
  teamStatsForToday: TeamStatsResponse[];
  userStats: UserStatsResponse[];
  userTagStats: UserStatsResponse[];
}

@Module({ dynamic: true, store, name: 'stats', namespaced: true })
class StatsModule extends VuexModule implements StatsState {
  public teamStats: TeamStatsResponse[] = [];
  public teamStatsForToday: TeamStatsResponse[] = [];
  public userStats: UserStatsResponse[] = [];
  public userTagStats: UserStatsResponse[] = [];

  @Action
  public async listTeamStats({ teamId, startDt, endDt }: { teamId: string; startDt: string; endDt: string; }) {
    const listResponse = await listTeamStats({ teamId, startDt, endDt });
    if ('error' in listResponse) {
      return;
    }

    this.setTeamStats(listResponse);
  }

  @Mutation
  public setTeamStats(stats: TeamStatsResponse[]) {
    this.teamStats = stats;
  }

  @Action
  public async listTeamStatsForToday(teamId: string) {
    const endDt = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    const startDt = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
    const listResponse = await listTeamStats({ teamId, startDt, endDt });
    if ('error' in listResponse) {
      return;
    }

    this.setTeamStatsForToday(listResponse);
  }

  @Mutation
  public setTeamStatsForToday(stats: TeamStatsResponse[]) {
    this.teamStatsForToday = stats;
  }

  @Action
  public async listUserStatsWithTeamId({ teamId, startDt, endDt }: { teamId: string; startDt: string; endDt: string; }) {
    this.listUserTagStatsWithTeamId({ teamId, startDt, endDt });
    const listResponse = await listUserStatsWithTeamId({ teamId, startDt, endDt });
    if ('error' in listResponse) {
      return;
    }

    this.setUserStats(listResponse);
  }

  @Mutation
  public setUserStats(stats: UserStatsResponse[]) {
    this.userStats = stats;
  }

  @Action
  public async listUserTagStatsWithTeamId({ teamId, startDt, endDt }: { teamId: string; startDt: string; endDt: string; }) {
    const listResponse = await listUserTagStatsWithTeamId({ teamId, startDt, endDt });
    if ('error' in listResponse) {
      return;
    }

    this.setUserTagStats(listResponse);
  }

  @Mutation
  public setUserTagStats(stats: UserStatsResponse[]) {
    this.userTagStats = stats;
  }
}

export const statsModule = getModule(StatsModule);
