import {
  DailyReportResponse,
} from 'remonade-api/lib';


export const sortTasks = (tasks: DailyReportResponse[]) => {
  return tasks.sort((t1, t2) => {
    return (t1.displayOrder || 0) - (t2.displayOrder || 0);
  });
};

export const calcTotalHours = (tasks: DailyReportResponse[]) => { // tasks should be filtered by user and date
  const rootTasks = tasks.filter((t) => t.parentId === null);
  return rootTasks.reduce((hours, task) => {
    const childTasks = tasks.filter((t) => t.parentId === task.reportId);
    if (childTasks.length > 0) {
      const childrenHours = childTasks.reduce((ch, ct) => {
        return ch + (ct.hour || 0);
      }, 0);
      return hours + childrenHours;
    }
    return hours + (task.hour || 0);
  }, 0);
};

export const calcDoneHours = (tasks: DailyReportResponse[]) => { // tasks should be filtered by user and date
  const rootTasks = tasks.filter((t) => t.parentId === null);
  return rootTasks.reduce((hours, task) => {
    const childTasks = tasks.filter((t) => t.parentId === task.reportId);
    if (childTasks.length > 0) {
      const childrenHours = childTasks.reduce((ch, ct) => {
        return ch + (ct.hour || 0);
      }, 0);
      const childrenDones = childTasks.filter((c) => c.isDone);
      if (childTasks.length === childrenDones.length || task.isDone) {
        return hours + childrenHours;
      }
      return hours;
    }

    if (task.isDone) {
      return hours + (task.hour || 0);
    }
    return hours;
  }, 0);
};

export const getIsDone = (task: DailyReportResponse, tasks: DailyReportResponse[]) => { // true when parent task is done or all children is done.
  if (task.parentId !== null) {
    const parent = tasks.find((t) => t.reportId === task.parentId);
    if (parent && parent.isDone) {
      return true;
    }
  }
  if (task.isDone) {
    return true;
  }
  const childTasks = tasks.filter((t) => t.parentId === task.reportId);
  if (childTasks.length > 0) {
    const childrenDones = childTasks.filter((c) => c.isDone);
    return (childrenDones.length === childTasks.length);
  }
  return false;
};
