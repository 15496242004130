import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';

import store from '@/store/index';
export interface ElectronNotificationState {
}

@Module({ dynamic: true, store, name: 'electron-notification', namespaced: true})
class ElectronNotificationModule extends VuexModule implements ElectronNotificationState {
  @Action
  public async processNotification({ key, data }: { key: string, data: any }) {
    // EDIT THIS BLOCK ONLY IN ELECTRON BRANCH
    // console.log('processNotification');
  }
}

export const electronNotificationModule = getModule(ElectronNotificationModule);
