import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

// models
import {
  TodayUser, TaskItem, ChatReaction,
} from '@/model/types';
import {
  getTodayUser, getTaskItem, getChatReactionsWithTodayUser,
} from '@/service/ui/ModelService';
import {
  getDoneRateChartData, calcDoneRate, getTodayStatsSummary,
} from '@/service/ui/StatsService';

import {
  DailyReportResponse,
  ChannelResponse,
  ScheduledReportResponse,
} from 'remonade-api/lib';

// store
import { snapshotModule } from '@/store/dataModule/snapshot/snapshotModule';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { reportsModule } from '@/store/dataModule/report/reportsModule';
import { scheduledReportModule } from '@/store/dataModule/report/scheduledReportModule';
import { chatModule } from '@/store/dataModule/chat/chatModule';
import { bookmarkModule } from '@/store/dataModule/bookmark/bookmarkModule';

// services
import LocalDataService from '@/service/LocalDataServices';
import dayjs from 'dayjs';
import { pusherModule } from '@/store/dataModule/pusher/pusherModule';
import { statsModule } from '@/store/dataModule/stats/statsModule';
import { calcDoneHours, calcTotalHours } from '@/service/ui/TaskService';
import LocalDataServices from '@/service/LocalDataServices';

export interface ChannelWithProgress {
  channel: ChannelResponse;
  progress: number;
  presence: number;
}

export interface TodayViewState {
  selectedChannelId: string;
  isChatMinified: boolean;
}

@Module({ dynamic: true, store, name: 'today-view', namespaced: true })
class TodayViewModule extends VuexModule implements TodayViewState {

  public selectedChannelId: string = 'ALL';
  public isChatMinified: boolean = true; // true = close, false = open.

  // for stats summary in user card
  public endDate = dayjs().format('YYYY-MM-DD');
  public startDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');

  /**
   * Getters: User / Team user
   */
  public get me() {
    if (userModule.user) {
      const id = userModule.user.userId;
      const myStats = statsModule.teamStatsForToday.find((s) => s.userId === id);

      const myTodayReports = reportsModule.todayReports.filter((r) => r.userId.endsWith(id));
      const data = getTodayStatsSummary(myStats ? myStats.completions : [], myTodayReports, this.startDate, this.endDate);

      const snap = snapshotModule.snapshots.find((s) => s.userId.endsWith(userModule.user!.userId)) || null;
      const presence = pusherModule.presenceUsers.includes(userModule.user.userId);
      return getTodayUser(userModule.user, presence, reportsModule.todayReports, snap ? snap.originalUrl : null, data);
    }
    return null;
  }

  public get activeUsers() {
    return this.visibleUsers.filter((tu) => {
      if (tu !== undefined) {
        if (tu.totalHours > 0) {
          return true;
        }

        if (tu.lastActiveAt) {
          const lastActive = dayjs(tu.lastActiveAt);
          const now = dayjs();
          const diff = now.diff(lastActive, 'hour');
          if (diff <= 2) {
            return true;
          }
        }

        return false;
      }
      return [];
    });
  }

  public get sortedActiveUsers() {
    return [...this.activeUsers].sort((u1, u2) => {
      if (u1.totalHours > 0 && u2.totalHours > 0) {
        if ((u1.completedHours / u1.totalHours) < (u2.completedHours / u2.totalHours)) {
          return 1;
        } else {
          return -1;
        }
      } else if (u1.totalHours === 0) {
        return 1;
      } else if (u2.totalHours === 0) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  public get inactiveUsers() {
    return this.visibleUsers.filter((tu) => {
      const find = this.activeUsers.find((au) => au.userId === tu.userId);
      if (find) {
        return false;
      }
      return true;
    });
  }

  public get visibleUsers() {
    return this.selectedChannelUsers.filter((tu) => {
      if (tu !== undefined) {
        if (tu.lastActiveAt) {
          const lastActive = dayjs(tu.lastActiveAt);
          const now = dayjs();
          const diff = now.diff(lastActive, 'day');
          if (diff < 8) {
            return true;
          }
        }

        return false;
      }
      return false;
    });
  }

  public get selectedChannel() {
    if (this.selectedChannelId !== 'ALL') {
      const selectedChannel = channelsModule.channels.find((channel) => channel.channelId === this.selectedChannelId);
      return selectedChannel || null;
    }
    return null;
  }

  public get selectedChannelUsers() {
    if (this.selectedChannel) {
      return this.teamUsers.filter((u) => this.selectedChannel!.users.find((channelUser) => channelUser.userId === u.userId) ? true : false);
    }
    return this.teamUsers;
  }

  public get teamUsers(): TodayUser[] {
    if (userModule.user) {
      return userModule.teamUsers
        .filter((tu) => tu.userId !== userModule.user!.userId)
        .map((u) => {
          const userStats = statsModule.teamStatsForToday.find((s) => s.userId === u.userId);
          const userTodayReports = reportsModule.todayReports.filter((r) => r.userId === u.userId);
          const data = getTodayStatsSummary(userStats ? userStats.completions : [], userTodayReports, this.startDate, this.endDate);
          const snap = snapshotModule.snapshots.find((s) => s.userId === `${u.teamId}#${u.userId}`) || null;
          const presence = pusherModule.presenceUsers.includes(u.userId);
          return getTodayUser(u, presence, reportsModule.todayReports.filter((report) => report.type === 'task'), snap ? snap.originalUrl : null, data);
        });
    }
    return [];
  }

  public get allPresenceUserLength(): number {
    let length = 1; // 自分を含める
    for (const user of this.teamUsers) {
      if (pusherModule.presenceUsers.includes(user.userId)) {
        length += 1;
      }
    }
    return length;
  }

  public get selectedChannelPresenceUserLength(): number {
    let length = 1; // 自分を含める
    for (const user of this.selectedChannelUsers) {
      if (pusherModule.presenceUsers.includes(user.userId)) {
        length += 1;
      }
    }
    return length;
  }

  /**
   * Getters: Reports / stats
   */
  public get selectedUserReports(): DailyReportResponse[] {
    const todayReports = reportsModule.todayReports;
    let result: DailyReportResponse[] = [];
    this.selectedChannelUsers.forEach((user) => {
      const userReports = todayReports.filter((report) => report.userId.endsWith(user.userId));
      result = result.concat(userReports);
    });
    if (this.me) {
      const myReports = todayReports.filter((report) => report.userId.endsWith(this.me!.userId));
      result = result.concat(myReports);
    }
    return result;
  }

  public get todayTasks(): TaskItem[] {
    return reportsModule.sortedTodayTasks.filter((r) => r.type === 'task').map((r) => getTaskItem(r));
  }

  /**
   * Getters: Channels & progress
   */
  public get myChannelsWithProgress(): ChannelWithProgress[] {
    const channels = channelsModule.myChannels;
    return channels.map((channel) => {
      const users = this.teamUsers.filter((u) => channel.users.find((channelUser) => channelUser.userId === u.userId) ? true : false);

      let presence = 1; // 自分を含める
      for (const user of users) {
        if (pusherModule.presenceUsers.includes(user.userId)) {
          presence += 1;
        }
      }

      const todayReports = reportsModule.todayReports;
      let result: DailyReportResponse[] = [];
      users.forEach((user) => {
        const userReports = todayReports.filter((report) => report.userId.endsWith(user.userId));
        result = result.concat(userReports);
      });
      if (this.me) {
        const myReports = todayReports.filter((report) => report.userId.endsWith(this.me!.userId));
        result = result.concat(myReports);
      }

      const progress = calcDoneRate(result);

      return {
        channel,
        progress,
        presence,
      };
    });
  }

  public get allTodayProgress() {
    return calcDoneRate(reportsModule.todayReports);
  }

  public get myTodayProgress() {
    if (userModule.user) {
      const reports = reportsModule.todayReports.filter((report) => report.userId === userModule.user.userId && !report.deletedAt);
      return calcDoneRate(reports);
    }
    return 0;
  }

  /**
   * Getters: Chat
   */
  public get chats() {
    const chats = chatModule.chats;
    if (this.selectedChannelId !== 'ALL') {
      return chats
              .filter((c) => c.channelId === this.selectedChannelId)
              .sort((c1, c2) => {
                const createdAt1 = dayjs(c1.createdAt);
                const createdAt2 = dayjs(c2.createdAt);
                if (createdAt1 > createdAt2) {
                  return -1;
                }
                if (createdAt1 < createdAt2) {
                  return 1;
                }
                return 0;
              });
    }
    return chats
            .filter((c) => !c.channelId)
            .sort((c1, c2) => {
              const createdAt1 = dayjs(c1.createdAt);
              const createdAt2 = dayjs(c2.createdAt);
              if (createdAt1 > createdAt2) {
                return -1;
              }
              if (createdAt1 < createdAt2) {
                return 1;
              }
              return 0;
            });
  }

  public get chatsWithReactions() {
    return this.chats.map((chat) => {
      let sender = this.teamUsers.find((user) => user.userId === chat.userId) || null;

      if (this.me && !sender && chat.userId === this.me.userId) {
        sender = this.me;
      }

      if (chat.reactions) {
        const reactionForView = getChatReactionsWithTodayUser(chat.reactions, [...this.teamUsers, this.me!], this.me!).sort((reaction1, reaction2) => {
          if (reaction1.reaction > reaction2.reaction) { return 1; }
          if (reaction1.reaction < reaction2.reaction) { return -1; }
          return 0;
        });

        return {
          chat,
          user: sender,
          reactions: reactionForView,
        };
      }

      return {
        chat,
        user: sender,
        reactions: [],
      };
    });
  }

  /**
   * Getters: Bookmarks
   */
  public get groupBookmarks() {
    if (this.selectedChannelId !== 'ALL') {
      return bookmarkModule.bookmarks.filter((bookmark) => bookmark.channelId === this.selectedChannelId);
    }
    return bookmarkModule.bookmarks.filter((bookmark) => bookmark.channelId === null);
  }

  /**
   * Init
   */
  @Action
  public async getTodayData() { // fetch all data that today grid need
    const teamId = LocalDataServices.getTeamId();
    if (teamId) {
      await tagModule.listTags();
      const today = dayjs().format('YYYY-MM-DD');
      await reportsModule.listTeamReportsWithRange({
        teamId,
        startDt: today,
        endDt: today,
      });

      scheduledReportModule.listScheduledReport();
      chatModule.listChat();
      channelsModule.listChannels();
      bookmarkModule.listBookmarks();
    }
  }


  /**
   * Channels
   */
  @Action
  public async listChannels() {
    const user = LocalDataService.getUser();
    if (user) {
      await channelsModule.listChannels();
    }
  }

  @Mutation
  public selectChannel(channelId: string) {
    this.selectedChannelId = channelId;
  }

  /**
   * Scheduled reports
   */
  @Action
  public async createScheduledReport({
    task,
    hour,
    tag,
    weekday,
  }: {
    task: string;
    hour?: number | null | undefined;
    tag?: string | null | undefined;
    weekday: number;
  }) {
    if (userModule.user) {
      scheduledReportModule.createScheduledReport({
        teamId: userModule.user.teamId,
        userId: userModule.user.userId,
        task,
        hour,
        tag,
        weekday,
      });
    }
  }

  @Action
  public async deleteScheduledReport(task: ScheduledReportResponse) {
    await scheduledReportModule.deleteScheduledReport({
      sReportId: task.sReportId,
      teamId: task.teamId,
      userId: task.userId,
    });
  }

  /**
   * Chat
   */
  @Action
  public async sendChat(message: string) {
    if (userModule.user) {
      await chatModule.createChat({
        teamId: userModule.user.teamId,
        comment: message,
        channelId: this.selectedChannelId === 'ALL' ? null : this.selectedChannelId,
      });
    }
  }

  @Action
  public async clickReaction({ reaction, chatId }: { reaction: ChatReaction, chatId: string }) {
    if (userModule.user) {
      if (reaction.class && reaction.class === 'mine') {
        // delete
        await chatModule.deleteChatReaction({
          teamId: userModule.user.teamId,
          reaction: reaction.reaction,
          chatId,
        });
      } else {
        // add
        await chatModule.createChatReaction({
          teamId: userModule.user.teamId,
          reaction: reaction.reaction,
          chatId,
        });
      }
    }
  }

  @Action
  public async addReaction({ emoji, chatId }: { emoji: string, chatId: string }) {
    if (userModule.user) {
      await chatModule.createChatReaction({
        teamId: userModule.user.teamId,
        reaction: emoji,
        chatId,
      });
    }
  }

  /**
   * Bookmark
   */
  @Action
  public async createBookmark({
    title,
    url,
    icon,
    description,
  }: {
    title: string,
    url: string,
    icon?: string,
    description?: string,
  }) {
    const channelId = this.selectedChannelId === 'ALL' ? null : this.selectedChannelId;
    bookmarkModule.createBookmark({
      channelId,
      title,
      url,
      icon,
      description,
    });
  }

  /**
   * Chat minified flag
   */
  @Mutation
  public setIsChatMinified(isMinified: boolean) {
    this.isChatMinified = isMinified;
  }

  @Mutation
  public toggleChat() {
    this.isChatMinified = !this.isChatMinified;
  }
}

export const todayViewModule = getModule(TodayViewModule);
