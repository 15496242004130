import { post, get, deleteRequest, ErrorResponse } from '../common';

import {
  ChatResponse,
  ChatReactionResponse,
} from 'remonade-api/lib';

export interface ListChatsResponse {
  chats: ChatResponse[];
}

export const listChats = (
  payload: {
    teamId: string,
    startDt: string,
    endDt: string,
  },
) => {
  return get('/chat', {
    teamId: payload.teamId,
    startDt: payload.startDt,
    endDt: payload.endDt,
  }) as Promise<ListChatsResponse | ErrorResponse>;
};

export const createChatReaction = (
  payload: {
    chatId: string,
    reaction: string,
    teamId: string,
  },
) => {
  return post('/chat/reaction', {
    chatId: payload.chatId,
    reaction: payload.reaction,
    teamId: payload.teamId,
  }) as Promise<ChatReactionResponse | ErrorResponse>;
};

export const createChat = (
  payload: {
    teamId: string,
    comment: string,
    channelId: string | null,
  },
) => {
  return post('/chat', {
    teamId: payload.teamId,
    comment: payload.comment,
    channelId: payload.channelId,
  }) as Promise<ChatResponse | ErrorResponse>;
};

export const deleteChatReaction = (
  payload: {
    teamId: string,
    chatId: string,
    reaction: string,
  },
) => {
  return deleteRequest('/chat/reaction', {
    teamId: payload.teamId,
    chatId: payload.chatId,
    reaction: payload.reaction,
  });
};
