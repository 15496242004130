import { post, get } from '../common';

export const getActivityList = (
  payload: {
    teamId: string,
    startDt: string,
    endDt: string,
  },
) => {
  return get('/activity', {
    teamId: payload.teamId,
    startDt: payload.startDt,
    endDt: payload.endDt,
  });
};

export const createActivity = (
  payload: {
    teamId: string,
    date: string,
    taskName?: string | null,
    actionType: string,
  },
) => {
  return post('/activity', {
    teamId: payload.teamId,
    date: payload.date,
    taskName: payload.taskName,
    actionType: payload.actionType,
  });
};
