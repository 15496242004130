export default {
  // common
  error: 'エラーが発生しました。',
  team: 'チーム',
  save: '保存',
  plan: 'プラン',
  title: 'タイトル',
  email: 'メールアドレス',
  password: 'パスワード',
  passwordConfirm: 'パスワード (確認)',
  cancel: 'キャンセル',
  invite: '招待',
  tags: 'タグ',
  users: 'ユーザー',
  user: 'ユーザー',
  edit: '編集',
  delete: '削除',
  add: '追加',
  today: 'Today',
  todaysTask: '今日のタスク',
  journal: '日報',
  allUsers: '全メンバー',
  groups: 'グループ',
  talkroom: 'トークルーム',
  stats: 'スタッツ',
  myStats: '自分のスタッツ',
  teamStats: 'チームのスタッツ',
  tagAnalysis: 'タグ分析',
  weeklyReports: '週間レポート',
  roadmaps: 'ロードマップ',
  taskpool: 'タスクプール',
  settings: '設定',
  userSettings: 'ユーザー設定',
  teamSettings: 'チーム設定',
  inviteUser: 'メンバー招待',
  logout: 'ログアウト',
  calendar: 'カレンダー',
  subtask: 'サブタスク',
  overUsersLimit: 'ユーザー数がご利用のプランの上限を超過しています。プラン内容を変更いただくか、ユーザー数を減らして下さい。',
  goToTeamSetting: 'チーム設定へ',
  contactAdmin: 'チームの管理者に連絡してプランをアップグレードしてください。',
  or: 'または',
  menu: {
    changeTeam: 'チームを変更',
  },
  // auth
  signin: 'サインイン',
  signup: 'サインアップ',
  loginWithSocialAccount: 'ソーシャルアカウントでログイン',
  forgetPassword: 'パスワードを忘れた場合',
  currentPassword: '初回パスワード',
  newPassword: '新しいパスワード',
  newPasswordConfirm: '新しいパスワード (確認)',
  // task
  tasks: 'タスク',
  taskAndEmotion: 'タスクと気分を登録してください！',
  todaysEmotion: '今日の気分は',
  taskName: 'タスク内容',
  tag: 'タグ',
  requiredTime: '所要時間',
  estimate: '所要時間',
  dragAndDrop: 'ドラッグ&ドロップで、表示順を変更できます',
  selectTag: 'タグを選択',
  select: '選択',
  selected: '選択中',
  deselect: '解除',
  moveToAnother: '別日に移動',
  copied: '既にコピー済みです',
  copyToAnother: '別日にコピー',
  total: '合計',
  newTask: '新規タスク',
  cantEdit: '前日以前のタスクは編集できません',
  todaysTaskAndEmotion: '今日のタスクと気分を登録してください！',
  moveTask: 'タスクを移動',
  copyTask: 'タスクをコピー',
  takeSnapshot: 'スナップショットを撮影します',
  shareSetting: 'スナップショットの共有設定を変更します',
  interval: '撮影間隔を変更します',
  filter: 'スナップショットの加工を変更します',
  task: '本日のタスク管理',
  suggestedTask: 'サジェストされているタスク',
  getEventsFromCalendar: 'Googleカレンダーから予定を取得',
  eventsRegisteredAtCalendar: 'Googleカレンダーに登録されている予定',
  noCalendar: '登録されているカレンダーがありません',
  noEventToday: '登録されている予定はありません',
  addToTask: '今日のタスクに追加',
  // task
  chatPlaceholder: 'つぶやく',
  chatTip: 'Press ⌘ / ctrl + return で送信',
  chatExplain: 'チャットは投稿から24時間だけ表示されます',
  // daily note
  dailyReport: '日報',
  dailyReports: '日報',
  dailyReportsSaved: '日報が保存されました。',
  // team setting
  teamSetting: 'チーム設定',
  setTimezone: 'タイムゾーンを設定してください。',
  teamId: 'チームID',
  timezone: 'タイムゾーン',
  notifications: '通知',
  notificationSettings: '通知設定',
  maxUsers: '最大ユーザー数',
  pricePerUser: '1ユーザーあたり料金',
  // user invite
  notAdmin: '権限がありません。',
  invited: 'ユーザーを招待しました。',
  inviteFailed: 'ユーザーの招待に失敗しました。',
  inviteMessage: '招待したいユーザーのメールアドレスを入力してください',
  resentInvitation: '招待メールを再送しました。',
  // user management
  userManagement: 'ユーザー管理',
  resend: '再送',
  suspend: '停止',
  suspendAccount: 'アカウント停止',
  suspendMessageEn: '',
  suspendMessageJa: 'のアカウントを削除してよろしいですか？',
  whitelistSetting: '招待リンクのメールアドレスのドメイン許可設定',
  addWhitelist: 'ドメインを追加',
  whitelistSettingExplain: '招待リンクからサインアップできるユーザーを、メールアドレスのドメインによって制限することができます',
  teamMember: 'チームメンバー',
  memberSetting: 'メンバー管理',
  // user setting
  userUpdated: 'ユーザー情報を更新しました',
  userSettingUpdated: 'ユーザー設定を更新しました。',
  newScheduledTask: '新規繰り返しタスク',
  scheduledTask: '繰り返しタスク',
  emailNotification: 'メールによる通知',
  userSetting: 'ユーザー情報設定',
  changeImage: 'プロフィール画像を設定',
  userName: 'ユーザー名',
  firstName: '名',
  lastName: '姓',
  language: '言語',
  teamPage: 'チーム画面へ',
  pushNotificationSetting: 'プッシュ通知設定',
  chatNotification: '新着チャット',
  taskIncompleteNotification: 'タスク未達成 (通知時刻は管理者が設定できます)',
  taskNoRegistNotification: 'タスク未登録 (通知時刻は管理者が設定できます)',
  teamMemberActive: 'チームメンバーのログイン',
  teamMemberTaskDone: 'チームメンバーのタスク達成',
  teamMemberJournalCreated: 'チームメンバーの日報作成',
  isAddedToChannel: '自分がグループへ追加されたとき',
  suggestedToPoolTask: 'プールタスクをサジェストされたとき',
  takenPoolTask: '作ったプールタスクが引き受けられたとき',
  googleCalendarSetting: 'Googleカレンダー連携設定',
  linkGoogleCalendar: 'Googleカレンダーを連携',
  pleaseSigninWithGoogle: 'Googleアカウントでログインしてください。',
  profileSetting: 'プロフィール画像の設定',
  ok: '決定',
  // plan settings
  planSetting: 'プラン設定',
  proPlanDescription: '✅ 無制限のチームメンバー / ✅ 無制限のデータ閲覧',
  planSet: 'プランの設定が完了しました。',
  cardRegistered: 'クレジットカードの登録が完了しました。',
  bankRegistered: '銀行振込の設定が完了しました。',
  paymentStopped: '支払いの停止が完了しました。',
  currentPlan: '現在のプラン情報',
  monthly: '月ごと契約',
  month: '月',
  sixMonthes: '半年契約',
  changePlan: 'プランを変更する',
  paymentSetting: '支払い情報の登録・変更',
  creditcard: 'クレジットカード',
  currentCreditcard: 'ご利用中のクレジットカード',
  billMessage: '請求書でのお支払いをご希望の場合は、画面右下のチャットウィンドウからお問い合わせください。',
  changeCard: 'カードを変更する',
  registerCard: 'カードを登録してプランを適用する。',
  isTrial: '現在は無料期間中です。',
  freePlan: 'フリープラン',
  downgrade: 'ダウングレード',
  donwgradeMessage: 'ダウングレードするには、チームメンバーを5人以下まで減らしてください。',
  limitedUsers: '最大5名のメンバー',
  limitedData: '2週間のデータ保存期間',
  currentPlanPrefix: '現在のプランは',
  howToUpgradeToV2Plan: '新プランへの切り替えは、画面右下のチャットからお問い合わせください。',
  // notify setting
  channel: 'チャンネル',
  message: 'メッセージ',
  notificationTime: '通知時間',
  loginReminder: 'ログインのリマインダー',
  loginNotification: 'ログイン時通知',
  weeklyReport: '週間レポート',
  taskRegistrationReminder: 'タスク登録リマインダー',
  taskCompletionReminder: 'タスク完了リマインダー',
  loginReminderMessage: '毎日定刻にログインのリマインダーを送ります。チーム全員に通知されます。',
  loginNotificationMessage: 'ユーザーがログインをした際に通知をします。特定のチャンネルに通知されます。',
  weeklyReportMessage: '毎週月曜日に、前週のスタッツをレポートとして送ります。管理者ユーザーに通知されます。',
  taskRegistrationReminderMessage: 'タスク登録がされていないユーザーに対してリマインダーを送信します',
  taskCompletionReminderMessage: '未完了のタスクがあるユーザーに対してリマインダーを送信します',
  unlinkSlack: 'Slackとの連携を解除する',
  linkSlack: 'Slackと連携する',
  unlinkChatwork: 'Chatworkとの連携を解除する',
  linkChatwork: 'Chatworkと連携する',
  // tag setting
  tagSetting: 'タグ管理',
  archivedTags: 'アーカイブされたタグ',
  archive: 'アーカイブ',
  activate: 'アクティベート',
  addTag: 'タグ追加',
  deleteTag: 'タグ削除',
  deleteMessageEn: '',
  deleteMessageJa: 'を削除してよろしいですか？',
  // stats
  statsItems: {
    completionRate: 'タスク達成率',
    numberOfTasks: '総タスク数',
    completedTasks: '達成タスク数',
    taskAnalysisByTag: 'タグごとのタスク分析',
    taskAnalysisByDay: '曜日ごとのタスク分析',
  },
  period: '期間指定',
  has: 'のデータ保存期間は',
  days: '日間',
  unlimited: '無制限',
  storagePeriod: 'です。',
  yourStats: '個人スタッツ',
  weeklyReportsTitle: '週間レポート',
  weeklyReportsNotFound: '週間レポートはまだありません',
  emailSentCSV: 'ご登録のメールアドレスにCSVファイルを送信しました。しばらくしてもメールが届かない場合は、サポートにお問い合わせください。',
  sStats: 'のスタッツ',
  totalTasks: '総タスク数',
  totalHours: '総所要時間',
  combinationChart: '複合グラフ',
  onlyCompletionRate: '達成率のみ',
  noTagInStats: 'タスクにタグが割り当てられていないようです。管理画面からタグを追加して、タスク分析を活用しましょう！',
  averageHour: '平均所要時間',
  requiredTimeRate: '所要時間割合',
  showWeekends: '土日を表示',
  searchByUserName: 'ユーザー名で検索',
  seeMore: 'もっと見る',
  close: '閉じる',
  highCompletionRate: 'タスク達成率の高いメンバー',
  longRequiredTime: 'タスク時間の多いメンバー',
  longDoneTime: '完了時間の多いメンバー',
  doneTime: '完了時間',
  taskDistribution: 'ユーザーごとタスク分配',
  downloadCSV: 'データをCSVファイルでダウンロード',
  legend: '凡例',
  userAnalysis: 'ユーザー分析',
  sort: 'ソート',
  displayTags: '表示するタグ',
  limitation: 'の制限は',
  // auth
  passwordChanged: 'パスワードの変更に成功しました。',
  passwordChangeFailed: 'パスワードの変更に失敗しました。',
  joinTeam: 'チームに参加する',
  sentInvitation: '招待メールをお送りしました。メールボックスをご確認ください。',
  resetPassword: 'パスワード再設定',
  passwordResetCompleted: 'パスワードを変更しました。',
  sendPasswordResetEmail: '再設定リンクを送信',
  resetEmailSent: '再設定メールを送信しました。受信ボックスを確認してください。',
  selectTeam: 'ログインするチームを選択',
  signupTitle: 'チームを作成',
  createTeam: 'チームを作成',
  teamIdRule: '半角英数字またはハイフンのみ可',
  teamName: 'チーム名',
  agreement: '本サービスの',
  tos: '利用規約',
  and: 'と',
  privacyPolicy: 'プライバシーポリシー',
  agreementEnd: 'に同意します',
  confirmationEmailSent: '入力されたメールアドレス宛に確認メールを送信しました。',
  validator: {
    required: 'この項目は必須です。',
    teamId: '半角英数字またはハイフンのみを使用できます。',
    email: 'このメールアドレスは不正です。',
    passwordLength: '8文字以上で入力してください・',
    passwordChars: '大文字、小文字、数字を全て含めてください。',
    passwordConfirm: 'パスワードが異なっています',
  },
  // onboarding
  welcome: 'remonadeへようこそ',
  letsInvite: 'まずは、チームのユーザーを招待しましょう。',
  registerTag: '次に、タスクに紐付けるタグを登録しましょう。',
  registerTagExplain: 'タグを設定することで、タグごとの達成率や所要時間、メンバーごとのタスクの配分などの分析を見ることができます',
  tagMessage: '現在チームで進行しているプロジェクトやミッションごとにタグを作成しておくと便利です。',
  analysisMessage: 'タスクの分析は紐付いているタグ情報を用いて行われます。',
  tagName: 'タグ名',
  register: '登録',
  copyURL: '招待リンクをコピー',
  invitedTeamMembers: 'チームメンバーを招待しました。',
  registeredTask: 'タスクを登録しました。',
  copiedInvitationLink: '招待リンクをクリップボードにコピーしました。リンクを受け取ったユーザーは、このチームに自分でサインアップできます。',
  copyFailed: '招待リンクのコピーに失敗しました。',
  dailyTask: 'デイリータスク',
  // talkroom
  talkroomTitle: 'トークルームを編集',
  createTalkroom: 'トークルームを作成',
  addTalkroom: 'トークルームを追加',
  privateMode: 'プライベートモード',
  roomName: 'ルーム名',
  create: '作成',
  current: '現在の',
  talkroomLimitation: 'のトークルーム数上限は',
  talkroomDesu: 'です。',
  member: 'メンバー',
  remove: '削除',
  addUser: 'ユーザーを追加',
  deleteTalkroom: 'トークルームを削除',
  deleteTalkroomMemberMessage: 'メンバーをトークルームから削除しますか？この操作は取り消せません。',
  deleteTalkroomMessage: 'トークルームを削除しますか？この操作は取り消せません。',
  thisIsPublic: 'このトークルームは公開トークルームです。',
  // taskpool
  taskpoolTitle: 'タスクプール',
  newTaskPool: '新規タスク',
  hour: '時間',
  taskname: 'タスク',
  addSuggest: 'サジェストを追加',
  pool: 'プール',
  takeit: '今日のタスクへ',
  take: '着手',
  noTag: 'タグなし',
  // team
  editGroup: 'グループ編集',
  group: {
    addUserToGroup: 'グループにメンバーを追加',
    groupSetting: 'グループ設定',
    groupName: 'グループ名',
  },
  createGroup: '新規グループを作成',
  addGroup: 'グループを追加',
  groupName: '新規グループ名',
  groupLimitation: 'のグループ数上限は',
  groupsDesu: 'グループです。',
  deleteGroup: 'グループ削除',
  deleteGroupMemberMessage: 'メンバーをグループから削除しますか？この操作は取り消せません。',
  deleteGroupMessage: 'グループを削除しますか？この操作は取り消せません。',
  inviteByEmail: 'メールアドレスで招待',
  inviteByURL: 'リンクで招待',
  memberLimitation: 'のユーザー数上限は',
  membersDesu: '人です。',
  more: 'さらにユーザーを招待するためには、',
  changePlanMessage: 'プランを変更',
  please: 'してください。',
  userMaxMessage: 'さらにユーザーを招待するためには、プランをアップグレードしてください。',
  statsPeriodMessage: '14日以上前のスタッツを閲覧するためには、プランをアップグレードしてください。',
  upgrade: 'プラン変更',
  upgradeMessage: 'さらにユーザーを招待するためには、管理者に連絡してプランを変更してください。',
  emailInvalidAlertPre: 'メールアドレス',
  emailInvalidAlertSuf: 'は形式が不正であるため招待できません。',
  emailMineAlertPre: 'メールアドレス',
  emailMineAlertSuf: 'は現在ログインしているアカウントのものであるため招待できません。',
  // bookmark
  bookmark: {
    title: 'ブックマーク',
    createTitle: '新規ブックマークを作成',
    editTitle: 'ブックマークを編集',
  },
  // error messages
  errorUnexpected: '予期せぬエラーが発生しました。サポートまでお問い合わせください。',
  errorAuthAlert: '認証のエラーが発生しました。再度お試しいただくか、サポートまでご連絡ください。',
  errorInviteAlert041: 'ご利用中のプランで参加できるユーザー数を超過しています。管理者にご連絡いただき、プランのアップグレードをお願いいたします。',
  errorSignin: 'ユーザー情報が一致しません。',
  errorSignup: '登録のエラーが発生しました。再度お試しいただくか、サポートまでご連絡ください。',
  errorE003: 'チームが存在しません。',
  errorE005: '入力項目に不足があります。',
  errorE007: 'ユーザーが存在しません。',
  errorE020: 'メールアドレスが不正です。',
  errorE032: 'パスワードのフォーマットが要件を満たしていません。',
  errorE038: 'ユーザー名がすでに使われています。別のユーザー名を入力してください。',
  errorE039: '認証リンクが不正です。',
  errorE040: '認証リンクの有効期限が切れています。新たに登録を行うか、管理者に連絡して招待メールを再送してください。',
  errorE041: 'パスワードが不正です。',
  errorE042: 'チームIDが不正です。半角英数字とハイフンのみ使用できます。',
  errorE043: 'チームIDがすでに使われています。',
  errorE051: '支払い方法が登録されていません',
  errorE075: 'メールアドレスが認証されていません。チームの管理者に連絡して、再度招待を行ってください。',
  errorE076: 'プランが変更されていません',
  errorE077: '年額から月額へのプラン変更はできません',
  errorE082: 'チームの人数がプランの上限を上回っているため、プランを変更できません',
  errorE083: 'ユーザーグループの数がプランの上限を上回っているため、プランを変更できません',
  errorE088: 'トークルームの数がプランの上限を上回っているため、プランを変更できません',
  errorE089: 'お支払い通貨は変更できません',
};
