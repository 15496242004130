import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import LocalDataService from '@/service/LocalDataServices';
import dayjs from 'dayjs';

// api
import { getUserList, updateUser, deleteUser, createActivity, updateLastActive } from '@/api/client';
import { UserResponse } from 'remonade-api/lib';
import { auth0Module } from '@/store/viewModule/auth/auth0Module';

export interface UserState {
  user: UserResponse | null;
  teamUsers: UserResponse[];
  isGoogleUser: boolean;
}

@Module({ dynamic: true, store, name: 'user', namespaced: true })
class UserModule extends VuexModule implements UserState {
  public user: UserResponse | null = null;
  public teamUsers: UserResponse[] = [];
  public isGoogleUser: boolean = false;

  @Action
  public async findMyUser() {
    const userId = LocalDataService.getUserId();
    const teamId = LocalDataService.getTeamId();
    if (userId && teamId) {
      // 👇 もとのfindUserみたいなリクエストはない？
      const listResponse = await getUserList({ teamId });
      if ('error' in listResponse) {
        return;
      }
      const users = listResponse.users;
      const user = users.find((u) => u.userId === userId);
      if (user) {
        this.setUser(user);
      }
      this.setTeamUsers(users);
    }
  }

  @Action
  public async sendLoginNotification() {
    if (this.user) {
      // 0920: 一旦見当たらないのでコメントアウト
      // RemonadeApiClient.sendLoginNotification(this.user.team_id, this.user.user_id);

      const today = dayjs().format('YYYY-MM-DD');
      createActivity({
        teamId: this.user.teamId,
        date: today,
        actionType: 'login',
      });
    }
  }

  @Action
  public async updateInterval(interval: number) {
    if (this.user) {
      const user = await updateUser({
        teamId: this.user.teamId,
        userId: this.user.userId,
        interval,
      });
      if (user) {
        if ('error' in user) {
          return;
        }
        this.setUser(user);
      }
    }
  }

  @Action
  public async updatePixel(pixel: number) {
    if (this.user) {
      const user = await updateUser({
        teamId: this.user.teamId,
        userId: this.user.userId,
        pixel,
      });
      if (user) {
        if ('error' in user) {
          return;
        }
        this.setUser(user);
      }
    }
  }

  @Action
  public async updateSnaptype(type: number) {
    if (this.user) {
      const user = await updateUser({
        teamId: this.user.teamId,
        userId: this.user.userId,
        snapType: type,
      });
      if (user) {
        if ('error' in user) {
          return;
        }
        this.setUser(user);
      }
    }
  }

  @Action
  public async updateComment(comment: string) {
    if (this.user) {
      if (comment === '') {
        comment = ' ';
      }
      const user = await updateUser({
        teamId: this.user.teamId,
        userId: this.user.userId,
        message: comment,
      });
      if (user) {
        if ('error' in user) {
          return;
        }
        this.setUser(user);
      }
    }
  }

  @Action
  public async updateSnapContent(content: string) {
    if (this.user) {
      const user = await updateUser({
        teamId: this.user.teamId,
        userId: this.user.userId,
        snapContent: content,
      });
      if (user) {
        if ('error' in user) {
          return;
        }
        this.setUser(user);
      }
    }
  }

  @Action
  public async updateUserEmailEnabled(user: UserResponse) {
    const updatedUser = await updateUser({
      teamId: user.teamId,
      userId: user.userId,
      isMailNotificationEnabled: user.isMailNotificationEnabled,
    });
    if (updatedUser) {
      if ('error' in updatedUser) {
        return;
      }
      this.setUser(updatedUser);
    }
  }

  @Action
  public async updateUserCalendarConnection(user: UserResponse) {
    // TODO: 多分リライト？
    const updatedUser = await updateUser({
      teamId: user.teamId,
      userId: user.userId,
      isMailNotificationEnabled: user.isMailNotificationEnabled,
    });
    if (updatedUser) {
      if ('error' in updatedUser) {
        return;
      }
      this.setUser(updatedUser);
    }
  }

  @Action
  public async updateUserFirstLogin(isFirstLogin: boolean) {
    if (this.user) {
      const user = await updateUser({
        teamId: this.user.teamId,
        userId: this.user.userId,
        isFirstLogin,
      });
      if (user) {
        if ('error' in user) {
          return;
        }
        this.setUser(user);
      }
    }
  }

  @Action
  public async finishOnboarding({ teamId, userId }: { teamId: string; userId: string; }) {
    const user = await updateUser({
      teamId,
      userId,
      isFirstLogin: false,
    });
    if (user) {
      if ('error' in user) {
        return;
      }
      this.setUser(user);
    }
  }

  @Action
  public async updateLastActive() {
    if (this.user) {
      const updateResponse = await updateLastActive({ teamId: this.user.teamId, userId: this.user.userId });

      if ('error' in updateResponse) {
        return;
      }
      this.setUser(updateResponse);
    }
  }

  @Mutation
  public setUser(user: UserResponse | null) {
    // latest snapshotだけ手動で移植
    if (
      user &&
      this.user &&
      this.user.snapshot
    ) {
      user.snapshot = this.user.snapshot;
      this.user = user;
    } else {
      this.user = user;
    }
    if (user) {
      LocalDataService.setUser(user);
    }
  }

  ///////////////
  // Team User //
  ///////////////
  @Action
  public async listTeamUsers() {
    const teamId = LocalDataService.getTeamId();
    if (teamId) {
      const listResponse = await getUserList({ teamId });
      if ('error' in listResponse) {
        return;
      }

      this.setTeamUsers(listResponse.users);
    }
  }

  @Mutation
  public setTeamUsers(users: UserResponse[]) {
    this.teamUsers = users;
  }

  @Mutation
  public updateTeamUser(updatedUser: UserResponse) {
    this.teamUsers = this.teamUsers.filter((user) => user.userId !== updatedUser.userId);
    this.teamUsers.unshift(updatedUser);
  }

  @Action
  public async changeRole(user: UserResponse) {
    const updatedUser = await updateUser({
      teamId: user.teamId,
      userId: user.userId,
      userType: user.userType,
    });
    if (updatedUser) {
      if ('error' in updatedUser) {
        return;
      }
      this.updateTeamUser(updatedUser);
    }
    return null;
  }

  @Action
  public async suspendUser(user: UserResponse) {
    const deletedUser = await deleteUser({
      teamId: user.teamId,
      userId: user.userId,
    });
    if (deletedUser) {
      if ('error' in deletedUser) {
        return;
      }
      return deletedUser;
    }
  }

  // Google
  @Action
  public async checkGoogleUser() {
    if (LocalDataService.getAuthType() === 'OPENID_CONNECT') {
      const user = await auth0Module.getAuth0User();
      if (user.sub.match(/google/)) {
        this.setIsGoogle(true);
      } else {
        this.setIsGoogle(false);
      }
    } else {
      this.setIsGoogle(false);
    }
  }

  @Mutation
  public setIsGoogle(isGoogleUser: boolean) {
    this.isGoogleUser = isGoogleUser;
  }
}

export const userModule = getModule(UserModule);
