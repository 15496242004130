import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import dayjs from 'dayjs';
import { teamModule } from '../team/teamModule';

// api
import { getPlanList, updatePlan, registerPayment, updateCreditcard } from '@/api/client';
import { PlanResponse, TeamResponse } from 'remonade-api/lib';

export interface PlanState {
  planList: PlanResponse[];
  v2PlanList: PlanResponse[];
}

@Module({ dynamic: true, store, name: 'plan', namespaced: true })
class PlanModule extends VuexModule implements PlanState {
  public planList: PlanResponse[] = [];
  public v2PlanList: PlanResponse[] = [];

  public get team(): TeamResponse | null {
    return teamModule.team;
  }

  public get minDate() {
    if (this.team && this.team.payment && !this.team.payment.isTrial && (this.team.plan.planId === 0 || this.team.plan.planId === 7)) {
      // フリープラン
      const minDate = dayjs().add(-14, 'day').format('YYYY-MM-DD');
      return minDate;
    } else if (
      (this.team && this.team.plan.planId === 1)
      || (this.team && this.team.plan.planId === 2)
      || (this.team && this.team.plan.planId === 8)
      || (this.team && this.team.plan.planId === 9)
    ) {
      // ライトプラン
      const minDate = dayjs().add(-3, 'month').format('YYYY-MM-DD');
      return minDate;
    } else if (
      (this.team && this.team.plan.planId === 3)
      || (this.team && this.team.plan.planId === 4)
      || (this.team && this.team.plan.planId === 10)
      || (this.team && this.team.plan.planId === 11)
    ) {
      // スタンダードプラン
      const minDate = dayjs().add(-12, 'month').format('YYYY-MM-DD');
      return minDate;
    } else if (
      (this.team && this.team.plan.planId === 5)
      || (this.team && this.team.plan.planId === 6)
      || (this.team && this.team.plan.planId === 12)
      || (this.team && this.team.plan.planId === 13)
    ) {
      // エンタープライズプラン(トライアルもここ)
      const minDate = '';
      return minDate;
    }
  }

  public get maxDate() {
    return dayjs().format('YYYY-MM-DD');
  }

  public get allowTeamStatsCSV() {
    if (
      (this.team && this.team.payment && this.team.payment.isTrial)
      || (this.team && this.team.plan.planId === 5)
      || (this.team && this.team.plan.planId === 6)
      || (this.team && this.team.plan.planId === 12)
      || (this.team && this.team.plan.planId === 13)
    ) {
      return true;
    }
    return false;
  }

  @Action
  public async changePlan({teamId, planId}) {
    try {
      const r = await updatePlan({
        teamId,
        planId,
      });
      await teamModule.findTeam(teamId);
      return r;
    } catch (e) {
      throw e;
    }
  }

  @Action
  public async registerCard({ teamId, planId, source }) {
    try {
      const r = await registerPayment({
        teamId,
        source,
        planId,
      });
      await teamModule.findTeam(teamId);
      if ('error' in r) {
        switch (r.error) {
          case 'E027':
            return '支払い情報が既に登録されています。';
          case 'E028':
            return 'トライアル期間中のため処理を実行できません。';
          case 'E029':
            return 'プランが変更されていません。';
          default:
            return null;
        }
      }
    } catch (e) {
      throw e;
    }
  }

  @Action
  public async editCard({teamId, source}) {
    try {
      const r = await updateCreditcard({
        teamId,
        source,
      });
      await teamModule.findTeam(teamId);
      if ('error' in r) {
        switch (r.error) {
          case 'E026':
            return '支払い情報が登録されていません。';
          case 'E028':
            return 'トライアル期間中のため処理を実行できません。';
          default:
            return null;
        }
      }
    } catch (e) {
      throw e;
    }
  }

  @Action
  public async listRemonadePlans() {
    if (teamModule.teamId) {
      const planList = await getPlanList({ teamId: teamModule.teamId });
      if (planList) {
        if ('error' in planList) {
          return;
        }
        this.setPlanList(planList.plans);
        const v2Plans = (planList.plans as PlanResponse[]).filter((p) => p.planId >= 200);
        this.setV2PlanList(v2Plans);
      }
    }
  }

  @Mutation
  public setPlanList(planList: PlanResponse[]) {
    this.planList = planList;
  }

  @Mutation
  public setV2PlanList(planList: PlanResponse[]) {
    this.v2PlanList = planList;
  }
}

export const planModule = getModule(PlanModule);
