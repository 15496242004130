import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { authorizePusher } from '@/api/client';
import LocalDataService from '@/service/LocalDataServices';
import Pusher from 'pusher-js';
import { chatModule } from '../chat/chatModule';
import { userModule } from '../user/userModule';
import { reportsModule } from '../report/reportsModule';
import { teamModule } from '../team/teamModule';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { journalViewModule } from '@/store/viewModule/journal/journalViewModule';
import { ChatReactionResponse, ChatResponse } from 'remonade-api/lib';
import { electronNotificationModule } from '../electron/electronNotificationModule';
import { channelsModule } from '../channel/channelsModule';
import { bookmarkModule } from '../bookmark/bookmarkModule';
import { taskpoolModule } from '../report/taskpoolModule';

export interface PusherState {
  pusher: Pusher;
  presenceUsers: string[];
  beamsClient: PusherPushNotifications.Client | null;
}

@Module({ dynamic: true, store, name: 'pusher', namespaced: true })
class PusherModule extends VuexModule implements PusherState {
  public pusher: Pusher = null;
  public presenceUsers: string[] = [];
  public beamsClient: PusherPushNotifications.Client | null = null;

  @Action
  public async connectPusher() {
    Pusher.logToConsole = true; // 👈 Enable pusher logging - don't include this in production

    const teamId = LocalDataService.getTeamId();
    if (teamId) {
      if (this.pusher) {
        this.pusher.disconnect();
      }

      const authorizer = (ch, options) => {
        return {
          authorize: (socketId, callback) => {
            authorizePusher({
              socketId,
              teamId,
            }).then((res) => {
              return res;
            })
            .then((data) => {
              callback(null, data);
            })
            .catch((err) => {
              callback(new Error(`Error calling auth endpoint: ${err}`), {
                auth: '',
              });
            });
          },
        };
      };

      this.setPusher(authorizer);

      const channel = this.pusher.subscribe(`presence-${teamId}`);
      channel.bind('pusher:subscription_succeeded', (data) => {
        const ids = Object.keys(data.members);
        this.setPresenceUsers(ids);
      });
      channel.bind('pusher:member_added', (data) => {
        this.addPresenceUser(data.id);
      });
      channel.bind('pusher:member_removed', (data) => {
        this.deletePresenceUser(data.id);
      });
      channel.bind('USER_MESSAGE', function(data) { // 👈 TODO: 機能調整中
        alert(`USER_MESSAGE, ${JSON.stringify(data)}`);
      });
      channel.bind('USER_UPDATE', function(data) {
        electronNotificationModule.processNotification({ key: 'USER_UPDATE', data });
        userModule.updateTeamUser(data);
      });
      channel.bind('USER_SNAP_CONTENT', function(data) {
        electronNotificationModule.processNotification({ key: 'USER_SNAP_CONTENT', data });
        // alert(`USER_SNAP_CONTENT, ${JSON.stringify(data)}`);
      });
      channel.bind('USER_SNAPSHOT', function(data) {
        electronNotificationModule.processNotification({ key: 'USER_SNAPSHOT', data });
        // alert(`USER_SNAPSHOT, ${JSON.stringify(data)}`);
      });
      channel.bind('USER_REPORT', function(data) {
        electronNotificationModule.processNotification({ key: 'USER_REPORT', data });
        reportsModule.refreshReport(data);
        taskpoolModule.replaceTask(data);
      });
      channel.bind('USER_REPORT_DONE', function(data) {
        electronNotificationModule.processNotification({ key: 'USER_REPORT_DONE', data });
        reportsModule.refreshReport(data);
      });
      channel.bind('USER_REPORT_FROM_POOL', function(data) {
        electronNotificationModule.processNotification({ key: 'USER_REPORT_FROM_POOL', data });
        reportsModule.refreshReport(data);
      });
      channel.bind('USER_POOL_SUGGEST', function(data) {
        electronNotificationModule.processNotification({ key: 'USER_POOL_SUGGEST', data });
      });
      channel.bind('USER_CHANNEL', async function(data) {
        electronNotificationModule.processNotification({ key: 'USER_CHANNEL', data });
        await channelsModule.listChannels();
      });
      channel.bind('USER_CHAT', async function(data) {
        electronNotificationModule.processNotification({ key: 'USER_CHAT', data });
        try {
          const chat = data as ChatResponse;
          chatModule.addChats([chat]);
        } catch {
          await chatModule.listChat();
        }
      });
      channel.bind('USER_CHAT_REACTIONS', async function(data) {
        electronNotificationModule.processNotification({ key: 'USER_CHAT_REACTIONS', data });
        try {
          const chatReaction = data as ChatReactionResponse;
          chatModule.addReactions([chatReaction]);
        } catch {
          await chatModule.listChat();
        }
      });
      channel.bind('CHANNEL', function(data) { // 👈 TODO: Channel編集未実装
        electronNotificationModule.processNotification({ key: 'CHANNEL', data });
        alert(`CHANNEL, ${JSON.stringify(data)}`);
      });
      channel.bind('CHANNEL_USERS_ADD', async function(data) {
        electronNotificationModule.processNotification({ key: 'CHANNEL_USERS_ADD', data });
        await channelsModule.listChannels();
      });
      channel.bind('CHANNEL_USERS_DELETE', async function(data) {
        electronNotificationModule.processNotification({ key: 'CHANNEL_USERS_DELETE', data });
        await channelsModule.listChannels();
      });
      channel.bind('JOURNAL_UPDATE', function(data) {
        electronNotificationModule.processNotification({ key: 'JOURNAL_UPDATE', data });
        journalViewModule.updateTeamNote(data);
      });
      channel.bind('BOOKMARK_UPDATE', async function(data) {
        electronNotificationModule.processNotification({ key: 'BOOKMARK_UPDATE', data });
        bookmarkModule.listBookmarks();
      });
      channel.bind('PAYMENT_UPDATE', function(data) {
        electronNotificationModule.processNotification({ key: 'PAYMENT_UPDATE', data });
        // teamModule.setTeam(data);
      });
      channel.bind('REFRESH_PLAN', async function(data) {
        electronNotificationModule.processNotification({ key: 'REFRESH_PLAN', data });
        await userModule.listTeamUsers();
        await teamModule.refreshTeam();
      });
      channel.bind('REFRESH', function(data) {
        electronNotificationModule.processNotification({ key: 'REFRESH', data });
        window.location.reload();
      });
    }
  }

  @Mutation
  public initBeamsClient() {
    try {
      this.beamsClient = new PusherPushNotifications.Client({
        instanceId: process.env.VUE_APP_PUSHER_BEAMS_ID,
      });
    } catch (e) {
    }
  }

  @Action
  public async addInterestOfBeams() {
    const userId = LocalDataService.getUserId();
    if (userId && this.beamsClient) {
      this.beamsClient.start()
      .then(() => this.beamsClient.getDeviceId())
      .then((deviceId) => {
        // console.log('Device ID:', deviceId);
      })
      .then(() => this.beamsClient.getDeviceInterests())
      .then(async (interests) => {
        await Promise.all(
          interests.map((interest) => { // 同じ端末で違うアカウントでログインしていた場合、そのsubscribeが残っているため、解除する
            if (interest !== userId && interest !== `debug-${userId}`) {
              this.beamsClient.removeDeviceInterest(interest);
            }
          }),
        );
        if (!interests.includes(userId)) {
          await this.beamsClient.addDeviceInterest(userId);
        }
        if (!interests.includes(`debug-${userId}`)) { // debug console用のsubscribe
          await this.beamsClient.addDeviceInterest(`debug-${userId}`);
        }
      })
      .then(() => this.beamsClient.getDeviceInterests())
      .then((interest) => {
        // console.log('Interest:', interest);
      })
      .catch((error) => {
        // console.log('error', error);
      });
    }
  }

  @Mutation
  public setPusher(authorizer) {
    this.pusher = new Pusher(process.env.VUE_APP_PUSHER_CHANNELS_APP_KEY, {
      cluster: 'ap3',
      authorizer,
    });
  }

  @Mutation
  public setPresenceUsers(ids: string[]) {
    this.presenceUsers = ids;
  }

  @Mutation
  public addPresenceUser(id: string) {
    this.presenceUsers.push(id);
  }

  @Mutation
  public deletePresenceUser(id: string) {
    this.presenceUsers = this.presenceUsers.filter((user) => user !== id);
  }
}

export const pusherModule = getModule(PusherModule);
