import { get } from '../common';

export const getPlanList = (
  payload: {
    teamId: string,
  },
) => {
  return get('/plan', {
    teamId: payload.teamId,
  });
};
